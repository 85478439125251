import React, { useRef, useEffect } from "react";
import Vimeo from "@vimeo/player";

export default function VimeoPlayer({ videoId }) {
    const playerRef = useRef(null);
  
    useEffect(() => {
      const player = new Vimeo(playerRef.current, {
        id: videoId,
        autopause: false,
        autoplay: false, // Set to true if you want videos to autoplay
      });
  
      return () => {
        player.destroy();
      };
    }, [videoId]);
  
    return <div ref={playerRef} />;
  }
  