import React from "react";
import avtarseven from "../../Temimg/avtarseven.png";
import widgetstar from "../../Temimg/widgetstar.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VideoTestimonial from '../VideoTestimonial';
import VimeoPlayer from '../VimeoPlayer';

function slick(props) {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: props.testimonials && props.testimonials.length>3?3:props.testimonials.length,
        slidesToScroll: 1,
        arrow: true,

      responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },      
      ],
    };
    const next = () => {
        Slider.current.slickNext();
    }
    const previous = () => {
        Slider.current.slickPrev();
    };

    let background_style = {
        backgroundColor: props.background
    };
    if(props.borderRadius!=='0px'){
        background_style = {
            backgroundColor: props.background,
            borderRadius: props.borderRadius
        };
    }

    const viewVideo = (review_id, type) => {
        fetch(process.env.REACT_APP_API_URL + 'view-count-increment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ review_id, type })
        })
            .then(data => data.json())
            .then(data => {
                console.log('video viewed');
            });
    }

    return (
        <>
            <div className="custom_slider widget-tem-one" style={background_style}>
                <Slider {...settings}>
                    {props.testimonials && props.testimonials.map((testimonial, key) => {
                        return (
                            <div key={key}>
                                <div className="widgets">
                                    <div className="widgets_one_inner">
                                        <div className={props.avatar?"customer_image text-center":'d-none'}>
                                            {
                                                testimonial.reviewer_picture
                                                    ?
                                                    <img className="rounded-circle" src={testimonial.reviewer_picture} alt="avtarseven" />
                                                    :
                                                    <img src={avtarseven} alt="avtarseven" />
                                            }
                                        </div>
                                        <div className="quote text-center">
                                            <div className={props.rating ? "rating mt-3" : 'd-none'}>
                                                <img src={widgetstar} alt="rating" />
                                            </div>
                                            <p className={testimonial.text?"mb-4":"d-none"} style={{color:props.color,fontFamily:props.fontFamily}}>
                                                {testimonial.text}
                                            </p>

                                            {testimonial.files &&
                                                <div className='upload_post_image mt-3' onClick={() => {
                                                    viewVideo(testimonial.media[0].id, testimonial.media[0].type)
                                                }}>
                                                {
                                                    testimonial.platform_name === 'proof_pudding'
                                                        ?
                                                        <VideoTestimonial src={testimonial.media[0].file_url} id={testimonial.media[0].id} />
                                                        :
                                                        testimonial.media[0].type === 'photo'
                                                            ?
                                                            <img src={testimonial.media[0].file_url} alt="image" className='w-100' />
                                                            :
                                                            testimonial.platform_name === 'vimeo'
                                                            ?
                                                            <VimeoPlayer key={testimonial.review_id} videoId={testimonial.review_id} />
                                                            :
                                                            <VideoTestimonial src={testimonial.media[0].file_url} id={testimonial.media[0].id} />
                                                }
                                                </div>
                                            }

                                            <div className="date">{testimonial.reviewer_date_2}</div>
                                            <div className="customer_name text-center pt-1" style={{color:props.color,fontFamily:props.fontFamily}}>
                                                {testimonial.reviewer_name}
                                            </div>
                                            <div className="designation" style={{color:props.color,fontFamily:props.fontFamily}}>{testimonial.reviewer_handle}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </>
    );
}



export default slick;