import { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Widgetone from "./Widgetone";

async function searchTestimonials(request, access_token) {
  return fetch(process.env.REACT_APP_API_URL + 'search-testimonials', {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + access_token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(data => data.json())
}

export default function MyVerticallyCenteredModal(props) {

  const accessToken = localStorage.getItem('accessToken');
  const [search, setSearch] = useState();
  const [reviews, setReviews] = useState([]);

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const response = await searchTestimonials({
        'search': event.target.value
      }, accessToken);
      if (response.status) {
        setReviews(response.reviews);
      }
    }
  };

  const handleSearch = async () => {
    const response = await searchTestimonials({
      'search': search
    }, accessToken);
    if (response.status) {
      setReviews(response.reviews);
    }
  }

  let temp_arr = [];
  const [data, setData] = useState([]);
  const handleButtonClick = () => {
    if (temp_arr.length > 0) {
      const eventData = { data: temp_arr };
      const event = new CustomEvent('childData', { detail: eventData });
      document.dispatchEvent(event);
    }
  };

  function setCurrentTheme(e) {
    if (e.target.checked) {
      temp_arr.push(e.target.value);
    } else {
      temp_arr.splice(temp_arr.indexOf(e.target.value), 1);
    }
  }

  function getTestimonial() {
    fetch(process.env.REACT_APP_API_URL + 'get-testimonial', {
      method: 'Get',
      headers: {
        authorization: 'Bearer ' + accessToken,
        Accept: 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        setData(data.data);
      });
  }

  useEffect(() => {
    getTestimonial();
  }, []);

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className='pick_testimonial'>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Pick a Testimonial
        </Modal.Title>
        <Button onClick={() => {
          handleButtonClick()
          props.onHide()
        }}>Done</Button>
      </Modal.Header>
      <Modal.Body>
        <h5>Select the testimonials you want to show up.</h5>
        <Form className="position-relative">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Search Testimonials</Form.Label>
            <Form.Control
                type="name"
                placeholder="Search Testimonials"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={handleKeyPress}
            />
          </Form.Group>
          <Button onClick={handleSearch}>Search</Button>
        </Form>
        <div className='testimonials_list'>

          <Widgetone
            setCurrentThemefunction={setCurrentTheme}
            data={reviews.length?reviews:data}
          />

        </div>
      </Modal.Body>
    </Modal>
  );
}