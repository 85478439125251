import scratch from "../Images/scratch.svg";
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import React, { useEffect,useState } from "react";
import WallTemplateOne from "../Images/wall_template_1.png";
import WallTemplateTwo from "../Images/lovewalltwo.png";
import WallTemplateThree from "../Images/lovewallone.png";
import ModalLove from "./love_wall/ModalLove";

export default function WallOfLovePartial() {
    const [modalShow, setModalShow] = React.useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const [allowedWalls, setAllowedWalls] = useState();
    
    function setthemplateFunction(slug){
        localStorage.setItem('love_Template',slug);
        setModalShow(true)
    }
    useEffect(()=>{
        setAllowedWalls(user.userplan.plan.no_of_proof_wall_templates);
        localStorage.removeItem('walllove_Name');
        localStorage.removeItem('love_Template');
    },[]);

    return (
        <Card className='mt-3'>
            <div className='card_box_wrapper'>
                <h4>Custom Review Page</h4>
                <p className={allowedWalls>=1?'':'d-none'}>Share your testimonials with a Custom Review Page.</p>
                <p className={allowedWalls>=1?'d-none':''} style={{color:'#fd0000'}}>Please upgrade your plan to have access of Proof wall templates.</p>
                <div className={allowedWalls>=1?'scroller proofwall_list':'d-none'}>
                    <div className="scroller_inner">
                        <div className="start_from_scratch">
                            <Link to="" onClick={setthemplateFunction.bind(this,'lovewallone')}>
                                <div className='start'>
                                    <img src={scratch} alt="scratch" />
                                    <h5>Start form <br />scratch</h5>
                                </div>
                            </Link>
                        </div>
                        <ul>
                            <li className={allowedWalls>=1?'':'d-none'}>
                                <Link to="">
                                    <img src={WallTemplateOne} alt="WallTemplateOne" onClick={setthemplateFunction.bind(this,'lovewallone')}/>
                                </Link>
                            </li>
                            <li className={allowedWalls>=2?'':'d-none'}>
                                <Link to="">
                                    <img src={WallTemplateTwo} alt="WallTemplateTwo" onClick={setthemplateFunction.bind(this,'lovewalltwo')}/>
                                </Link>
                            </li>
                            
                        </ul>                                                
                    </div>
                </div>
            </div> 
                <ModalLove
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
        </Card>
    );
}