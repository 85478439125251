import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WidgetCarousel from './WidgetCarousel';
import WidgetCarouselTwo from './WidgetCarouselTwo';
import WidgetCarouselThree from './WidgetCarouselThree';
import star from "../../Images/star.png";

export default function Widget() {
    const { slug } = useParams();
    const [testimonials, setTestimonials] = useState([]);
    const [widget, setWidget] = useState(null);
    const [displayProofPuddingBrand,setDisplayProofPuddingBrand] = useState('none');
    const [user,setUser] = useState();

    const getWidgetWall = () => {
        fetch(process.env.REACT_APP_API_URL + 'get-widget-wall/' + slug, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(response => response.json())
            .then(data => {
                setTestimonials(data.testimonials);
                setWidget(data.widget);
                setUser(data.user);
                if(data.user && data.user.userplan.plan.type=='paid'){
                    setDisplayProofPuddingBrand('none');
                }else{
                    setDisplayProofPuddingBrand('block');
                }
            });
    }

    useEffect(() => {
        getWidgetWall();
    }, [])

    if (testimonials && widget) {
        return (
            <>
                <div class="powered_by" style={{bottom:'auto',display:displayProofPuddingBrand}}>
                    <h6 class="mb-0">Powered by Proof Pudding<img src={star} alt="star" class="ms-1"/></h6>
                </div>
                {widget && widget.template == 1
                    ?
                    <WidgetCarouselThree
                        testimonials={testimonials}
                        avatar={widget.show_avatar}
                        date={widget.date}
                        rating={widget.show_rating}
                        background={widget.background}
                        color={widget.color}
                        fontFamily={widget.font_family}
                        borderRadius={widget.border_radius}
                        margin={widget.margin}
                        slug={slug}
                    />
                    :
                    <WidgetCarouselThree
                        testimonials={testimonials}
                        avatar={widget.show_avatar}
                        date={widget.date}
                        rating={widget.show_rating}
                        background={widget.background}
                        color={widget.color}
                        fontFamily={widget.font_family}
                        borderRadius={widget.border_radius}
                        margin={widget.margin}
                        slug={slug}
                    />
                }
            </>
        );
    } else {
        return <></>
    }
}