import { Col, Row, Container } from 'react-bootstrap';
import Sidebar from './Sidebar';
import Header from './Header';
import React from "react";
import SocialProofHeader from './SocialProofHeader';
import WidgetPartial from './WidgetPartial';

function SocialProof() {

  return (
    <>
      <Container fluid className="pe-0">
        <Sidebar />
        <div className='wrapper'>
          <Header title="Media Studio" />
          <div className='inner_content_wrapper social_proof_inner vh-100'>
            <Row>
              <SocialProofHeader active="widgets" />
              <Col md="12">
                <WidgetPartial />
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
}


export default SocialProof;

