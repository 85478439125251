import resize from "../Images/resize.svg";
import share from "../Images/share.svg";
import arrowback from "../Images/arrowback.png";
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import arrowsave from "../Images/arrowsave.svg";
import twitter from "../Images/twitter.png";
import linkedin from "../Images/linkedin.png";
import facebookF from "../Images/facebookF.png";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

export default function StudioHeader(props) {

    const user = JSON.parse(localStorage.getItem('user'));

    const [downloadedImages,setDownloadedImages] = useState();
    const [allowedImages,setAllowedImages] = useState();

    const handleDownload = () => {
        // if(localStorage.getItem('testimonial_ids').length)
        // setDownloadedImages(parseInt(downloadedImages)+1);
        // if(downloadedImages>=allowedImages){
        //     toast.warning('You have reached the limit ...applied on template')
        // }
    }

    useEffect(()=>{
        setDownloadedImages(user.userplan.no_of_social_images);
        setAllowedImages(user.userplan.plan.no_of_social_images);
    },[]);

    return (
        <div className='edit_header d-flex justify-content-between align-items-center'>
            <div className='left d-flex'>
                <div className='back_btn'>
                    <Link to="/social-proof"><img src={arrowback} alt="arrowback" /></Link>
                </div>
                <div className='title'>ProofPudding</div>
            </div>
            <div className='right d-flex'>
            
            <Dropdown>
                <Dropdown.Toggle variant="success" id="share">
                    <img src={ share } alt="share" className='me-2'/>Share
                </Dropdown.Toggle>
                {/* <Dropdown.Menu className={downloadedImages<allowedImages?"":"d-none"}> */}
                <Dropdown.Menu>
                    <Dropdown.Item  onClick={()=>{
                        props.handleConvertfunction();
                        handleDownload();
                    }} ><img src={ arrowsave } alt="arrowsave"/>Save as PNG</Dropdown.Item>
                    <Dropdown.Item onClick={()=>{
                        props.jpegfunction();
                        handleDownload();
                    }} ><img src={ arrowsave } alt="arrowsave"/>Save as JPEG</Dropdown.Item>
                    <Dropdown.Item onClick={()=>{
                        props.Zipfunction();
                        handleDownload();
                    }} ><img src={ arrowsave } alt="arrowsave"/>Export all as ZIP</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown> 
            </div>
        </div>
    );
}