import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import save from "../../Images/save.svg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect, useState } from 'react';

export default function ModalLove(props) {
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const [widgetName, setWidgetName] = useState(null);

  function createWallofLove(data,access_token) {

     fetch(process.env.REACT_APP_API_URL + 'save-wall-of-love', {
          method: 'POST',
          headers: {
              authorization: 'Bearer ' + access_token,
              Accept: 'application/json',
          },
          body:new URLSearchParams(data)
      })
      .then(data => data.json())
      .then((data)=>{
        localStorage.setItem('Wall_id',data.Wall_id);
        localStorage.setItem('Wall_url',data.data.wall_url);
      })
  }

  const moveToWidgetTemplate = () => {
    if(widgetName){
      let templete=localStorage.getItem('love_Template');
      createWallofLove({'wall_name':atob(widgetName),'template':templete},accessToken);
      navigate('/social-proof/proof-wall/template?slug='+widgetName);
    }else{
      toast.error('Please enter a Wall of Love');
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      moveToWidgetTemplate();
    }
  };

  useEffect(()=>{
    localStorage.removeItem('Wall_id');
    localStorage.removeItem("love_testimonials_ids");
    localStorage.removeItem('love_Testimonials');
    localStorage.removeItem('Wall_url');
  },[]);
  
  return (
    <> 
      <ToastContainer />
      <Modal {...props} size="lg" onKeyDown={handleKeyDown} aria-labelledby="contained-modal-title-vcenter" centered className='add_tile'>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Create a Custom Review Page
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Name this Custom Review Page so you can find it later in <img src={save} alt="save" className='me-1 ms-1' />Saved</h5>
          <Form onSubmit={props.lovefunction}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control 
                type="text" 
                name="widget_name"
                placeholder="Ex. Custom Review Page"
                onChange={(e)=>setWidgetName(btoa(e.target.value))} 
                required
              />
            </Form.Group>
          </Form>
          <Button onClick={moveToWidgetTemplate}>Create Custom Review Page</Button>
        </Modal.Body>
      </Modal>
    </>
  );
}