import { Container, Col, Button } from 'react-bootstrap';
import star from "../Images/star.png";
import React, { useRef, useState, useEffect } from 'react';
import { ReactMediaRecorder } from 'react-media-recorder';
import { useNavigate } from "react-router-dom";
import stoprecord from "../Images/stoprecord.png";

async function saveFile(params, business_name) {
  return fetch(process.env.REACT_APP_API_URL + 'save-recorded-video', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  })
    .then(data => data.json())
    .then(data => {
      if (data.status) {
        window.location = '/write-a-review-final?business_name=' + business_name + '&id=' + btoa(data.data.id);
      } else {
        console.log(data.message);
      }
    });
}

function Dashboard() {
  const [shortName, setShortName] = useState('');
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const business_name = urlParams.get('business_name');
  const [user, setUser] = useState();
  const [stream, setStream] = useState(null);
  const [started, setStarted] = useState(false);
  const [serviceQuestions, setServiceQuestions] = useState([]);
  const [displayProofPuddingBrand, setDisplayProofPuddingBrand] = useState('none');

  function getUserNameInitials(name_) {
    const slices = name_.split(' ');
    let name_initials = '';
    slices.forEach(item => {
      name_initials = name_initials + '' + item.charAt(0).toUpperCase();
    });
    setShortName(name_initials);
  }

  async function getBusiness(params) {
    return fetch(process.env.REACT_APP_API_URL + 'get-business', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    })
      .then(data => data.json())
      .then(response => {
        setUser(response.data);
        if (response.data) {
          getUserNameInitials(response.data.name);
          setServiceQuestions(response.data.service_questions);

          if (response.data.userplan.plan.type == 'paid') {
            setDisplayProofPuddingBrand('none');
          } else {
            setDisplayProofPuddingBrand('block');
          }
        }
      });
  }

  useEffect(() => {
    getBusiness({ business_name });
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then((mediaStream) => {
        setStream(mediaStream);
      })
      .catch((err) => {
        console.error('Failed to get media stream', err);
      });
  }, []);

  const [enable, setEnable] = useState(false);
  const [videoFile, setVideoFile] = useState(true);
  const [showSave, setShowSave] = useState(false);

  var convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
  const sendVideo = async (event) => {
    event.preventDefault();
    setEnable(false);
    const audioBlob = await fetch(videoFile).then((r) => r.blob());
    const audiofile = new File([audioBlob], "videofiles.webm", {
      type: "video/webm",
    });

    const videorecord = await convertBase64(audiofile)
    const res = await saveFile({
      videorecord,
      business_name
    }, business_name);

  }
  const VideoPreview = ({ stream }) => {
    const videoRef = useRef(null);
    useEffect(() => {
      if (videoRef.current && stream) {
        setEnable(true);
        videoRef.current.srcObject = stream;
      }
    }, [stream]);
    if (!stream) {
      return null;
    }
    return <video id='video_2' ref={videoRef} width={500} height={500} autoPlay />;
  };

  return (
    <div className='record_video_wrap'>
      <div>
        <ReactMediaRecorder
          video
          blobPropertyBag={{
            type: 'video/webm',
          }}
          render={({
            previewStream,
            status,
            startRecording,
            stopRecording,
            mediaBlobUrl,

          }) => {

            if (previewStream) {
              setVideoFile(mediaBlobUrl);
            }

            return (
              <Container fluid className="p-0">
                <div className='add_review'>
                  <Col>
                    <div className='add_reviews_inner position-relative m-auto'>
                      <div className='head_wrap d-flex justify-content-between'>
                        {
                          user && user.image
                            ?
                            <img className='logo_review' src={user.image} />
                            :
                            <div className='logo_review'>{shortName ? shortName : 'PP'}</div>
                        }
                        <h4 className='mt-0'>{business_name ? business_name : 'Business Name'}</h4>
                      </div>
                      <div className='common_content_wrapper pt-2'>
                        <h3 className='text-center'>Record Video Review</h3>
                        <p style={{whiteSpace: "pre-wrap"}}>{serviceQuestions}</p>
                        { /*<ul>
                          {serviceQuestions && serviceQuestions.map((ques, key) => {
                            return <li key={key}>{ques.text}</li>
                          })}

                        </ul> */ }

                        {!started && stream && (
                          <video autoPlay playsInline muted width="500" height="480" ref={(videoRef) => {
                            if (videoRef && !videoRef.srcObject) {
                              videoRef.srcObject = stream;
                            }
                          }} />
                        )}

                        <button className={!started ? "stop_record" : 'd-none'} onClick={() => {
                          startRecording();
                          setStarted(true);
                        }}><img src={stoprecord} alt="stoprecord" className="me-2" />Start Recording</button>

                        <div className={enable ? 'position-relative z-1' : 'd-none'}>
                          <div className=''></div>

                          <div className={status === 'stopped' ? 'd-none' : ''}>
                            {<VideoPreview stream={previewStream} />}
                          </div>
                          <div className={status === 'stopped' ? '' : 'd-none'} >
                            <video id='video_1' src={mediaBlobUrl} controls autoPlay />
                          </div>

                          {
                            showSave
                              ?
                              <></>
                              :
                              <button className="stop_record" onClick={() => {
                                stopRecording();
                                setShowSave(true);
                              }}><img src={stoprecord} alt="stoprecord" className="me-2" />Stop Recording</button>
                          }

                        </div>

                        <div className={showSave ? 'common_btn_wrapper mt-2' : 'd-none'}>
                          <Button className='mb-2' onClick={sendVideo}>Submit</Button>
                        </div>
                      </div>
                      <div className='powered_by' style={{display:displayProofPuddingBrand}}>
                        <h6 className='mb-0'>Powered by Proof Pudding<img src={star} alt="star" className='ms-1' /></h6>
                      </div>
                    </div>
                  </Col>
                </div>
              </Container>
            );
          }}
        />
      </div>
    </div>
  );
}
export default Dashboard;