import { Col, Row, Container, Card, ProgressBar } from 'react-bootstrap';
import menu from "../Images/menu.svg";
import React, { useState, useEffect } from 'react';

export default function Header(props) {

    const user = JSON.parse(localStorage.getItem('user'));
    const [shortName, setShortName] = useState('');

    function getUserNameInitials(name_) {
        const slices = name_.split(' ');
        let name_initials = '';
        slices.forEach(item => {
            name_initials = name_initials + '' + item.charAt(0).toUpperCase();
        });
        setShortName(name_initials);
    }

    function toggleClass() {
        if (document.body.classList.contains('show')) {
            document.body.classList.remove('show');
        } else {
            document.body.classList.add('show');
        }
    };

    useEffect(() => {
        getUserNameInitials(user.name);
    }, []);

    return <>
        <div className='header pb-3'>
            <Row>
                <Col>
                    <div className='d-flex align-items-center'>
                        <div className='sidebar_toggle' onClick={toggleClass}>
                            <img src={menu} alt="menu" />
                        </div>
                        <div>
                            <h2 className='mb-0 h-100'>{props.title}</h2>
                            <p className={props.title == 'Social Proof' ? 'mt-1' : 'mt-1 d-none'}>Turn your testimonials into shareable social proof.</p>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='d-flex justify-content-end'>
                        {
                        user.image
                        ?
                        <img className='profile_name d-flex justify-content-center align-items-center' src={user.image} />
                        :
                        <div className='profile_name d-flex justify-content-center align-items-center'>
                            {shortName}
                        </div>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    </>
}

