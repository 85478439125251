import testimonialvector from "../../Images/testimonialvector.png";
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import WidgetModal from "./WidgetModal";
import WidgetCarousel from './WidgetCarousel';
import WidgetCarouselTwo from './WidgetCarouselTwo';

async function getTestimonialsByIds(params, access_token) {
    return fetch(process.env.REACT_APP_API_URL + 'get-testimonials-by-ids', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
        body: JSON.stringify(params)
    })
        .then(data => data.json())
}

export default function WidgetSection(props) {

    const accessToken = localStorage.getItem('accessToken');
    const [modalShow, setModalShow] = useState(false);
    const [testimonialsIds, setTestimonialsIds] = useState([]);
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        document.addEventListener('widgetData', handleChildData);
        return () => {
            document.removeEventListener('widgetData', handleChildData);
        };
    }, []);

    const handleChildData = async (event) => {
        let arr = event.detail.data.split(',');
        setTestimonialsIds(arr);
        const response = await getTestimonialsByIds({
            'ids': arr
        }, accessToken);
        setTestimonials(response.data);

        localStorage.setItem('Widget_Testimonials', arr);
    };

    return (
        <div className='testimonial_view' id="testimonial_view">
            <div className={testimonialsIds.length?'':'create_testimonial'}>
                {
                    testimonialsIds.length
                        ?
                        props.activeTemplate && props.activeTemplate == 1
                            ?
                            <WidgetCarousel
                                testimonials={testimonials}
                                avatar={props.avatar}
                                date={props.date}
                                rating={props.rating}
                                background={props.background}
                                color={props.color}
                                fontFamily={props.fontFamily}
                                borderRadius={props.borderRadius}
                                margin={props.margin}
                            />
                            :
                            <WidgetCarouselTwo
                                testimonials={testimonials}
                                avatar={props.avatar}
                                date={props.date}
                                rating={props.rating}
                                background={props.background}
                                color={props.color}
                                fontFamily={props.fontFamily}
                                borderRadius={props.borderRadius}
                                margin={props.margin}
                            />
                        :
                        <Link to="" onClick={() => setModalShow(true)}>
                            <div className='vector'><img src={testimonialvector} alt="testimonialvector" /></div>
                            <div className='label'>Pick up the testimonial to get started</div>
                        </Link>
                }
                <WidgetModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    testimonials={props.testimonials}
                />
            </div>
        </div>
    );
}