import { Form, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import WallLoveTestimonial from './WallLoveTestimonial';
import { toast } from 'react-toastify';
import React, { useState } from "react";

async function searchTestimonials(request, access_token) {
    return fetch(process.env.REACT_APP_API_URL + 'search-testimonials', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    })
        .then(data => data.json())
}

export default function WidgetModal(props) {

    const accessToken = localStorage.getItem('accessToken');
    const [search, setSearch] = useState();
    const [reviews, setReviews] = useState([]);

    const closeModal = () => {
        const testimonials_ids = localStorage.getItem('love_testimonials_ids');
        if (testimonials_ids) {
            props.onHide();
            const eventData = { data: testimonials_ids };
            const event = new CustomEvent('lovewallData', { detail: eventData });
            document.dispatchEvent(event);
            localStorage.removeItem("love_testimonials_ids");
        } else {
            toast.error('Please selecte at least one testimonial');
        }
    }

    const handleKeyPress = async(event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            const response = await searchTestimonials({
                'search' : event.target.value
            },accessToken);
            if(response.status){
                setReviews(response.reviews);
            }
        }
    };

    const handleSearch = async () => {
        const response = await searchTestimonials({
            'search': search
        }, accessToken);
        if (response.status) {
            setReviews(response.reviews);
        }
    }

    return (
        <>
            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className='pick_testimonial'>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        All Testimonials

                    </Modal.Title>
                    <Button onClick={() => {
                        closeModal();
                    }}>Done</Button>
                </Modal.Header>
                <Modal.Body>
                    <h5>Select the testimonials you want to show up.</h5>
                    <Form className="position-relative">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Search Testimonials</Form.Label>
                            <Form.Control
                                type="name"
                                placeholder="Search Testimonials"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </Form.Group>
                        <Button onClick={handleSearch}>Search</Button>
                    </Form>
                    <div className='testimonials_list'>
                        <WallLoveTestimonial 
                            testimonials={reviews.length?reviews:props.testimonials}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}