import React from 'react';
import { Spinner } from 'react-bootstrap';
import LoaderGIF from '../Images/loader.gif';

const Loader = () => {
  return (
    <div className="loader">
      <img src={LoaderGIF}/>
    </div>
  );
};
export default Loader;
