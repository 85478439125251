import { Col, Row, Container } from 'react-bootstrap';
import Sidebar from './Sidebar';
import Header from './Header';
import React,{useEffect} from "react";
import SocialProofHeader from './SocialProofHeader';
import ImagesPartial from './ImagesPartial';

function SocialProof() {
  function selectImageTheme(slug)
  {
    localStorage.setItem('image_template',slug);
  }

   useEffect(()=>{
      localStorage.removeItem("testimonial_ids");
      localStorage.removeItem('image_template');
   },[]);

  return (
    <>
      <Container fluid className="pe-0">
        <Sidebar />
        <div className='wrapper images_wrapper vh-100'>
          <Header title="Media Studio" />
          <div className='inner_content_wrapper social_proof_inner'>
            <Row>
              <SocialProofHeader active="images" />
              <Col md="12">
                <ImagesPartial themefunction={selectImageTheme} />
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
}


export default SocialProof;

