import React, { useEffect, useState } from "react";
import Wallofloveone from './Wallofloveone';
import Walloflovetwo from './Walloflovetwo';

function LoveTemplate(props) {
  const accessToken = localStorage.getItem('accessToken');
  const [data, setData] = useState([]);
  const template = props.title;

  function getSingleTestimonial(data, accessToken) {
    return fetch(process.env.REACT_APP_API_URL + 'get-single-testimonial', {
      method: 'post',
      headers: {
        authorization: 'Bearer ' + accessToken,
        Accept: 'application/json',
      },
      body: new URLSearchParams(data),
    })
      .then(response => response.json())

      .then(data => {
         console.log(data);
        setData(data.data);
      });
  }

  useEffect(() => {
    let data = localStorage.getItem('love_testimonials_ids');
    if (data) {
      const response = getSingleTestimonial({ 'testimonial_id': data }, accessToken);
    } else {
      setData(props.testimonials);
    }
  }, [props.testimonials]);

  if (template == 'lovewallone') {
    return (
      <Wallofloveone 
        data={data} 
        logoimage={props.logoimage} 
        size={props.logosize} 
        Bgcolor={props.Bgcolor}
        pageTitle={props.pageTitle} 
        ctaTitle={props.ctaTitle}
        subtitle={props.subtitle}
        ctaURL={props.ctaURL}
        MenuOneTitle={props.MenuOneTitle}
        MenuOneLink={props.MenuOneLink}
        MenuTwoTitle={props.MenuTwoTitle}
        MenuTwoLink={props.MenuTwoLink}
        MenuThreeTitle={props.MenuThreeTitle}
        MenuThreeLink={props.MenuThreeLink}
        showDelete={props.showDelete}
        buttonColor={props.buttonColor}
        reviewColor={props.reviewColor}
        fontColor={props.fontColor}
        fontFamily={props.fontFamily}
        authorFont={props.authorFont}
        reviewFont={props.reviewFont}
      />
    );
  } else if (template == 'lovewalltwo') {
    return (
      <Walloflovetwo 
        data={data} 
        logoimage={props.logoimage} 
        size={props.logosize} 
        Bgcolor={props.Bgcolor}
        pageTitle={props.pageTitle} 
        ctaTitle={props.ctaTitle}
        subtitle={props.subtitle}
        ctaURL={props.ctaURL}
        showDelete={props.showDelete}
        buttonColor={props.buttonColor}
        reviewColor={props.reviewColor}
        fontColor={props.fontColor}
        fontFamily={props.fontFamily}
        authorFont={props.authorFont}
        reviewFont={props.reviewFont}
      />
    );
  }
}
export default LoveTemplate;