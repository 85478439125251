import arrowback from "../../Images/arrowback.png";
import { Link } from 'react-router-dom';

export default function StudioHeader(props) {

    const wall_url = localStorage.getItem('Wall_url');
    
    return (
        <div className='edit_header d-flex justify-content-between align-items-center'>
            <div className='left d-flex'>
                <div className='back_btn'>
                    <Link to="/social-proof"><img src={arrowback} alt="arrowback" /></Link>
                </div>
                <div className='title'>{props.title ? atob(props.title) : 'ProofPudding'}</div>
            </div>
            <div className='right d-flex'>
               <div className={wall_url?"bg-light lh-lg px-1 rounded wall-url-wrapper":'d-none'}>
                <a href={wall_url} target="_blank" className="text-decoration-none">{wall_url}</a>
               </div>
               &nbsp;&nbsp;
                <button className='share' onClick={props.savefunction}>Save changes</button>
            </div>
        </div>
    );
}