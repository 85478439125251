import Modal from 'react-bootstrap/Modal';
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import '../css/style.css'
import mastercard from "../Images/mastercard.png";
import visa from "../Images/visa.svg";
import amex from "../Images/amex.svg";
import cardadd from "../Images/cardadd.svg";
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const stripePromise = loadStripe('pk_test_51HpZUWH65aypuFHuB0FseHw9lJU9AnICxaVI5iXuXiGgZ5ZjjHyaTrqEKtRdkLgn79cQy331ON0lFQhfFP5ggCbX00DCkOvVrP');

async function makePayment(params, access_token) {
    return fetch(process.env.REACT_APP_API_URL + 'charge', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
        body: JSON.stringify(params),
    }).then(data => data.json());
}

export default function PaymentModal(props) {

    const accessToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();

    const handlePayment = async(customer_id) => {
        const response = await makePayment({
            'customer_id' : customer_id,
            'plan_id' : props.plan_id
        },accessToken);

        if(response.status){
            localStorage.setItem('user', JSON.stringify(response.data));
            navigate('/settings');
        }else{
            toast.error(response.message);
        }
    }

    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className='make_payment'>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Make Payment
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='payment_method'>
                    <div className='card_list'>
                        <ul>
                            {props.cards && props.cards.map((card, key) => {

                                let logo = mastercard;
                                let slug = 'mastercard';
                                if(card.brand==='Visa'){
                                    logo = visa;
                                    slug = 'visa';
                                }else if(card.brand==='American Express'){
                                    logo = amex;
                                    slug = 'amex';
                                }

                                return (
                                        <div className="saved_card" key={key}>
                                            <div className='left d-flex align-items-center'>
                                                <div className='customcheck'></div>
                                                <div className='card_logo me-2'>
                                                    <img src={logo} alt={slug} />
                                                </div>
                                                <div className='card_info'>
                                                    <div className='card_name'>{card.brand} ending in {card.last_4_digit}</div>
                                                    <div className='expiry_date'>Expiry {card.exp_month}/{card.exp_year}</div>
                                                </div>
                                            </div>
                                            <button className='payment_btn' onClick={()=>handlePayment(card.stripe_customer_id)}>Pay</button>
                                        </div>
                                );
                            })}


                            <li className='position-relative p-0'>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header><img src={cardadd} alt="cardadd" className='me-1' />Add New Card</Accordion.Header>
                                        <Accordion.Body>
                                            <Elements stripe={stripePromise}>
                                                <CheckoutForm
                                                    plan_id={props.plan_id}
                                                />
                                            </Elements>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </li>
                        </ul>

                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}