import { Col, Row, Container, Card } from 'react-bootstrap';
import Sidebar from './Sidebar';
import Header from './Header';
import Dropdown from 'react-bootstrap/Dropdown';
import lovewallone from "../Images/lovewallone.png";
import { useNavigate } from "react-router-dom";
import more from "../Images/more.svg";
import code from "../Images/code.svg";
import duplicate from "../Images/duplicate.svg";
import remove from "../Images/remove.svg";
import edit from "../Images/edit.svg";
import { Link } from 'react-router-dom';
import { React, useState, useEffect,useRef } from 'react'
import SocialProofHeader from './SocialProofHeader';
import SavedWidgets from './Widgets/SavedWidgets';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SocialProof() {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken');
  const [widgets, setWidgets] = useState([]);
  const [savewall,setSavewall]= useState([]);
  const urlRef = useRef(null);

  const getSavedWidgetsAndWalls = () => {
    fetch(process.env.REACT_APP_API_URL + 'saved-widgets-and-walls', {
      method: 'GET',
      headers: {
        authorization: 'Bearer ' + accessToken,
        Accept: 'application/json',
      },
    }).then(response => response.json())
      .then(data => {
        setWidgets(data.widgets);
        setSavewall(data.walls);
      });
  }

  const deleteWidget = (id) => {
    fetch(process.env.REACT_APP_API_URL + 'delete-widget', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + accessToken,
      },
      body: JSON.stringify({ 'id': id })
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          setWidgets(data.widgets);
        } else {
          console.log(data.message);
        }
      });
  }

  const duplicateFun = (id) => {
    fetch(process.env.REACT_APP_API_URL + 'duplicate-widget', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + accessToken,
      },
      body: JSON.stringify({ 'id': id })
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          setWidgets(data.widgets);
        } else {
          console.log(data.message);
        }
      });
  }

  const handleCopyUrl = () => {
      const text = urlRef.current.value;
      navigator.clipboard.writeText(text).then(() => {
          toast.success("copied to clipboard");
      });
  };

  const DeleteWall = (id) => {
    fetch(process.env.REACT_APP_API_URL + 'delete-wall-love', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + accessToken,
      },
      body: JSON.stringify({ 'id': id })
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          setSavewall(data.data);
        } else {
          console.log(data.message);
        }
      });
  }

  const editWallTheme=(name,id)=>{
    navigate('/social-proof/proof-wall/template?slug='+btoa(name)+"&id="+btoa(id));
  }

  useEffect(() => {
    getSavedWidgetsAndWalls();
    localStorage.removeItem('Wall_id');
    localStorage.removeItem('love_Template');
    localStorage.removeItem('walllove_Name');
    localStorage.removeItem('love_testimonials_ids');
    localStorage.removeItem('Wall_url');
  }, [])

  return (
    <>
      <Container fluid className="pe-0">
        <Sidebar />
        <div className='wrapper'>
          <Header title="Media Studio" />
          <div className='inner_content_wrapper social_proof_inner saved'>
            <Row>
              <SocialProofHeader active="saved" />
              <Col md="12">

                <SavedWidgets 
                  widgets={widgets} 
                  onDeleteClick={deleteWidget} 
                  onDuplicateClick={duplicateFun}
                />

                <Card className='mt-3'>
                  <div className='card_box_wrapper'>
                    <h4>Your Custom Review Pages</h4>
                    <p>Share your testimonials with beautiful pages.</p>
                    <div className="scroll">
                      <div className="scroller_inner">
                        <ul> 
                          {savewall.map((value,index)=>(
                            <li key={index}>
                             <Link to="">
                                <div className='title'>{value.wall_name}</div>                         
                               <img src={value.thumbnail?value.thumbnail:lovewallone} alt="lovewallone" />
                             </Link>
                             <Dropdown>
                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                 <img src={more} alt="more" />
                               </Dropdown.Toggle>
                               <Dropdown.Menu> 
                                 <Dropdown.Item onClick={()=>editWallTheme(value.wall_name,value.id)}><img src={edit} alt="edit" className='me-2' />Edit</Dropdown.Item>
                                  <textarea 
                                    ref={urlRef} 
                                    defaultValue={value.wall_url} 
                                    style={{display:'none'}}
                                  />
                                 <Dropdown.Item onClick={handleCopyUrl}>
                                      <img src={code} alt="code" className='me-2' />Copy URL
                                  </Dropdown.Item>
                                 <Dropdown.Item  onClick={() => DeleteWall(value.id)}><img src={remove} alt="remove" className='me-2' />Delete</Dropdown.Item>
                               </Dropdown.Menu>
                             </Dropdown>
                           </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
}
export default SocialProof;

