import edit from "../Images/edit.png";
import star from "../Images/star.png";
import { Container, Col, Form, Button } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

async function addReview(params) {
    return fetch(process.env.REACT_APP_API_URL + 'add-review', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    })
        .then(data => data.json())
}

function Dashboard() {
    const navigate = useNavigate();
    const [message, setMessage] = useState(null);
    const [user,setUser] = useState();
    const [shortName, setShortName] = useState('');
    const urlParams = new URLSearchParams(window.location.search);
    const business_name = urlParams.get('business_name');
    const [validated, setValidated] = useState(false);
    const [serviceQuestions, setServiceQuestions] = useState([]);
    const [displayProofPuddingBrand, setDisplayProofPuddingBrand] = useState('none');

    function getUserNameInitials(name_) {
        const slices = name_.split(' ');
        let name_initials = '';
        slices.forEach(item => {
            name_initials = name_initials + '' + item.charAt(0).toUpperCase();
        });
        setShortName(name_initials);
    }

    async function getBusiness(params) {
        return fetch(process.env.REACT_APP_API_URL + 'get-business', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(data => data.json())
            .then(response => {
                console.log(response);
                setUser(response.data);
                if(response.data){
                    if(response.data && response.data.userplan.plan.type=='paid'){
                        setDisplayProofPuddingBrand('none');
                    }else{
                        setDisplayProofPuddingBrand('block');
                    }
                    getUserNameInitials(response.data.name);
                    setServiceQuestions(response.data.service_questions);
                }
            });
    }

    useEffect(() => {
        getBusiness({business_name});
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            if (!business_name)
                return toast.error('Something went wrong!');

            if (!message || !message.trim().length)
                return toast.error('Please add a review');

            const response = await addReview({
                message,
                business_name
            });

            if (response.status) {
                navigate('/write-a-review-final?business_name=' + business_name+'&id='+btoa(response.data.id)); 
            } else {
                toast.error(response.message)
            }
        }
    };

    return (
        <>
            <ToastContainer/>
            <Container fluid className="p-0">
                <div className='add_review'>
                    <Col>
                        <div className='add_reviews_inner position-relative m-auto'>
                            <div className='head_wrap d-flex justify-content-between'>
                                
                                {
                                user && user.image
                                ?
                                <img className='logo_review' src={user.image} />
                                :
                                <div className='logo_review'>{shortName?shortName:'PP'}</div>
                                }

                                <h4 className='mt-0'>{business_name?business_name:'Business Name'}</h4>
                            </div>
                            <div className='common_content_wrapper pt-2'>
                                <h3 className='text-center'>Write a Text Review</h3>
                                <p style={{whiteSpace: "pre-wrap"}}>{serviceQuestions}</p>
                                <ul>
                                    {/*{serviceQuestions && serviceQuestions.map((ques,key)=>{
                                        console.log(ques);
                                        return <li key={key}>{ques.text}</li>
                                    })}*/}
                                    
                                </ul>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="formBasicreviews">
                                        <textarea
                                            className='mt-0 w-100'
                                            placeholder='Write a review for others to see!'
                                            onChange={e => setMessage(e.target.value)}
                                        >
                                        </textarea>
                                    </Form.Group>
                                    <div className='common_btn_wrapper'>
                                        <Button type="submit"><img src={edit} alt="edit" />Write a review</Button>
                                    </div>
                                </Form>
                            </div>
                            <div className='powered_by' style={{display:displayProofPuddingBrand}}>
                                <h6 className='mb-0'>Powered by Proof Pudding<img src={star} alt="star" className='ms-1' /></h6>
                            </div>
                        </div>
                    </Col>
                </div>
            </Container>
        </>
    );
}
export default Dashboard;