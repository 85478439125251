import { Col, Row, Container, Card, Accordion, Form, Button } from 'react-bootstrap';
import Sidebar from './Sidebar';
import Header from './Header';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import SelectGoogleBusinessName from "./SelectGoogleBusinessName";
function Integrations() {
    const [platforms, setPlatforms] = useState([]);
    const [organisationName, setOrganisationName] = useState();
    const [facebookPageId, setFacebookPageId] = useState();
    const [G2ProductName, setG2ProductName]=useState();
    const [TrustPilotName,setTrustPilotName]=useState();
    const [YelpName,setYelpName]=useState();
    const [CapterraName,setCapterraName]=useState();
    const [YoutubeName,setYoutubeName]=useState();
    const [TripAdvisorName,setTripAdvisorName]=useState(); 
    const [ZillowUid,setZillowUid]=useState();
    const [tripadvisor_rental_id, setTripadvisorRentalId] = useState();
    const [realtor_advertiser_id, setRealtorAdvertiserId] = useState();

    const navigate = useNavigate();
    const accessToken = localStorage.getItem('accessToken');
    const user = JSON.parse(localStorage.getItem('user'));
    const email = user.email;
    const getSinglePlaceId = '';
    const [modalShow, setModalShow] = React.useState(false);
    
    const [name,setName]=React.useState([]);

    const getPlatforms = () => {
        fetch(process.env.REACT_APP_API_URL + 'platforms', {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            },
        }).then(response => response.json())
            .then(data => {
                setPlatforms(data.data);
            });
    }
    useEffect(() => {
        getPlatforms();
    }, []);

    const handleGoogleConnect = (event) => {
        event.preventDefault();
        if (organisationName) {
            getGoogleReviews(organisationName);
        }
    }
    const getGoogleReviews = (place_id) => {
        fetch(process.env.REACT_APP_API_URL + 'get-google-reviews', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ place_id: place_id,email:email })
        }).then(response => response.json())
            .then(data => {
                
                if(data.code==2){

                    setModalShow(true);
                    setName(data.data);
                    console.log(data.data);
                    return false;
                }
                if (data.status) {
                    setModalShow(false);
                    toast.success(data.message);
                    setTimeout(() => {
                        navigate('/proof-wall/combined');
                    }, 500);
                } else {
                    toast.error(data.message);
                }
            });
    }

    const saveGoogleReviwes = async () => {
        const response = await getGoogleReviews({
            'place_id': place_id
        }, accessToken);

        if(response.status){
            // setProjects(response.projects);
            setModalShow(false);
        }else{
            toast.error(response.message);
        }
    }
     
    const handleSearch = async (org_name) => {
        try {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ address: org_name }, (results, status) => {
                if (status === "OK") {
                    const placeId = results[0].place_id;
                    getGoogleReviews(placeId);
                } else {
                    const placeId = 'heloo';
                    getGoogleReviews(placeId);
                    toast.error('Business not found');
                    console.log("Geocode was not successful for the following reason: " + status);
                }
            });
        } catch (error) {
            console.error(error);
        }
    };
    const facebookConnect = () => {
        fetch(process.env.REACT_APP_API_URL + 'get-facebook-reviews', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ page_id: facebookPageId,email:email })
        }).then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.status) {
                    toast.success(data.message);
                    setTimeout(() => {
                        navigate('/proof-wall/combined');
                    }, 500);
                } else {
                    toast.error(data.message);
                }
            });
    }

    const G2Connect = () => {

        fetch(process.env.REACT_APP_API_URL + 'get-g2-reviews', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ product_id: G2ProductName,email:email })
        }).then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.status) {
                    toast.success(data.message);
                    setTimeout(() => {
                        navigate('/proof-wall/combined');
                    }, 500);
                } else {
                    toast.error(data.message);
                }
            });
    }
 const TrustPilotConnect = () => {
        fetch(process.env.REACT_APP_API_URL + 'get-trustpilot-reviews', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ trustpilot_id: TrustPilotName,email:email })
        }).then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.status) {
                    toast.success(data.message);
                    setTimeout(() => {
                        navigate('/proof-wall/combined');
                    }, 500);
                } else {
                    toast.error(data.message);
                }
            });
    }

 const YELPConnect = () => {
        fetch(process.env.REACT_APP_API_URL + 'get-yelp-reviews', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ place_id: YelpName,email:email })
        }).then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.status) {
                    toast.success(data.message);
                    setTimeout(() => {
                        navigate('/proof-wall/combined');
                    }, 500);
                } else {
                    toast.error(data.message);
                }
            });
    }
   
   const CapterraConnect = () => {
        fetch(process.env.REACT_APP_API_URL + 'get-capterra-reviews', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ place_id: CapterraName,email:email })
        }).then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.status) {
                    toast.success(data.message);
                    setTimeout(() => {
                        navigate('/proof-wall/combined');
                    }, 500);
                } else {
                    toast.error(data.message);
                }
            });
    } 

    const YoutubeConnect = () => {
        fetch(process.env.REACT_APP_API_URL + 'get-youtube-reviews', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ youtube_channel_id: YoutubeName,email:email })
        }).then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.status) {
                    toast.success(data.message);
                    setTimeout(() => {
                        navigate('/proof-wall/combined');
                    }, 500);
                } else {
                    toast.error(data.message);
                }
            });
    } 
  
     const TripAdvisorConnect = () => {
        fetch(process.env.REACT_APP_API_URL + 'get-tripadvisor-reviews', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ place_id: TripAdvisorName,email:email })
        }).then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.status) {
                    toast.success(data.message);
                    setTimeout(() => {
                        navigate('/proof-wall/combined');
                    }, 500);
                } else {
                    toast.error(data.message);
                }
            });
    } 

    const button_style = {
        maxWidth: '270px',
        margin: '10px auto 10px',
        color: '#2B0C04',
        backgroundColor: '#05DD7B',
        borderColor: '#05DD7B',
        width: 'calc(100 % - 100px)',
        display: 'block',
        height: '50px',
        borderRadius: '100px',
        fontSize: '14px',
        fontWeight: '600',
        textDecoration: 'none',
        textAlign: 'center',
        lineHeight: '50px'
    };

    const getUser = () => {
        fetch(process.env.REACT_APP_API_URL + 'get-user', {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            },
        }).then(response => response.json())

            .then(data => {
                 console.log(data.data);
                setFacebookPageId(data.data.facebook_page_id);
                setOrganisationName(data.data.business_name);
                setG2ProductName(data.data.g2_product_name);
                setTrustPilotName(data.data.trustpilot_place_name);
                setYelpName(data.data.yelp_place_name);
                setCapterraName(data.data.capterra_place_id);
                setYoutubeName(data.data.youtube_channel_id);
                setZillowUid(data.data.zillow_uid); 
                setTripadvisorRentalId(data.data.tripadvisor_rental_id);
                setRealtorAdvertiserId(data.data.realtor_advertiser_id);

                
                
            });
    }
    useEffect(() => {
        getUser();
    }, []);

    const twitter_authorize_url = 'https://twitter.com/i/oauth2/authorize?response_type=code&client_id=dHpQSVZQM2xYZE1FbjFFLUtOOFY6MTpjaQ&redirect_uri=' + process.env.REACT_APP_TWITTER_CALLBACK_URL + '&scope=tweet.read%20users.read%20follows.read%20offline.access&state=' + email + '&code_challenge=unique_string&code_challenge_method=plain';

    const vimeo_authorization_url = "https://api.vimeo.com/oauth/authorize?response_type=code&client_id=f6787127e83c97614e8159d794987512e36fab72&scope=offline&redirect_uri="+process.env.REACT_APP_VIMEO_URL+"vimeo-callback&state="+email;


    // const youtube_authorization_url = "https://accounts.google.com/o/oauth2/v2/auth?client_id=197546576636-07j1rvv4s1023n5ju3o89iha5nddqaic.apps.googleusercontent.com&redirect_uri="+process.env.REACT_APP_API_URL+"youtube-callback&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email&access_type=offline&state="+email;

    const youtube_authorization_url = ""+process.env.REACT_APP_API_URL+"youtube-callback?email="+email;

    const bbb_authorization_url = ""+process.env.REACT_APP_API_URL+"bbb-callback?email="+email;

    const zillow_authorization_url = ""+process.env.REACT_APP_API_URL+"zillow-callback?email="+email+"&zillow_uid="+ZillowUid;

    const realtor_authorization_url = ""+process.env.REACT_APP_API_URL+"realtor-callback?email="+email+"&realtor_advertiser_id="+realtor_advertiser_id;

    const tripadvisor_authorization_url = ""+process.env.REACT_APP_API_URL+"get-tripadvisor-reviews?email="+email+"&tripadvisor_rental_id="+tripadvisor_rental_id;

    return (
        <>
            <Container fluid className="pe-0">
                <Sidebar />
                <div className='wrapper'>
                    <ToastContainer />
                    <Header title="Integrations" />
                    <div className='inner_content_wrapper'>
                        <Row>
                            <Col>
                                <Card>
                                    <div className='integrations'>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h4>All platform details</h4>
                                            </div>
                                        </div>
                                        <Accordion defaultActiveKey="0" className='mt-3'>

                                            {platforms.map(function (platform, key) {
                                                let display = platform.slug === 'proof_pudding' ? 'none' : '';
                                                return (
                                                    <Accordion.Item eventKey={key} key={key} style={{ display: display }}>
                                                        <Accordion.Header><img src={process.env.REACT_APP_PLATFORM_LOGO_URL + '' + platform.logo} alt={platform.name} className='me-1' />{platform.name}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className='form_wrapper pt-0 mt-3'>
                                                                <Form>
                                                                    {
                                                                        platform.slug === 'twitter'
                                                                            ?
                                                                            <Row>
                                                                                <a href={twitter_authorize_url} target="_blank" style={button_style}>Connect</a>
                                                                            </Row>
                                                                            :
                                                                            platform.slug === 'google'
                                                                                ?
                                                                                <>
                                                                                    <Row>
                                                                                        <Col lg="12" className='pe-2'>
                                                                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                                                                <Form.Label>Business Name</Form.Label>
                                                                                                <Form.Control
                                                                                                    type="name"
                                                                                                    placeholder="Business Name as it is on your google review page"
                                                                                                    value={organisationName}
                                                                                                    onChange={(e) => setOrganisationName(e.target.value)}
                                                                                                />
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row><Button type="button" onClick={handleGoogleConnect}>Connect</Button></Row>
                                                                                </>
                                                                                :
                                                                                platform.slug === 'facebook'
                                                                                    ?
                                                                                    <Row>
                                                                                        <Col lg="12" className='pe-2'>
                                                                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                                                                <Form.Label>Page Id</Form.Label>
                                                                                                <Form.Control
                                                                                                    type="text"
                                                                                                    placeholder="Page Id. E.g, metmuseum of https://www.facebook.com/metmuseum"
                                                                                                    value={facebookPageId}
                                                                                                    onChange={(e) => setFacebookPageId(e.target.value)}
                                                                                                />
                                                                                            </Form.Group>
                                                                                        </Col>

                                                                                        <Button type="button" onClick={facebookConnect}>Connect</Button>
                                                                                    </Row>
                                                                                    :
                                                                                    platform.slug === 'vimeo'
                                                                                        ?
                                                                                        <Row>
                                                                                            <a href={vimeo_authorization_url} target="_blank" style={button_style}>Connect</a>
                                                                                        </Row>

                                                                                         :

                                                                                    platform.slug === 'g2'
                                                                                        ?
                                                                                    <Row>
                                                                                        <Col lg="12" className='pe-2'>
                                                                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                                                                <Form.Label>Product Name</Form.Label>
                                                                                                <Form.Control
                                                                                                    type="text"
                                                                                                    placeholder="Please enter G2 product name e.g  slack"
                                                                                                    value={G2ProductName}
                                                                                                    onChange={(e) => setG2ProductName(e.target.value)}
                                                                                                />
                                                                                            </Form.Group>
                                                                                        </Col>

                                                                                        <Button type="button" onClick={G2Connect}>Connect</Button>
                                                                                    </Row>
                                                                                     :
                                                                                      platform.slug === 'yelp'
                                                                                        ?
                                                                                    <Row>
                                                                                        <Col lg="12" className='pe-2'>
                                                                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                                                                <Form.Label>Place Name</Form.Label>
                                                                                                <Form.Control
                                                                                                    type="text"
                                                                                                    placeholder="Please enter place name in Yelp    e.g  'best-buy-midtown-manhattan-44th-and-5th-new-york'"
                                                                                                    value={YelpName}
                                                                                                    onChange={(e) => setYelpName(e.target.value)}
                                                                                                />
                                                                                            </Form.Group>
                                                                                        </Col>

                                                                                        <Button type="button" onClick={YELPConnect}>Connect</Button>
                                                                                    </Row>
                                                                                     :
                                                                                    platform.slug === 'trustpilot'
                                                                                        ?
                                                                                    <Row>
                                                                                        <Col lg="12" className='pe-2'>
                                                                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                                                                <Form.Label>Place Name</Form.Label>
                                                                                                <Form.Control
                                                                                                    type="text"
                                                                                                    placeholder="Please enter place name in Trustpilot  e.g  slack.com"
                                                                                                    value={TrustPilotName}
                                                                                                    onChange={(e) => setTrustPilotName(e.target.value)}
                                                                                                />
                                                                                            </Form.Group>
                                                                                        </Col>

                                                                                        <Button type="button" onClick={TrustPilotConnect}>Connect</Button>
                                                                                    </Row>
                                                                                    :

                                                                                    platform.slug === 'capterra'
                                                                                        ?
                                                                                    <Row>
                                                                                        <Col lg="12" className='pe-2'>
                                                                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                                                                <Form.Label>Place Id</Form.Label>
                                                                                                <Form.Control
                                                                                                    type="text"
                                                                                                    placeholder="Please enter place id in Capterra  e.g  135003"
                                                                                                    value={CapterraName}
                                                                                                    onChange={(e) => setCapterraName(e.target.value)}
                                                                                                />
                                                                                            </Form.Group>
                                                                                        </Col>

                                                                                        <Button type="button" onClick={CapterraConnect}>Connect</Button>
                                                                                    </Row>
                                                                                    :

                                                                                    platform.slug === 'youtube'
                                                                                        ?
                                                                                    <Row>
                                                                                        <Col lg="12" className='pe-2'>
                                                                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                                                                <Form.Label>Channel Id</Form.Label>
                                                                                                <Form.Control
                                                                                                    type="text"
                                                                                                    placeholder="Please enter channel id in youtube"
                                                                                                    value={YoutubeName}
                                                                                                    onChange={(e) => setYoutubeName(e.target.value)}
                                                                                                />
                                                                                            </Form.Group>
                                                                                        </Col>

                                                                                        <Button type="button" onClick={YoutubeConnect}>Connect</Button>
                                                                                         
                                                                                    </Row>
                                                                                    :

                                                                                    platform.slug === 'zillow'
                                                                                        ?

                                                                                    <Row>
                                                                                         <Col lg="12" className='pe-2'>
                                                                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                                                                <Form.Label>Zillow Uid</Form.Label>
                                                                                                <Form.Control
                                                                                                    type="text"
                                                                                                    placeholder="Please enter zillow uid e.g  X1-ZUz0nmomozy2o9_9bpwk"
                                                                                                    value={ZillowUid}
                                                                                                    onChange={(e) => setZillowUid(e.target.value)}
                                                                                                />
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                     
                                                                                        <a href={zillow_authorization_url}   style={button_style}>Connect</a> 
                                                                                        
                                                                                    </Row>
                                                                                     
                                                                                    :

                                                                                    platform.slug === 'realtor'
                                                                                        ?

                                                                                    <Row>
                                                                                        <Col lg="12" className='pe-2'>
                                                                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                                                                <Form.Label>Realtor Advertiser Id</Form.Label>
                                                                                                <Form.Control
                                                                                                    type="text"
                                                                                                    placeholder="Please enter realtor advertiser id   e.g  1633379"
                                                                                                    value={realtor_advertiser_id}
                                                                                                    onChange={(e) => setRealtorAdvertiserId(e.target.value)}
                                                                                                />
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                     
                                                                                        <a href={realtor_authorization_url}   style={button_style}>Connect</a> 
                                                                                        
                                                                                    </Row>
                                                                                    :

                                                                                    platform.slug === 'tripadvisor'
                                                                                        ?

                                                                                    <Row>
                                                                                        <Col lg="12" className='pe-2'>
                                                                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                                                                <Form.Label>Tripadvisor Rental Id</Form.Label>
                                                                                                <Form.Control
                                                                                                    type="text"
                                                                                                    placeholder="Please enter tripadvisor rental id e.g  93437"
                                                                                                    value={tripadvisor_rental_id}
                                                                                                    onChange={(e) => setTripadvisorRentalId(e.target.value)}
                                                                                                />
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                        
                                                                                        <a href={tripadvisor_authorization_url}   style={button_style}>Connect</a> 
                                                                                    </Row>

                                                                                    :

                                                                                    platform.slug === 'bbb'
                                                                                        ?

                                                                                    <Row>
                                                                                     
                                                                                        <a href={bbb_authorization_url} target="_blank" style={button_style}>Connect</a> 
                                                                                        
                                                                                    </Row>
                                                                                    :
                                                                                     

                                                                                        <p style={{
                                                                                            textAlign: 'center',
                                                                                            fontWeight: '700',
                                                                                            fontSize: '15px',
                                                                                            color: '#422615'
                                                                                        }}>Coming Soon!</p>
                                                                    }
                                                                </Form>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>);
                                            })}
                                        </Accordion>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
            <SelectGoogleBusinessName
                show={modalShow}
                onHide={() => setModalShow(false)}
                name={name}
                saveGoogleReviwes={saveGoogleReviwes}
            />
        </>
    );
}
export default Integrations;
