import React from "react";
import VideoPlayer from '../VideoPlayer';
import VimeoPlayer from "../VimeoPlayer";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

export default function WidgetTestimonials(props) {

    let temp_arr = [];
    const selectTestimonial = (e) => {
        const {value, checked} = e.target;
        if(checked){
            temp_arr.push(value);
        }else{
            temp_arr.splice(temp_arr.indexOf(value), 1);
        }
        localStorage.setItem('testimonials_ids', temp_arr);
    }

    return (
        <>

          <div className="masonry mt-0">
              <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
              >                   
                  <Masonry gutter={10}>                    
                        {props.testimonials && props.testimonials.map((testimonial, key) => {
                            return (
                                <div className='box position-relative' key={key}>
                                    <input 
                                        type="checkbox" 
                                        name='radio'
                                        value={testimonial.id}
                                        onClick={selectTestimonial} 
                                    />
                                    <label className='custom_radio'></label>
                                    <div className="widgets flex">
                                        <div className="widgets_one_inner" >
                                            <div className="customer_image text-start">
                                            {
                                                testimonial.reviewer_picture
                                                    ?
                                                    <img src={testimonial.reviewer_picture} alt="customerimage" className="rounded-circle" />
                                                    :
                                                    <img src="https://senjaio.b-cdn.net/public/media/default-avatar_EWOV398Bs.jpeg?width=48&height=48" alt="customerimage" className="rounded-circle" />
                                            }
                                            </div>
                                            <div className="customer_name text-start pt-1">
                                                {testimonial.reviewer_name}
                                            </div>
                                            <div className="quote text-start">
                                                
                                                <p className="mb-0">
                                                    {testimonial.text}
                                                </p>
                                                {testimonial.files ?
                                                    <div className='upload_post_image mt-3'>
                                                        {
                                                            testimonial.platform_name === 'proof_pudding'
                                                                ?
                                                                <VideoPlayer src={testimonial.media[0].file_url} id={testimonial.media[0].id} />
                                                                :
                                                                testimonial.media[0].type === 'photo'
                                                                    ?
                                                                    <img src={testimonial.media[0].file_url} alt="image" className='w-100' />
                                                                    :
                                                                    testimonial.platform_name === 'vimeo'
                                                                    ?
                                                                    <VimeoPlayer key={testimonial.review_id} videoId={testimonial.review_id} />
                                                                    :
                                                                    <VideoPlayer src={testimonial.media[0].file_url} id={testimonial.media[0].id} />
                                                        }
                                                    </div>
                                                    : ''}
                                                <p className="mb-0 mt-2">
                                                    {testimonial.review_date_2}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                  </Masonry>
              </ResponsiveMasonry>                    
          </div>              
        </>
    );
}