import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Images/proof-wall-logo.png"
import VideoPlayer from '../VideoPlayer';
import VimeoPlayer from "../VimeoPlayer";
import Trash from "../../Images/trash.png"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

function Walloflovetwo(props) {
    const handleRemove = (review_id) => {
        const testimonials_ids = localStorage.getItem('love_testimonials_ids');
        const array = testimonials_ids.split(",");
        const newArray = array.filter((item) => item != review_id);
        const new_testimonials_ids = newArray.join(", ");

        if (new_testimonials_ids) {
            const eventData = { data: new_testimonials_ids };
            const event = new CustomEvent('lovewallData', { detail: eventData });
            document.dispatchEvent(event);
            localStorage.removeItem("love_testimonials_ids");
        }
    }
    return (
        <>
            <div className="wall_of_love  overflow-auto" id="wall_of_love_theme">
                <div className="wall_of_love_inner_two" style={{ backgroundColor: props.Bgcolor }}>
                    <div className="header">
                        <div className="import_logo">
                            <img src={props.logoimage != '' ? props.logoimage : logo} style={{ height: props.size != 0 ? props.size + "px" : "20px" }} />
                        </div>                    
                        <h4>{props.pageTitle}</h4>
                        <p>{props.subtitle}</p>
                        <Link 
                            className={props.ctaTitle && props.ctaURL ? '' : 'd-none'} 
                            to={props.ctaURL}
                            style={{backgroundColor:props.buttonColor}} 
                            >
                            {props.ctaTitle}
                        </Link>
                    </div>
                </div>
                <div className="lovewall pt-2">
                    <div className="masonry">
                        <ResponsiveMasonry
                            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                        >
                            <Masonry gutter={10}>
                                {props.data.map((value, index) => (
                                    <div className="lovewall_two_inner position-relative w-100" style={{backgroundColor:props.reviewColor}}>
                                        <button onClick={() => handleRemove(value.id)} className={props.showDelete ? "remove" : 'd-none'}><img src={Trash} /></button>
                                        <div className="customer_image text-start">
                                            {
                                                value.reviewer_picture
                                                    ?
                                                    <img src={value.reviewer_picture} alt="customerimage" className="rounded-circle" />
                                                    :
                                                    <img src="https://senjaio.b-cdn.net/public/media/default-avatar_EWOV398Bs.jpeg?width=48&height=48" alt="customerimage" className="rounded-circle" />
                                            }
                                        </div>
                                        <div className="customer_name text-start pt-1" style={{color:props.fontColor,fontFamily:props.fontFamily,fontSize:props.authorFont}}>
                                            {value.reviewer_name}
                                        </div>
                                        <div className="quote text-start">

                                            <p className="mb-0" style={{color:props.fontColor,fontFamily:props.fontFamily,fontSize:props.reviewFont}}>
                                                {value.text}
                                            </p>
                                            {value.files ?
                                                <div className='upload_post_image mt-3'>
                                                    {
                                                        value.platform_name === 'proof_pudding'
                                                            ?
                                                            <VideoPlayer src={value.media[0].file_url} id={value.media[0].id} />
                                                            :
                                                            value.media[0].type === 'photo'
                                                                ?
                                                                <img src={value.media[0].file_url} alt="image" className='w-100' />
                                                                :
                                                                value.platform_name === 'vimeo'
                                                                ?
                                                                <VimeoPlayer key={value.review_id} videoId={value.review_id} />
                                                                :
                                                                <VideoPlayer src={value.media[0].file_url} id={value.media[0].id} />
                                                    }
                                                </div>
                                                : ''}
                                            <p className="mb-0 mt-2" style={{color:props.fontColor,fontFamily:props.fontFamily,fontSize:props.reviewFont}}>
                                                {value.review_date_2}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </Masonry>

                        </ResponsiveMasonry>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Walloflovetwo;