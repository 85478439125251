import { Container, Tab, Tabs, Form } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import React, { useEffect, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Bg1 from "../Images/Bg1.png";
import Bg2 from "../Images/Bg2.png";
import Bg3 from "../Images/Bg3.png";
import Bg4 from "../Images/Bg4.png";
import Bg5 from "../Images/Bg5.png";
import Bg6 from "../Images/Bg6.png";
import Bg7 from "../Images/Bg7.png";
import Bg8 from "../Images/Bg8.png";
import upload from "../Images/upload.svg";
import testimonialvector from "../Images/testimonialvector.png";
import templateone from "../Tem_images/1.png";
import templatetwo from "../Tem_images/2.png";
import templatethree from "../Tem_images/3.png";
import templatefour from "../Tem_images/4.png";
import templatefive from "../Tem_images/5.png";
import templateeight from "../Tem_images/8.png";
import Modaltestimonial from "./Modaltestimonial";
import StudioHeader from './StudioHeader';
import Template from './Template'
import { SketchPicker } from 'react-color';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import globe from "../Images/globe.svg";
import add from "../Images/add.svg";
import refresh from "../Images/refresh.svg";
import edittypo from "../Images/edittypo.svg";
import computer from "../Images/computer.svg";
import Dropdown from 'react-bootstrap/Dropdown';
import trash from "../Images/trash.png";

function SocialProof() {
    const accessToken = localStorage.getItem('accessToken');
    const user = JSON.parse(localStorage.getItem('user'));
    const [selecttheme, setSelectTheme] = useState('TemplateOne');
    const [selectTestimonial, setselecttestimonial] = useState([]);
    const localtestimonial = localStorage.getItem('testimonial_ids') != null ? localStorage.getItem('testimonial_ids') : null;
    const [imageUrl, setImageUrl] = useState('');
    const [activeTab, setActiveTab] = useState('templates');
    const [modalShow, setModalShow] = useState(false);
    const [rating, setRating] = useState(true);
    const [avtar, setAvtar] = useState(true);
    const [BgImage, setBgImage] = useState('');
    const [file, setFile] = useState();
    const [color, setColor] = useState('#000000');

    const [nameTextcolor, setNameTextColor] = useState('#000000');
    const [nameFontFamily, setNameFontFamily] = useState('Poppins');
    const [nameFontSize, setNameFontSize] = useState('17px');
    const [nameFontWeight, setNameFontWeight] = useState('700');
    const [nameLineHeight, setNameLineHeight] = useState('initial');
    const [nameLetterSpacing, setNameLetterSpacing] = useState('initial');
    const [nameWordSpacing, setNameWordSpacing] = useState('initial');
    const [namePaddingTop, setNamePaddingTop] = useState('0px');
    const [namePaddingRight, setNamePaddingRight] = useState('0px');
    const [namePaddingBottom, setNamePaddingBottom] = useState('0px');
    const [namePaddingLeft, setNamePaddingLeft] = useState('0px');

    const [reviewTextcolor, setReviewTextColor] = useState('#000000');
    const [reviewFontFamily, setReviewFontFamily] = useState('Poppins');
    const [reviewFontSize, setReviewFontSize] = useState('17px');
    const [reviewFontWeight, setReviewFontWeight] = useState('400');
    const [reviewLineHeight, setReviewLineHeight] = useState('initial');
    const [reviewLetterSpacing, setReviewLetterSpacing] = useState('initial');
    const [reviewWordSpacing, setReviewWordSpacing] = useState('initial');
    const [reviewPaddingTop, setReviewPaddingTop] = useState('0px');
    const [reviewPaddingRight, setReviewPaddingRight] = useState('0px');
    const [reviewPaddingBottom, setReviewPaddingBottom] = useState('0px');
    const [reviewPaddingLeft, setReviewPaddingLeft] = useState('0px');

    const [showPicker, setShowPicker] = useState(false);
    const [showNamePicker, setShowNamePicker] = useState(false);
    const [showReviewPicker, setShowReviewPicker] = useState(false);
    const [Bgcolor, setBgColor] = useState('');
    const [Bgstyle, setBgstyle] = useState(true);
    const [paddingvalue, setPaddingValue] = React.useState(0);
    // const [sizevalue, setSizeValue] = useState(30);
    const [sizevalue, setSizeValue] = useState(0);
    const [logoposition, setLogoPosition] = useState('');
    const [logoimg, setLogoImg] = useState('');

    const [profileImg, setProfileImg] = useState();
    const [fileKey, setFileKey] = useState(0)

    const [allowedSocialImages, setAllowedSocialImages] = useState();

    const handleClick = () => {
        setShowPicker(!showPicker);
    };
    const handleClose = () => {
        setShowPicker(false);
    };
    const handleChangecolor = (newColor) => {
        setBgImage('');
        setBgColor(newColor.hex)
        setColor(newColor.hex);

    };
    function handleChangebgImage(e) {
        setBgColor('');
        setBgImage(URL.createObjectURL(e.target.files[0]));
        setFile(URL.createObjectURL(e.target.files[0]));
    }
    function handleChangelogoImage(e) {
        setLogoImg(URL.createObjectURL(e.target.files[0]));
    }
    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (tab === 'testimonials') {
            setModalShow(true);
        }
    };

    // adding name customizations
    function handlePickerClick() {
        setShowNamePicker(!showNamePicker);
    }
    function handleColorChange(newColor) {
        setNameTextColor(newColor.hex);
    }
    const handleNameFontFamilyChange = (event) => {
        setNameFontFamily(event.target.value);
    }
    const handleNameFontChange = (event) => {
        setNameFontSize(event.target.value + 'px');
    }
    const handleNameFontWeightChange = (event) => {
        setNameFontWeight(event.target.value);
    }
    const handleNameLineHeightChange = (event) => {
        setNameLineHeight(event.target.value + 'px');
    }
    const handleNameLetterSpacingChange = (event) => {
        setNameLetterSpacing(event.target.value + 'px');
    }
    const handleNameWordSpacingChange = (event) => {
        setNameWordSpacing(event.target.value + 'px');
    }
    const handleNamePaddingTopChange = (event) => {
        setNamePaddingTop(event.target.value ? event.target.value + 'px' : '0px');
    }
    const handleNamePaddingRightChange = (event) => {
        setNamePaddingRight(event.target.value ? event.target.value + 'px' : '0px');
    }
    const handleNamePaddingBottomChange = (event) => {
        setNamePaddingBottom(event.target.value ? event.target.value + 'px' : '0px');
    }
    const handleNamePaddingLeftChange = (event) => {
        setNamePaddingLeft(event.target.value ? event.target.value + 'px' : '0px');
    }
    // adding name customizations

    // review name customization
    function handleReviewPickerClick() {
        setShowReviewPicker(!showReviewPicker);
    }
    function handleReviewColorChange(newColor) {
        setReviewTextColor(newColor.hex);
    }
    const handleReviewFontFamilyChange = (event) => {
        setReviewFontFamily(event.target.value);
    }
    const handleReviewFontChange = (event) => {
        setReviewFontSize(event.target.value + 'px');
    }
    const handleReviewFontWeightChange = (event) => {
        setReviewFontWeight(event.target.value);
    }
    const handleReviewLineHeightChange = (event) => {
        setReviewLineHeight(event.target.value + 'px');
    }
    const handleReviewLetterSpacingChange = (event) => {
        setReviewLetterSpacing(event.target.value + 'px');
    }
    const handleReviewWordSpacingChange = (event) => {
        setReviewWordSpacing(event.target.value + 'px');
    }
    const handleReviewPaddingTopChange = (event) => {
        setReviewPaddingTop(event.target.value ? event.target.value + 'px' : '0px');
    }
    const handleReviewPaddingRightChange = (event) => {
        setReviewPaddingRight(event.target.value ? event.target.value + 'px' : '0px');
    }
    const handleReviewPaddingBottomChange = (event) => {
        setReviewPaddingBottom(event.target.value ? event.target.value + 'px' : '0px');
    }
    const handleReviewPaddingLeftChange = (event) => {
        setReviewPaddingLeft(event.target.value ? event.target.value + 'px' : '0px');
    }
    // review name customization

    // profile image
    const handleProfileChange = (event) => {
        setProfileImg(URL.createObjectURL(event.target.files[0]));
        setFileKey(fileKey + 1);
    }
    // profile image

    async function socialImageDownloaded(params, token) {
        return fetch(process.env.REACT_APP_API_URL + 'social-image-downloaded', {
            method: 'POST',
            mode: 'cors',
            headers: {
                authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(data => data.json())
            .then(response => {
                localStorage.setItem('user', JSON.stringify(response['data']));
            });
    }

    const handleConvert = () => {
        const options = {
            width: 1000,
            height: 700,
            style: {
                justifyContent: 'center',
                alignItems: 'center',
            },
        };
        const node = document.getElementById('image_dowload');
        html2canvas(node, {
            allowTaint: true,
            useCORS: true,
            logging: true
        })
            .then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                setImageUrl(imgData);
                const link = document.createElement('a');
                link.download = 'image_social_proof_' + Math.floor(Math.random() * 10) + '.png';
                link.href = imgData;
                link.click();
                socialImageDownloaded({'item':1},accessToken);
            }).catch(function (error) {
                console.error('oops, something went wrong!', error);
            });    
    };
    const handleConvertjpeg = () => {
        const node = document.getElementById('image_dowload');
        html2canvas(node, {
            allowTaint: true,
            useCORS: true,
            logging: true
        })
            .then(canvas => {
                const imgData = canvas.toDataURL('image/jpeg');
                var link = document.createElement('a');
                link.download = 'image_social_proof_' + Math.floor(Math.random() * 10) + '.jpeg';
                link.href = imgData;
                link.click();
                socialImageDownloaded({'item':1},accessToken);
            }).catch(function (error) {
                console.error('oops, something went wrong!', error);
            });    
    };

    const captureImages = (refs) => {
        const promises = refs.map((ref) =>
            html2canvas(ref, {
                allowTaint: true,
                useCORS: true,
                logging: true
            }).then((canvas) => canvas.toDataURL())
        );
        return Promise.all(promises);
    };
    const handleConvertZip = () => {
        const node = document.getElementById('image_dowload');
        let imageRefs = [node];
        captureImages(imageRefs).then((imageData) => {
            const zip = new JSZip();
            imageData.forEach((data, i) => {
                zip.file(`image-${i}.png`, data.substr(data.indexOf(',') + 1), {
                    base64: true,
                });
            });
            zip.generateAsync({ type: 'blob' }).then((content) => {
                saveAs(content, 'images.zip');
            });
            socialImageDownloaded({'item':1},accessToken);
        });
    };
    function changetheme(slug) {
        setBgImage('');
        setBgColor('');
        setLogoPosition('');
        setLogoImg('');
        setPaddingValue(0)
        setSizeValue(0)
        setSelectTheme(slug);
        if (slug === 'TemplateThree') {
            setBgColor('#725d5d');
            setSizeValue(0);
            setNameFontFamily("Arial");
        } else if (slug === 'TemplateSix') {
            setBgColor('#63bcc4');
            setSizeValue(30);
            setNameFontFamily("Arial");
        }

        if (slug === 'TemplateFive') {
            setReviewPaddingTop('80px');
            setReviewPaddingRight('80px');
            setReviewPaddingBottom('40px');
            setReviewPaddingLeft('40px');
            setNameFontSize('17px');
            setSizeValue(30);
            setNameFontFamily("Arial");
        } else if (slug === 'TemplateThree') {
            setReviewPaddingTop('0px');
            setReviewPaddingRight('40px');
            setReviewPaddingBottom('0px');
            setReviewPaddingLeft('40px');
            setNameFontSize('17px');
            setNameFontFamily("Arial");
        }else if(slug==='TemplateEight'){
            setNameFontFamily("'Nanum Brush Script', cursive");
            setNameFontSize('25px');
            setSizeValue(30);
        } else {
            setReviewPaddingTop('0px');
            setReviewPaddingRight('0px');
            setReviewPaddingBottom('0px');
            setReviewPaddingLeft('0px');
            setNameFontSize('17px');
            setSizeValue(30);
            setNameFontFamily("Arial");
        }
    }

    useEffect(() => {
        let value = localStorage.getItem('image_template');
        
        setAllowedSocialImages(user.userplan.plan.no_of_social_images==='unlimited'?100:user.userplan.plan.no_of_social_images);

        if (value) {
            setSelectTheme(value);
            value === 'TemplateThree' && setBgColor('#725d5d');
            value === 'TemplateSix' && setBgColor('#63bcc4');

            if(value==='TemplateEight'){
                setNameFontFamily("'Nanum Brush Script', cursive");
                setNameFontSize('25px');
            } else {
                setNameFontSize('17px');
                setNameFontFamily("Arial");
            }
        }
        localStorage.removeItem("testimonial_ids");
        document.addEventListener('childData', handleChildData);
        return () => {
            document.removeEventListener('childData', handleChildData);
        };
    }, []);

    const handleChildData = (event) => {
        localStorage.setItem('testimonial_ids', event.detail.data);
        setselecttestimonial(event.detail.data);
    };

    return (
        <>
            <ToastContainer />
            <Container fluid className="p-0">
                <div className='edit_wrapper'>
                    <StudioHeader 
                        handleConvertfunction={handleConvert} 
                        jpegfunction={handleConvertjpeg} 
                        Zipfunction={handleConvertZip} 
                    />
                    <div className='edit_content_wrapper d-flex'>
                        <div className='edit_sidebar'>

                            <Tabs
                                activeKey={activeTab}
                                onSelect={handleTabClick}
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="testimonials" title="Testimonials" >
                                    <Modaltestimonial
                                        show={modalShow}
                                        onHide={() => {
                                            setModalShow(false);
                                            setActiveTab("templates");
                                        }}
                                    />
                                </Tab>

                                <Tab eventKey="templates" title="Templates">
                                    <div className='edit_testimonial_list'>
                                        <ul className='p-0 m-0'>
                                            {/*<li className={allowedSocialImages>=1?'':'d-none'}>
                                                <Link to="">
                                                    <img src={templateone} alt="templateone" onClick={changetheme.bind(this, 'TemplateOne')} />
                                                </Link>
                                            </li>*/}
                                            <li className={allowedSocialImages>=2?'':'d-none'}>
                                                <Link to="">
                                                    <img src={templatetwo} alt="templatetwo" onClick={changetheme.bind(this, 'TemplateTwo')} />
                                                </Link>
                                            </li>
                                            <li className={allowedSocialImages>=3?'':'d-none'}>
                                                <Link to="">
                                                    <img src={templatethree} alt="templatethree" onClick={changetheme.bind(this, 'TemplateThree')} />
                                                </Link>
                                            </li>
                                            {/*<li className={allowedSocialImages>=4?'':'d-none'}>
                                                <Link to="">
                                                    <img src={templatefour} alt="templatefour" onClick={changetheme.bind(this, 'TemplateFour')} />
                                                </Link>
                                            </li>*/}
                                            <li className={allowedSocialImages>=5?'':'d-none'}>
                                                <Link to="">
                                                    <img src={templatefive} alt="templatefive" onClick={changetheme.bind(this, 'TemplateFive')} />
                                                </Link>
                                            </li>

                                            <li className={allowedSocialImages>=6?'':'d-none'}>
                                                <Link to="">
                                                    <img src={templateeight} alt="templateeight" onClick={changetheme.bind(this, 'TemplateEight')} />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </Tab>

                                <Tab eventKey="design" title="Design">
                                    <div className='edit_testimonial_list'>

                                        <div className='logo_wrap'>
                                            <Form>
                                                <Form.Group className="mb-3" controlId="formBasicLogoupload">
                                                    <Form.Label>Logo</Form.Label>
                                                    <div className='custom_upload'>
                                                        <img src={upload} alt="upload" />
                                                        <label>Upload</label>
                                                        <Form.Control type="file" onChange={handleChangelogoImage} />
                                                    </div>
                                                    <img src={logoimg} className={logoimg ? ' mt-2' : 'd-none'} style={{ height: "70px", width: "100px" }} onClick={() => setLogoImg(logoimg)} />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="formBasicLogoposition">
                                                    <Form.Label>Logo Position</Form.Label>
                                                    <Form.Select onChange={(e) => setLogoPosition(e.target.value)}>
                                                        <option value="left top">Left Top</option>
                                                        <option value="right top">Right Top</option>
                                                        <option value="left bottom">Left Bottom</option>
                                                        <option value="right bottom">Right Bottom</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group className="mb-3 position-relative" controlId="formBasicLogosize">
                                                    <Form.Label>Logo Size</Form.Label>
                                                    <Form.Range value={sizevalue} min='0' max='120' onChange={(e) => { setSizeValue(e.target.value) }} />
                                                    <div className='ranecount'>{sizevalue}</div>
                                                </Form.Group>
                                                <Form.Group className="mb-3 position-relative" controlId="formBasicLogopadding">
                                                    <Form.Label>Logo Padding</Form.Label>
                                                    <Form.Range value={paddingvalue} min='0' onChange={(e) => { setPaddingValue(e.target.value) }} />
                                                    <div className='ranecount'>{paddingvalue}</div>
                                                </Form.Group>
                                            </Form>
                                        </div>
                                        <div className='background_style'>
                                            <Form>
                                                <Form.Group className="mb-3" controlId="formBasicBackgroundstyle">
                                                    <Form.Label>Background Style</Form.Label>
                                                    <Form.Select onChange={(e) => {
                                                        setBgstyle(e.target.value == 1 ? true : false)
                                                    }}>
                                                        <option value={1}>Image</option>
                                                        <option value={0}>Solid</option>
                                                    </Form.Select>
                                                    <div className={Bgstyle ? 'images_list' : 'd-none'}>
                                                        <ul>
                                                            <li>
                                                                <Link to=""><img src={Bg1} alt="Bg1" onClick={() => setBgImage(Bg1)} /></Link>
                                                            </li>
                                                            <li>
                                                                <Link to=""><img src={Bg2} alt="Bg2" onClick={() => setBgImage(Bg2)} /></Link>
                                                            </li>
                                                            <li>
                                                                <Link to=""><img src={Bg3} alt="Bg3" onClick={() => setBgImage(Bg3)} /></Link>
                                                            </li>
                                                            <li>
                                                                <Link to=""><img src={Bg4} alt="Bg4" onClick={() => setBgImage(Bg4)} /></Link>
                                                            </li>
                                                            <li>
                                                                <Link to=""><img src={Bg5} alt="Bg5" onClick={() => setBgImage(Bg5)} /></Link>
                                                            </li>
                                                            <li>
                                                                <Link to=""><img src={Bg6} alt="Bg6" onClick={() => setBgImage(Bg6)} /></Link>
                                                            </li>
                                                            <li>
                                                                <Link to=""><img src={Bg7} alt="Bg7" onClick={() => setBgImage(Bg7)} /></Link>
                                                            </li>
                                                            <li>
                                                                <Link to=""><img src={Bg8} alt="Bg8" onClick={() => setBgImage(Bg8)} /></Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Form.Group>
                                                <div className={Bgstyle ? 'd-none' : "border d-flex lh-lg bg-white rounded p-1"}>
                                                    <div className="rounded-circle border border-light mt-1"
                                                        style={{ backgroundColor: color, width: '20px', height: '20px' }}
                                                        onClick={handleClick}
                                                    />&nbsp;&nbsp;
                                                    <span> {color}</span>

                                                </div>
                                                {showPicker && (
                                                    <SketchPicker color={color} onChange={handleChangecolor} onClose={handleClose} />
                                                )}
                                                <Form.Group className={Bgstyle ? "mb-3" : 'd-none'} controlId="formBasicUploadbackground">
                                                    <Form.Label>Your Uploaded Backgrounds</Form.Label>
                                                    <div className='custom_upload'>
                                                        <img src={upload} alt="upload" />
                                                        <label>Upload</label>

                                                        <Form.Control type="file" onChange={handleChangebgImage} />
                                                    </div>
                                                    <img src={file} style={{ height: "70px", width: "100px" }} onClick={() => setBgImage(file)} className={!file ? 'd-none' : 'mt-2'} />
                                                </Form.Group>
                                            </Form>
                                        </div>

                                        {/* adding name customizations */}
                                        <div className='name_style style_wrap'>
                                            <div className='box'>
                                                <h5>Name</h5>
                                                <div className='edit_wrapper'>
                                                    <div className='box_inner d-flex align-items-center justify-content-between w-100'>
                                                        <label className='p-0'>Text Color</label>
                                                        <div className='d-flex align-items-center editer_inner'>
                                                            <div className='web_icon'>
                                                                <img src={globe} alt="globe" />
                                                            </div>
                                                            <div className='colorpicker'>
                                                                <div className='color' style={{ backgroundColor: nameTextcolor }} onClick={handlePickerClick} />
                                                                {showNamePicker && (
                                                                    <SketchPicker color={nameTextcolor} onChange={handleColorChange} />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='box_inner d-flex align-items-center justify-content-between w-100 mt-3'>
                                                        <label className='p-0'>Typography</label>
                                                        <div className='d-flex align-items-center editer_inner'>
                                                            <div className='web_icon'>
                                                                <Link to=""><img src={globe} alt="globe" /></Link>
                                                            </div>
                                                            <div className='edit_icon'>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                        <img src={edittypo} alt="edittypo" />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <div className='typography_info'>

                                                                            <div className='typo_head d-flex align-items-center justify-content-between'>
                                                                                <label className='p-0'>Typography</label>
                                                                                <div className='d-flex align-items-center'>
                                                                                    <div className='refresh'><img src={refresh} alt="refresh" /></div>
                                                                                    <div className='add'><img src={add} alt="add" /></div>
                                                                                </div>
                                                                            </div>

                                                                            <div className='dropdpown_wrap'>
                                                                                <label className='p-0'>Family</label>
                                                                                <Form.Select aria-label="Default select example" value={nameFontFamily} onChange={handleNameFontFamilyChange}>
                                                                                    <option value="'Poppins'">Poppins</option>
                                                                                    <option value="'Arial'">Arial</option>
                                                                                    <option value="'Helvetica'">Helvetica</option>
                                                                                    <option value="'Verdana'">Verdana</option>
                                                                                    <option value="'Nanum Brush Script', cursive">Nanum Brush Script, cursive</option>
                                                                                </Form.Select>
                                                                            </div>

                                                                            <div className='range_wrap'>
                                                                                <div className='top'>
                                                                                    <label className='p-0'>Size<img src={computer} alt="computer" /></label>

                                                                                </div>
                                                                                <div className='bottom'>
                                                                                    <Form.Range
                                                                                        onChange={handleNameFontChange}
                                                                                    />
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min='17'
                                                                                        placeholder={nameFontSize}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className='dropdpown_wrap'>
                                                                                <label className='p-0'>Weight</label>
                                                                                <Form.Select
                                                                                    aria-label="Default select example"
                                                                                    value={nameFontWeight}
                                                                                    onChange={handleNameFontWeightChange}
                                                                                >
                                                                                    <option value="700">700(Bold)</option>
                                                                                    <option value="600">600</option>
                                                                                    <option value="500">500</option>
                                                                                    <option value="400">400</option>
                                                                                </Form.Select>
                                                                            </div>

                                                                            <div className='dropdpown_wrap'>
                                                                                <label className='p-0'>Transform</label>
                                                                                <Form.Select aria-label="Default select example">
                                                                                    <option>Default</option>
                                                                                </Form.Select>
                                                                            </div>

                                                                            <div className='dropdpown_wrap'>
                                                                                <label className='p-0'>Style</label>
                                                                                <Form.Select aria-label="Default select example">
                                                                                    <option>Default</option>
                                                                                </Form.Select>
                                                                            </div>

                                                                            <div className='dropdpown_wrap'>
                                                                                <label className='p-0'>Decoration</label>
                                                                                <Form.Select aria-label="Default select example">
                                                                                    <option>Default</option>
                                                                                </Form.Select>
                                                                            </div>

                                                                            <div className='range_wrap'>
                                                                                <div className='top'>
                                                                                    <label className='p-0'>Line-Height<img src={computer} alt="computer" /></label>

                                                                                </div>
                                                                                <div className='bottom'>
                                                                                    <Form.Range
                                                                                        onChange={handleNameLineHeightChange}
                                                                                    />
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        placeholder={nameLineHeight}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className='range_wrap'>
                                                                                <div className='top'>
                                                                                    <label className='p-0'>Letter Spacing<img src={computer} alt="computer" /></label>

                                                                                </div>
                                                                                <div className='bottom'>
                                                                                    <Form.Range
                                                                                        onChange={handleNameLetterSpacingChange}
                                                                                    />
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        placeholder={nameLetterSpacing}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className='range_wrap'>
                                                                                <div className='top'>
                                                                                    <label className='p-0'>Word Spacing<img src={computer} alt="computer" /></label>
                                                                                </div>
                                                                                <div className='bottom'>
                                                                                    <Form.Range
                                                                                        onChange={handleNameWordSpacingChange}
                                                                                    />
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        placeholder={nameWordSpacing}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* adding name customizations ends */}

                                        {/* name padding starts */}
                                        <div className='padding_wrap style_wrap'>
                                            <div className='box'>
                                                <h5>Name padding</h5>
                                                <div className='range_wrap'>
                                                    <div className='top'>
                                                        <label className='p-0'>Padding<img src={computer} alt="computer" /></label>

                                                    </div>
                                                    <div className='bottom'>
                                                        <ul className='p-0'>
                                                            <li>
                                                                <Form.Control
                                                                    type="number"
                                                                    onChange={handleNamePaddingTopChange}
                                                                />
                                                                <label className='p-0'>TOP</label>
                                                            </li>
                                                            <li>
                                                                <Form.Control
                                                                    type="number"
                                                                    onChange={handleNamePaddingRightChange}
                                                                />
                                                                <label className='p-0'>RIGHT</label>
                                                            </li>
                                                            <li>
                                                                <Form.Control
                                                                    type="number"
                                                                    onChange={handleNamePaddingBottomChange}
                                                                />
                                                                <label className='p-0'>BOTTOM</label>
                                                            </li>
                                                            <li>
                                                                <Form.Control
                                                                    type="number"
                                                                    onChange={handleNamePaddingLeftChange}
                                                                />
                                                                <label className='p-0'>LEFT</label>
                                                            </li>
                                                            <li>
                                                                <input type="checkbox" />
                                                                <label className='p-0'>
                                                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <g clip-path="url(#clip0_99_24)">
                                                                            <path d="M6.67884 11.0227C6.65941 11.1386 6.60265 11.2449 6.51721 11.3255C5.93321 11.9084 5.37615 12.5231 4.75498 13.064C3.83912 13.8603 2.78587 14.0041 1.67283 13.5149C0.649212 13.0645 -0.00266876 12.0253 0.00271869 10.8018C0.0125391 10.1277 0.278319 9.48248 0.746185 8.99702C1.88778 7.8301 3.02885 6.65995 4.21301 5.53667C5.30127 4.5039 7.02202 4.55185 8.09196 5.57923C8.35703 5.8346 8.39204 6.17185 8.17762 6.4159C7.94812 6.67612 7.61787 6.67989 7.31886 6.4256C6.52098 5.74624 5.57926 5.77641 4.84064 6.51234C3.7847 7.56289 2.73182 8.61649 1.68198 9.67315C1.16856 10.192 1.0134 10.8185 1.26392 11.5049C1.51066 12.1788 2.02301 12.5587 2.73846 12.6282C3.2772 12.682 3.74106 12.4854 4.12411 12.104C4.65154 11.5781 5.1752 11.048 5.70586 10.526C5.9149 10.3207 6.16811 10.2868 6.39546 10.4096C6.50089 10.4693 6.58494 10.5606 6.63577 10.6706C6.68661 10.7806 6.70167 10.9037 6.67884 11.0227Z" fill="#F7F9FA" />
                                                                            <path d="M13.7767 3.01805C13.7444 3.69417 13.5128 4.30672 13.037 4.79052C11.785 6.06339 10.5222 7.32567 9.24859 8.57735C8.73732 9.07838 8.04504 9.31597 7.20891 9.31597C6.58342 9.30142 5.92077 9.05144 5.36802 8.52832C5.09864 8.27458 5.06901 7.93571 5.28559 7.69166C5.50917 7.43952 5.85558 7.41259 6.12172 7.67172C6.79677 8.32899 7.9082 8.35 8.6689 7.56397C9.79111 6.40298 10.9537 5.28077 12.0889 4.13109C13.0527 3.15489 12.6739 1.59415 11.3885 1.22188C10.7264 1.0317 10.1494 1.20679 9.6645 1.68466C9.05087 2.28913 8.44425 2.90114 7.83654 3.51154C7.72071 3.62791 7.60111 3.73889 7.43302 3.76152C7.31702 3.78246 7.19736 3.76592 7.09139 3.71429C6.98542 3.66266 6.89864 3.57862 6.84364 3.47437C6.71595 3.25887 6.75151 2.98411 6.946 2.78262C7.26332 2.45344 7.59249 2.13289 7.91574 1.80965C8.25838 1.46754 8.59402 1.11736 8.94797 0.786031C9.36404 0.392185 9.88858 0.132066 10.4539 0.0392438C11.0193 -0.0535786 11.5994 0.0251555 12.1196 0.265285C12.6398 0.505415 13.076 0.895919 13.3721 1.3864C13.6681 1.87689 13.8104 2.44484 13.7805 3.01697L13.7767 3.01805Z" fill="#F7F9FA" />
                                                                            <path d="M13.7767 3.01805C13.7444 3.69417 13.5128 4.30672 13.037 4.79052C11.785 6.06339 10.5222 7.32567 9.24859 8.57735C8.73732 9.07838 8.04504 9.31597 7.20891 9.31597C6.58342 9.30142 5.92077 9.05144 5.36802 8.52832C5.09864 8.27458 5.06901 7.93571 5.28559 7.69166C5.50917 7.43952 5.85558 7.41259 6.12172 7.67172C6.79677 8.32899 7.9082 8.35 8.6689 7.56397C9.79111 6.40298 10.9537 5.28077 12.0889 4.13109C13.0527 3.15489 12.6739 1.59415 11.3885 1.22188C10.7264 1.0317 10.1494 1.20679 9.6645 1.68466C9.05087 2.28913 8.44425 2.90114 7.83654 3.51154C7.72071 3.62791 7.60111 3.73889 7.43302 3.76152C7.31702 3.78246 7.19736 3.76592 7.09139 3.71429C6.98542 3.66266 6.89864 3.57862 6.84364 3.47437C6.71595 3.25887 6.75151 2.98411 6.946 2.78262C7.26332 2.45344 7.59249 2.13289 7.91574 1.80965C8.25838 1.46754 8.59402 1.11736 8.94797 0.786031C9.36404 0.392185 9.88858 0.132066 10.4539 0.0392438C11.0193 -0.0535786 11.5994 0.0251555 12.1196 0.265285C12.6398 0.505415 13.076 0.895919 13.3721 1.3864C13.6681 1.87689 13.8104 2.44484 13.7805 3.01697L13.7767 3.01805Z" fill="black" />
                                                                            <path d="M8.17383 6.41591C7.94432 6.67612 7.61407 6.67989 7.31507 6.4256C6.51719 5.74625 5.57546 5.77642 4.83684 6.51234C3.7809 7.56289 2.72802 8.6165 1.67818 9.67315C1.16476 10.192 1.0096 10.8185 1.26012 11.5049C1.50686 12.1789 2.01921 12.5587 2.73466 12.6282C3.27341 12.682 3.73727 12.4854 4.12031 12.104C4.64774 11.5782 5.1714 11.048 5.70207 10.526C5.9111 10.3207 6.16431 10.2868 6.39166 10.4096C6.49828 10.4686 6.58358 10.5597 6.63543 10.67C6.68727 10.7802 6.70302 10.9041 6.68043 11.0238C6.661 11.1396 6.60425 11.246 6.51881 11.3266C5.93481 11.9095 5.37774 12.5242 4.75657 13.0651C3.84071 13.8614 2.78746 14.0052 1.67441 13.516C0.650799 13.0656 -0.00108184 12.0264 0.0043056 10.8029C0.014126 10.1288 0.279909 9.48357 0.747775 8.99811C1.88937 7.83119 3.03043 6.66104 4.21459 5.53775C5.30286 4.50498 7.02361 4.55293 8.09355 5.58031C8.35323 5.8346 8.38825 6.17293 8.17383 6.41591Z" fill="black" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_99_24">
                                                                                <rect width="13.78" height="13.7972" fill="white" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* name padding ends */}

                                        {/* review customization starts */}
                                        <div className='name_style style_wrap'>
                                            <div className='box'>
                                                <h5>Review</h5>
                                                <div className='edit_wrapper'>
                                                    <div className='box_inner d-flex align-items-center justify-content-between w-100'>
                                                        <label className='p-0'>Text Color</label>
                                                        <div className='d-flex align-items-center editer_inner'>
                                                            <div className='web_icon'>
                                                                <img src={globe} alt="globe" />
                                                            </div>
                                                            <div className='colorpicker'>
                                                                <div className='color' style={{ backgroundColor: reviewTextcolor }} onClick={handleReviewPickerClick} />
                                                                {showReviewPicker && (
                                                                    <SketchPicker color={reviewTextcolor} onChange={handleReviewColorChange} />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='box_inner d-flex align-items-center justify-content-between w-100 mt-3'>
                                                        <label className='p-0'>Typography</label>
                                                        <div className='d-flex align-items-center editer_inner'>
                                                            <div className='web_icon'>
                                                                <Link to=""><img src={globe} alt="globe" /></Link>
                                                            </div>
                                                            <div className='edit_icon'>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                        <img src={edittypo} alt="edittypo" />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <div className='typography_info'>

                                                                            <div className='typo_head d-flex align-items-center justify-content-between'>
                                                                                <label className='p-0'>Typography</label>
                                                                                <div className='d-flex align-items-center'>
                                                                                    <div className='refresh'><img src={refresh} alt="refresh" /></div>
                                                                                    <div className='add'><img src={add} alt="add" /></div>
                                                                                </div>
                                                                            </div>

                                                                            <div className='dropdpown_wrap'>
                                                                                <label className='p-0'>Family</label>
                                                                                <Form.Select aria-label="Default select example" value={reviewFontFamily} onChange={handleReviewFontFamilyChange}>
                                                                                    <option value="'Poppins'">Poppins</option>
                                                                                    <option value="'Arial'">Arial</option>
                                                                                    <option value="'Helvetica'">Helvetica</option>
                                                                                    <option value="'Verdana'">Verdana</option>
                                                                                </Form.Select>
                                                                            </div>

                                                                            <div className='range_wrap'>
                                                                                <div className='top'>
                                                                                    <label className='p-0'>Size<img src={computer} alt="computer" /></label>

                                                                                </div>
                                                                                <div className='bottom'>
                                                                                    <Form.Range
                                                                                        onChange={handleReviewFontChange}
                                                                                    />
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        placeholder={reviewFontSize}
                                                                                        min='17'
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className='dropdpown_wrap'>
                                                                                <label className='p-0'>Weight</label>
                                                                                <Form.Select
                                                                                    aria-label="Default select example"
                                                                                    value={reviewFontWeight}
                                                                                    onChange={handleReviewFontWeightChange}
                                                                                >
                                                                                    <option value="400">400</option>
                                                                                    <option value="500">500</option>
                                                                                    <option value="600">600</option>
                                                                                    <option value="700">700(Bold)</option>
                                                                                </Form.Select>
                                                                            </div>

                                                                            <div className='dropdpown_wrap'>
                                                                                <label className='p-0'>Transform</label>
                                                                                <Form.Select aria-label="Default select example">
                                                                                    <option>Default</option>
                                                                                </Form.Select>
                                                                            </div>

                                                                            <div className='dropdpown_wrap'>
                                                                                <label className='p-0'>Style</label>
                                                                                <Form.Select aria-label="Default select example">
                                                                                    <option>Default</option>
                                                                                </Form.Select>
                                                                            </div>

                                                                            <div className='dropdpown_wrap'>
                                                                                <label className='p-0'>Decoration</label>
                                                                                <Form.Select aria-label="Default select example">
                                                                                    <option>Default</option>
                                                                                </Form.Select>
                                                                            </div>

                                                                            <div className='range_wrap'>
                                                                                <div className='top'>
                                                                                    <label className='p-0'>Line-Height<img src={computer} alt="computer" /></label>

                                                                                </div>
                                                                                <div className='bottom'>
                                                                                    <Form.Range
                                                                                        onChange={handleReviewLineHeightChange}
                                                                                    />
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        placeholder={reviewLineHeight}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className='range_wrap'>
                                                                                <div className='top'>
                                                                                    <label className='p-0'>Letter Spacing<img src={computer} alt="computer" /></label>

                                                                                </div>
                                                                                <div className='bottom'>
                                                                                    <Form.Range
                                                                                        onChange={handleReviewLetterSpacingChange}
                                                                                    />
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        placeholder={reviewLetterSpacing}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className='range_wrap'>
                                                                                <div className='top'>
                                                                                    <label className='p-0'>Word Spacing<img src={computer} alt="computer" /></label>
                                                                                </div>
                                                                                <div className='bottom'>
                                                                                    <Form.Range
                                                                                        onChange={handleReviewWordSpacingChange}
                                                                                    />
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        placeholder={reviewWordSpacing}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* review customization ends */}

                                        {/* review padding starts */}
                                        <div className='padding_wrap style_wrap'>
                                            <div className='box'>
                                                <h5>Review padding</h5>
                                                <div className='range_wrap'>
                                                    <div className='top'>
                                                        <label className='p-0'>Padding<img src={computer} alt="computer" /></label>

                                                    </div>
                                                    <div className='bottom'>
                                                        <ul className='p-0'>
                                                            <li>
                                                                <Form.Control
                                                                    type="number"
                                                                    onChange={handleReviewPaddingTopChange}
                                                                />
                                                                <label className='p-0'>TOP</label>
                                                            </li>
                                                            <li>
                                                                <Form.Control
                                                                    type="number"
                                                                    onChange={handleReviewPaddingRightChange}
                                                                />
                                                                <label className='p-0'>RIGHT</label>
                                                            </li>
                                                            <li>
                                                                <Form.Control
                                                                    type="number"
                                                                    onChange={handleReviewPaddingBottomChange}
                                                                />
                                                                <label className='p-0'>BOTTOM</label>
                                                            </li>
                                                            <li>
                                                                <Form.Control
                                                                    type="number"
                                                                    onChange={handleReviewPaddingLeftChange}
                                                                />
                                                                <label className='p-0'>LEFT</label>
                                                            </li>
                                                            <li>
                                                                <input type="checkbox" />
                                                                <label className='p-0'>
                                                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <g clip-path="url(#clip0_99_24)">
                                                                            <path d="M6.67884 11.0227C6.65941 11.1386 6.60265 11.2449 6.51721 11.3255C5.93321 11.9084 5.37615 12.5231 4.75498 13.064C3.83912 13.8603 2.78587 14.0041 1.67283 13.5149C0.649212 13.0645 -0.00266876 12.0253 0.00271869 10.8018C0.0125391 10.1277 0.278319 9.48248 0.746185 8.99702C1.88778 7.8301 3.02885 6.65995 4.21301 5.53667C5.30127 4.5039 7.02202 4.55185 8.09196 5.57923C8.35703 5.8346 8.39204 6.17185 8.17762 6.4159C7.94812 6.67612 7.61787 6.67989 7.31886 6.4256C6.52098 5.74624 5.57926 5.77641 4.84064 6.51234C3.7847 7.56289 2.73182 8.61649 1.68198 9.67315C1.16856 10.192 1.0134 10.8185 1.26392 11.5049C1.51066 12.1788 2.02301 12.5587 2.73846 12.6282C3.2772 12.682 3.74106 12.4854 4.12411 12.104C4.65154 11.5781 5.1752 11.048 5.70586 10.526C5.9149 10.3207 6.16811 10.2868 6.39546 10.4096C6.50089 10.4693 6.58494 10.5606 6.63577 10.6706C6.68661 10.7806 6.70167 10.9037 6.67884 11.0227Z" fill="#F7F9FA" />
                                                                            <path d="M13.7767 3.01805C13.7444 3.69417 13.5128 4.30672 13.037 4.79052C11.785 6.06339 10.5222 7.32567 9.24859 8.57735C8.73732 9.07838 8.04504 9.31597 7.20891 9.31597C6.58342 9.30142 5.92077 9.05144 5.36802 8.52832C5.09864 8.27458 5.06901 7.93571 5.28559 7.69166C5.50917 7.43952 5.85558 7.41259 6.12172 7.67172C6.79677 8.32899 7.9082 8.35 8.6689 7.56397C9.79111 6.40298 10.9537 5.28077 12.0889 4.13109C13.0527 3.15489 12.6739 1.59415 11.3885 1.22188C10.7264 1.0317 10.1494 1.20679 9.6645 1.68466C9.05087 2.28913 8.44425 2.90114 7.83654 3.51154C7.72071 3.62791 7.60111 3.73889 7.43302 3.76152C7.31702 3.78246 7.19736 3.76592 7.09139 3.71429C6.98542 3.66266 6.89864 3.57862 6.84364 3.47437C6.71595 3.25887 6.75151 2.98411 6.946 2.78262C7.26332 2.45344 7.59249 2.13289 7.91574 1.80965C8.25838 1.46754 8.59402 1.11736 8.94797 0.786031C9.36404 0.392185 9.88858 0.132066 10.4539 0.0392438C11.0193 -0.0535786 11.5994 0.0251555 12.1196 0.265285C12.6398 0.505415 13.076 0.895919 13.3721 1.3864C13.6681 1.87689 13.8104 2.44484 13.7805 3.01697L13.7767 3.01805Z" fill="#F7F9FA" />
                                                                            <path d="M13.7767 3.01805C13.7444 3.69417 13.5128 4.30672 13.037 4.79052C11.785 6.06339 10.5222 7.32567 9.24859 8.57735C8.73732 9.07838 8.04504 9.31597 7.20891 9.31597C6.58342 9.30142 5.92077 9.05144 5.36802 8.52832C5.09864 8.27458 5.06901 7.93571 5.28559 7.69166C5.50917 7.43952 5.85558 7.41259 6.12172 7.67172C6.79677 8.32899 7.9082 8.35 8.6689 7.56397C9.79111 6.40298 10.9537 5.28077 12.0889 4.13109C13.0527 3.15489 12.6739 1.59415 11.3885 1.22188C10.7264 1.0317 10.1494 1.20679 9.6645 1.68466C9.05087 2.28913 8.44425 2.90114 7.83654 3.51154C7.72071 3.62791 7.60111 3.73889 7.43302 3.76152C7.31702 3.78246 7.19736 3.76592 7.09139 3.71429C6.98542 3.66266 6.89864 3.57862 6.84364 3.47437C6.71595 3.25887 6.75151 2.98411 6.946 2.78262C7.26332 2.45344 7.59249 2.13289 7.91574 1.80965C8.25838 1.46754 8.59402 1.11736 8.94797 0.786031C9.36404 0.392185 9.88858 0.132066 10.4539 0.0392438C11.0193 -0.0535786 11.5994 0.0251555 12.1196 0.265285C12.6398 0.505415 13.076 0.895919 13.3721 1.3864C13.6681 1.87689 13.8104 2.44484 13.7805 3.01697L13.7767 3.01805Z" fill="black" />
                                                                            <path d="M8.17383 6.41591C7.94432 6.67612 7.61407 6.67989 7.31507 6.4256C6.51719 5.74625 5.57546 5.77642 4.83684 6.51234C3.7809 7.56289 2.72802 8.6165 1.67818 9.67315C1.16476 10.192 1.0096 10.8185 1.26012 11.5049C1.50686 12.1789 2.01921 12.5587 2.73466 12.6282C3.27341 12.682 3.73727 12.4854 4.12031 12.104C4.64774 11.5782 5.1714 11.048 5.70207 10.526C5.9111 10.3207 6.16431 10.2868 6.39166 10.4096C6.49828 10.4686 6.58358 10.5597 6.63543 10.67C6.68727 10.7802 6.70302 10.9041 6.68043 11.0238C6.661 11.1396 6.60425 11.246 6.51881 11.3266C5.93481 11.9095 5.37774 12.5242 4.75657 13.0651C3.84071 13.8614 2.78746 14.0052 1.67441 13.516C0.650799 13.0656 -0.00108184 12.0264 0.0043056 10.8029C0.014126 10.1288 0.279909 9.48357 0.747775 8.99811C1.88937 7.83119 3.03043 6.66104 4.21459 5.53775C5.30286 4.50498 7.02361 4.55293 8.09355 5.58031C8.35323 5.8346 8.38825 6.17293 8.17383 6.41591Z" fill="black" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_99_24">
                                                                                <rect width="13.78" height="13.7972" fill="white" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* review padding ends */}

                                        {/* profile image starts */}
                                        <div className='profile_image'>
                                            <Form.Group className="mb-3" controlId="formBasicUploadbackground">
                                                <div className='d-flex align-items-center editer_inner'>
                                                    <Form.Label>Profile Image</Form.Label>
                                                    <div className='web_icon' onClick={()=>setProfileImg(null)} style={{right:'20px',position:'absolute'}}>
                                                        <Link to=""><img src={trash} alt="globe" /></Link>
                                                    </div>
                                                </div>            
                                                <div className='custom_upload'>
                                                    <img src={upload} alt="upload" />
                                                    <label>Upload</label>
                                                    <Form.Control
                                                        type="file"
                                                        key={fileKey}
                                                        onChange={handleProfileChange}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                        {/* profile image ends */}

                                    </div>
                                </Tab>
                                <Tab eventKey="settings" title="Settings">
                                    <div className='edit_testimonial_list'>
                                        <div className='swtich_wrapper'>
                                            <Form className='custom_swtich'>
                                                <Form.Group className="mt-0" controlId="formBasicSwitch">
                                                    <Form.Check type="switch" id="custom-switch" label="Show avatars" defaultChecked={avtar} onClick={() => setAvtar(!avtar)} />
                                                </Form.Group>
                                            </Form>
                                            <Form className='custom_swtich'>
                                                <Form.Group className="mt-3" controlId="formBasicSwitch">
                                                    <Form.Check type="switch" id="custom-switch" label="Show Rating" defaultChecked={rating} onClick={() => setRating(!rating)} />
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                        <div className='testimonial_view'>
                            {
                                localtestimonial != null
                                ?
                                <Template
                                    title={selecttheme}
                                    seltestimonial={selectTestimonial}
                                    rating={rating}
                                    avtar={avtar}
                                    BgImage={BgImage}
                                    color={Bgcolor}
                                    sizevalue={sizevalue}
                                    paddingvalue={paddingvalue}
                                    logoposition={logoposition}
                                    logoimg={logoimg}

                                    nameTextcolor={nameTextcolor}
                                    nameFontFamily={nameFontFamily}
                                    nameFontSize={nameFontSize}
                                    nameFontWeight={nameFontWeight}
                                    nameLineHeight={nameLineHeight}
                                    nameLetterSpacing={nameLetterSpacing}
                                    nameWordSpacing={nameWordSpacing}
                                    namePaddingTop={namePaddingTop}
                                    namePaddingRight={namePaddingRight}
                                    namePaddingBottom={namePaddingBottom}
                                    namePaddingLeft={namePaddingLeft}

                                    reviewTextcolor={reviewTextcolor}
                                    reviewFontFamily={reviewFontFamily}
                                    reviewFontSize={reviewFontSize}
                                    reviewFontWeight={reviewFontWeight}
                                    reviewLineHeight={reviewLineHeight}
                                    reviewLetterSpacing={reviewLetterSpacing}
                                    reviewWordSpacing={reviewWordSpacing}
                                    reviewPaddingTop={reviewPaddingTop}
                                    reviewPaddingRight={reviewPaddingRight}
                                    reviewPaddingBottom={reviewPaddingBottom}
                                    reviewPaddingLeft={reviewPaddingLeft}

                                    profileImg={profileImg}
                                />
                                :
                                <div className='create_testimonial'>
                                    <Link to="" onClick={() => setModalShow(true)}>
                                        <div className='vector'><img src={testimonialvector} alt="testimonialvector" /></div>
                                        <div className='label'>Pick up the testimonial to get started</div>
                                    </Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
}
export default SocialProof;

