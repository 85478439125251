import { Col, Row, Container, Form, Button, FormGroup, InputGroup } from 'react-bootstrap';
import ProofPuddingLogo from "../Images/ProofPuddingLogo.png";
import forgot from "../Images/forgot.svg";
import forgotgraphic from "../Images/forgotgraphic.svg"
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';    
import 'react-toastify/dist/ReactToastify.css'; 
import Sidebarlogo from "../Images/sidebarlogo.png";

async function sendResetPasswordLink(credentials) {
    return fetch(process.env.REACT_APP_API_URL+'send-reset-password-link', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
    .then(data => data.json())
    .then((response) => {
                
        if(response.status){
            toast.success(response.message);
        }else{
            toast.error(response.message);
        }
    })
}

function Forgotpassword() {

    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState(null);

    const handleSubmit = async(event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }else{
            setValidated(true);
            const response = await sendResetPasswordLink({
                email
            });
        }
    };
    
    return (
        <>
            <ToastContainer />
            <div className='form_header'>
                <Container fluid>
                <ToastContainer />
                </Container>
            </div>
            <div className='content_wrapper outer_form forgotpassword'>
                <Container fluid className='h-100'>
                    <Row className='h-100'>
                        <Col lg={6} md={12} className='p-0'>
                            <div className='form_wrapper d-flex align-items-center justify-content-center h-100'>
                                <div className='inner_wrapper'>
                                    <div className='logo'>
                                        <img src={Sidebarlogo} alt="Sidebarlogo" />
                                    </div>                                    
                                    <h2 className='text-center'>Forgot password</h2>
                                    <p className='text-center'>No worries! Enter your email and we will send <br /> you a reset.</p>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Form.Group className="mb-1" controlId="formBasicEmail">
                                            <Form.Label>Email</Form.Label>

                                            <InputGroup hasValidation>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Enter your Email"
                                                    aria-describedby="inputGroupPrepend"
                                                    required
                                                    onChange={e => setEmail(e.target.value)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Email is required.
                                                </Form.Control.Feedback>
                                            </InputGroup>

                                        </Form.Group>
                                        <Button variant="primary" onClick={handleSubmit} type="submit" className='m-auto mb-2 mt-4'>
                                            Send Email
                                        </Button>
                                        <FormGroup>
                                            <div className='link text-center'>
                                                <Link className='ms-1' to="/">Back to Sign in</Link>
                                            </div>
                                        </FormGroup>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} className='p-0'>
                            <div className='grahic_wrapper d-flex align-items-center justify-content-center h-100'>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
export default Forgotpassword;