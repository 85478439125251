import { Col, Row, Container, Form, Button, FormGroup, InputGroup } from 'react-bootstrap';
import ProofPuddingLogo from "../Images/ProofPuddingLogo.png";
import smile from "../Images/smile.png";
import signingraphic from "../Images/signingraphic.svg"
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import showPwdImg from '../Images/show-password.svg';
import hidePwdImg from '../Images/hide-password.svg';
import validator from 'validator'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Sidebarlogo from "../Images/sidebarlogo.png";

async function registerUser(credentials) {
    return fetch(process.env.REACT_APP_API_URL + 'register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

function Form_wrap() {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [websiteName, setWebsiteName] = useState(null);
    const [websiteUrl, setWebsiteUrl] = useState(null);
    const [tellUsMore, setTellUsMore] = useState(null);
    const [testimonials, setTestimonials] = useState('1');
    const [heardAbout, setHeardAbout] = useState('Product Hunt');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [validated, setValidated] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            if (!validator.isURL(websiteUrl)) {
                toast.error('Please enter a valid website url');
                event.preventDefault();
                event.stopPropagation();
            } else {
                const response = await registerUser({
                    firstName,
                    lastName,
                    email,
                    password,
                    websiteName,
                    websiteUrl,
                    tellUsMore,
                    testimonials,
                    heardAbout
                });
                if (response['status']) {
                    toast.success(response.message);
                    // setTimeout(() => {
                    //     localStorage.setItem('accessToken', response['data']['access_token']);
                    //     localStorage.setItem('user', JSON.stringify(response['data']['user']));
                    //     navigate('/dashboard');
                    // }, 1000);
                } else {
                    toast.error(response.message);
                }
            }
        }
    };

    if(user && user.userplan && user.userplan.plan && user.userplan.plan.no_of_business_profile){
        window.location.href = '/dashboard';
    }else{
        return (
            <>
                <div className='form_header'>
                    <ToastContainer />
                </div>
                <div className='content_wrapper outer_form register'>
                    <Container fluid className='h-100'>
                        <Row className='h-100'>
                            <Col lg={6} md={12} className='p-0'>
                                <div className='form_wrapper d-flex align-items-center justify-content-center h-100'>
                                    <div className='inner_wrapper'>
                                        <div className='logo'>
                                            <img src={Sidebarlogo} alt="Sidebarlogo" />
                                        </div>                                  
                                        <h2 className='text-center'>Sign Up</h2>
                                        <p className='text-center'>Create your free account</p>
                                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                            <Row>
                                                <Col md="6" className='p-lg-1'>
                                                    <Form.Group className="mb-1" controlId="first_name">
                                                        <Form.Label>First Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name='first_name'
                                                            placeholder="Enter your First Name"
                                                            onChange={e => setFirstName(e.target.value)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6" className='p-lg-1'>
                                                    <Form.Group className="mb-1" controlId="last_name">
                                                        <Form.Label>Last Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name='last_name'
                                                            placeholder="Enter your Last Name"
                                                            onChange={e => setLastName(e.target.value)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6" className='p-lg-1'>
                                                    <Form.Group className="mb-1" controlId="email">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            name='email'
                                                            placeholder="Enter your email"
                                                            onChange={e => setEmail(e.target.value)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6" className='p-lg-1'>
                                                    <Form.Group className="mb-1 position-relative" controlId="formBasicPassword">
                                                        <Form.Label>Password</Form.Label>
                                                        <InputGroup hasValidation>
                                                            <Form.Control
                                                                type={isRevealPwd ? "text" : "password"}
                                                                placeholder="Enter your Password"
                                                                aria-describedby="inputGroupPrepend"
                                                                required
                                                                value={password}
                                                                onChange={e => setPassword(e.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Password is required
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
    
                                                        <img
                                                            title={isRevealPwd ? "Hide password" : "Show password"}
                                                            src={isRevealPwd ? hidePwdImg : showPwdImg}
                                                            onClick={() => setIsRevealPwd(prevState => !prevState)}
                                                            alt="hide-show-pass"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6" className='p-lg-1'>
                                                    <Form.Group className="mb-1" controlId="website_name">
                                                        <Form.Label>Website Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name='website_name'
                                                            placeholder="Enter your Website Name"
                                                            onChange={e => setWebsiteName(e.target.value)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6" className='p-lg-1'>
                                                    <Form.Group className="mb-1" controlId="website_url">
                                                        <Form.Label>Website URL</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name='website_url'
                                                            placeholder="Enter your website url"
                                                            onChange={e => setWebsiteUrl(e.target.value)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6" className='p-lg-1'>
                                                    <Form.Group className="mb-1" controlId="testimonials">
                                                        <Form.Label>Do you already have testimonials?</Form.Label>
                                                        <Form.Select name='testimonials' onChange={e => setTestimonials(e.target.value)}>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6" className='p-lg-1'>
                                                    <Form.Group className="mb-1" controlId="formBasicTestimonials">
                                                        <Form.Label>How did you hear about us?</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name='how_did_heard_about_us'
                                                            placeholder="Ex: Product Hunt etc."
                                                            onChange={e => setHeardAbout(e.target.value)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                
                                            </Row>
                                            <Button variant="primary" type="submit" className='m-auto mb-2 mt-2'>
                                                Sign Up
                                            </Button>
                                            <FormGroup>
                                                <div className='link text-center'>
                                                    Already have an account?
                                                    <Link className='ms-1' to="/">Sign in</Link>
                                                </div>
                                            </FormGroup>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={12} className='p-0 d-none d-md-block'>
                                <div className='grahic_wrapper d-flex align-items-center justify-content-center h-100'>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}
export default Form_wrap;