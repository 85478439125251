import { Col, Row, Container, Form, Button, FormGroup, InputGroup } from 'react-bootstrap';
import ProofPuddingLogo from "../Images/ProofPuddingLogo.png";
import resetimage from "../Images/reset.png";
import resetgraphic from "../Images/resetgraphic.svg"
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import showPwdImg from '../Images/show-password.svg';
import hidePwdImg from '../Images/hide-password.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useNavigate } from "react-router-dom";

 
 async function RegisterEmailVerified(credentials) {
    // const navigate = useNavigate();
    return fetch(process.env.REACT_APP_API_URL + 'register-email-verified', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
        .then((response) => {

            if (response.status) {
                toast.success(response.message);
                setTimeout(() => {
                    // navigate('/')
                    window.location = '/';
                }, 500);
            } else {
                toast.error(response.message);
            }
        })
}


function RegisterEmailVerify() {

    const search = window.location.search;
        const params = new URLSearchParams(search);
        const token = params.get('token');
        console.log(token);
       

        const response = RegisterEmailVerified({
            token, 
        });
    

    return (
        <>
            <div className='form_header'>
                <ToastContainer />
                <Container fluid>
                    <Row>
                        <Col>
                            <div className='logo reset_logo'>
                                <img src={ProofPuddingLogo} alt="ProofPuddingLogo" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='content_wrapper'>
                <Container fluid className='h-100'>
                    <Row className='h-100'>
                        <Col lg={6} md={12} className='p-0'>
                            <div className='form_wrapper d-flex align-items-center justify-content-center h-100'>
                                <div className='inner_wrapper'>
                                    <h2 className='text-left'>Thanks you for verify your Email </h2>
                                     <Form >
                                         
                                        
                                        
                                        <FormGroup>
                                            <div className='link text-center'>
                                                <Link className='ms-1' to="/">Back to Sign in</Link>
                                            </div>
                                        </FormGroup>
                                    </Form>
                                   </div>
                                    

                            </div>

                        </Col>
                     
                                            
                                        
                        <Col lg={6} md={12} className='p-0'>
                            <div className='grahic_wrapper d-flex align-items-center justify-content-center h-100'>
                           
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
export default RegisterEmailVerify;