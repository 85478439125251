import { Col, Row, Container } from 'react-bootstrap';
import Sidebar from './Sidebar';
import Header from './Header';
import React, { useEffect } from "react";
import SocialProofHeader from './SocialProofHeader';
import ImagesPartial from './ImagesPartial';
import WidgetPartial from './WidgetPartial';
import WallOfLovePartial from './WallOfLovePartial';

function SocialProof() {

  function selectImageTheme(slug) {
    localStorage.setItem('image_template', slug);
  }

  useEffect(() => {
    localStorage.removeItem("testimonial_ids");
    localStorage.removeItem("image_template");
    localStorage.removeItem('Widget_Template');
    localStorage.removeItem('Widget_Testimonials');
    localStorage.removeItem('Widget_Name');
  }, []);

  return (
    <>
      <Container fluid className="pe-0">
        <Sidebar />
        <div className='wrapper'>
          <Header title="Media Studio" />
          <div className='inner_content_wrapper social_proof_inner'>
            <Row>
              <SocialProofHeader active="create" />
              <Col md="12">
                <ImagesPartial themefunction={selectImageTheme} />
                <WidgetPartial />
                <WallOfLovePartial />
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
}


export default SocialProof;

