import { Col, Row, Container, Card, Form, Button } from 'react-bootstrap';
import Sidebar from './Sidebar';
import Header from './Header';
import React, { useState, useEffect } from 'react';
import Loader from './Loader';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

async function updateSubdomain(params, accessToken) {
    return fetch(process.env.REACT_APP_API_URL + 'update-sub-domain', {
        method: 'POST',
        mode: 'cors',
        headers: {
            authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    })
        .then(data => data.json())
}

export default function ProjectSettings(props) {
    const accessToken = localStorage.getItem('accessToken');
    const user = JSON.parse(localStorage.getItem('user'));
    const [isLoading, setIsLoading] = useState(true);
    const [validated, setValidated] = useState(false);
    const [disableCustomDomain,setDisableCustomDomain] = useState(true);
    const [subdomain, setSubdomain] = useState('');
    const [error, setError] = useState('');
    const [projectId, setProjectId] = useState();
    const navigate = useNavigate();

    const handleSubmit = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            if(error!==''){
                toast.error(error);
            }else{
                const response = await updateSubdomain({
                    'project_id' : projectId,
                    'sub_domain' : subdomain
                },accessToken);

                if(response.status){
                    toast.success(response.message);
                    navigate('/project-settings');
                }else{
                    toast.error(response.message);
                }
            }
        }
    };

    const handleChange = (event) => {
        const value = event.target.value;
        setSubdomain(value);
        // const regex = /^(?![-.])[a-zA-Z0-9.-]+(?<![-.])$/;
        const regex = /(?:\/)([^#]+)(?=#*)/g;  
        if (!regex.test(value)) {
          setError('Please enter a valid subdomain');
        } else {
          setError('');
        }
    };

    const getActiveProject = () => {
        fetch(process.env.REACT_APP_API_URL + 'get-active-project', {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            },
        }).then(response => response.json())
            .then(data => {
                console.log(data);
                setProjectId(data.id);
                setSubdomain(data.subdomain);
            });
    }

    useEffect(() => {
        if(user.userplan.plan.type=='paid'){
            setDisableCustomDomain(false);
        }else{
            setDisableCustomDomain(true);
        }
        getActiveProject();
        setIsLoading(false);
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <Container fluid className="pe-0">
                <Sidebar />
                <div className='wrapper'>
                    <Header title="Project Settings" />
                    <div className='filter'>
                        <Row>
                            <Col sm="3" md="3" lg="4">
                                <div className='layout_switch'>
                                    <Link to="/project-settings">General</Link>
                                    <Link to="/project-settings/domain" className='active'>Domain</Link>
                                    <Link to="/project-settings/danger">Danger Zone</Link>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Row>
                        <Col>
                            <div className='project-settings-wrapper mt-4'>
                                <div className='form_wrapper pt-0'>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Row>
                                            <Col className='pe-2'>
                                                <div className="title">Custom domain</div>
                                                {
                                                    disableCustomDomain
                                                    ?
                                                    <p style={{color:'#ff5151'}}>Please upgrade your plan to customize your domain.</p>
                                                    :
                                                    <p className="mb-3">Personalize the url of your Proof Pudding page by connecting a custom domain. You can use any subdomain that you own.</p>
                                                }
                                            </Col>
                                        </Row>
                                        {
                                            !disableCustomDomain && 
                                            <>
                                                <Row>    
                                                    <Col>
                                                        <Form.Group className="mb-3" controlId="formBasicName">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder='Ex: mike-viper'
                                                                value={subdomain}
                                                                onChange={handleChange}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Button type="submit" className="save_btn">Save</Button>
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
}