import { Container, Col, Button} from 'react-bootstrap';
import star from "../Images/star.png";
import React,{useState,useEffect} from "react";

import { Link } from 'react-router-dom';
function Dashboard() {
    const [user, setUser] = useState();
    const [shortName, setShortName] = useState('');
    const urlParams = new URLSearchParams(window.location.search);
    const business_name = urlParams.get('business_name');  
    const [displayProofPuddingBrand, setDisplayProofPuddingBrand] = useState('none');

    function getUserNameInitials(name_) {
        const slices = name_.split(' ');
        let name_initials = '';
        slices.forEach(item => {
            name_initials = name_initials + '' + item.charAt(0).toUpperCase();
        });
        setShortName(name_initials);
    }

    async function getBusiness(params) {
        return fetch(process.env.REACT_APP_API_URL + 'get-business', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(data => data.json())
            .then(response => {
                setUser(response.data);
                if (response.data) {
                    getUserNameInitials(response.data.name);
                    if(response.data.userplan.plan.type=='paid'){
                        setDisplayProofPuddingBrand('none');
                    }else{
                        setDisplayProofPuddingBrand('block');
                    }
                }
            });
    }
    useEffect(() => {
        getBusiness({ business_name });
    }, []);

    const google_link='https://www.google.com/search?q='+business_name;

  return (
    <>  
        <Container fluid className="p-0">
            <div className='add_review'>
                <Col>
                    <div className='add_reviews_inner position-relative m-auto'>
                        <div className='head_wrap d-flex justify-content-between'>
                            {
                                user && user.image
                                ?
                                <img className='logo_review' src={user.image} />
                                :
                                <div className='logo_review'>{shortName ? shortName : 'PP'}</div>
                            }
                            <h4 className='mt-0'>{business_name?business_name:'Business Name'}</h4>
                        </div>
                        <div className='common_content_wrapper mt-4 pt-2'>
                            <h3 className='text-center mb-0'>Thank You For Your Review!</h3>
                            <p className='text-center'>Help us by sharing your review on Google!</p>
                             
                            <div className='common_btn_wrapper mb-5 pb-4'>
                                <a style={{ textDecoration: 'none' }} href={google_link} target="_blank">
                                <Button clas>Give 5 stars on Google</Button>
                                </a>
                            </div>
                            
                        </div>
                        <div className='powered_by' style={{display:displayProofPuddingBrand}}>
                            <h6 className='mb-0'>Powered by Proof Pudding<img src={ star } alt="star" className='ms-1'/></h6>
                        </div>
                    </div>
                </Col>
            </div>
        </Container>
    </>
  );
}
export default Dashboard;