import React from "react";
import VideoPlayer from "./VideoPlayer";
import temtwostar from "../Temimg/temtwostar.png";
import avtartwo from "../Temimg/avtartwo.png";
import VimeoPlayer from "./VimeoPlayer";

function Template(props) {
  let padding = props.paddingvalue;
  let size = props.sizevalue;
  let total_padding = parseInt(padding) + parseInt(size);

  return (
    <>
      <div className="template_wrapper" id="image_dowload" >
        {props.data.map(function (data, key) {
          return (
            <div className="temp_two common_temp mb-4" key={key} style={{ backgroundImage: `url(${props.BgImage})`, backgroundRepeat: "no-repeat, repeat", backgroundSize: "cover", backgroundColor: props.color }}>
              <div className="temp_two_inner position-relative" style={{ backgroundImage: `url(${props.logoimg})`, backgroundRepeat: "no-repeat, repeat", padding: (total_padding) + "px", backgroundPosition: props.logoposition, position: "relative", bottom: "0", backgroundSize: props.sizevalue + "px" }}>
                <div className="wrap">
                  <div className={props.rating ? "stars text-center" : "d-none"}>
                    <img src={temtwostar} alt="temtwostar" className="w-100" />
                  </div>
                  <div className="content">
                    <p className={data.text?"":"d-none"} style={
                    {
                        color:props.reviewTextcolor,
                        fontFamily:props.reviewFontFamily,
                        fontSize:props.reviewFontSize,
                        fontWeight:props.reviewFontWeight,
                        lineHeight:props.reviewLineHeight,
                        letterSpacing:props.reviewLetterSpacing,
                        wordSpacing:props.reviewWordSpacing,
                        paddingTop:props.reviewPaddingTop,
                        paddingRight:props.reviewPaddingRight,
                        paddingBottom:props.reviewPaddingBottom,
                        paddingLeft:props.reviewPaddingLeft
                    }
                    }>{data.text}</p>

                    {data.files &&
                      <div className='upload_post_image mt-3'>
                        {
                          data.platform_name === 'proof_pudding'
                            ?
                            <VideoPlayer src={data.media[0].file_url} id={data.media[0].id} />
                            :
                            data.media[0].type === 'photo'
                              ?
                              <img src={data.media[0].file_url} alt="image" className='w-100' />
                              :
                              data.platform_name === 'vimeo'
                              ?
                              <VimeoPlayer key={data.review_id} videoId={data.review_id} />
                              :
                              <VideoPlayer src={data.media[0].file_url} id={data.media[0].id} />
                        }
                      </div>
                    }

                  </div>
                  <div className="author text-center mt-4">
                    <div>
                      {
                        props.profileImg
                        ?
                        <img src={props.profileImg} className={props.avtar ? "" : 'd-none'} alt="avatar" />
                        :
                        data.reviewer_picture
                          ?
                          <img src={data.reviewer_picture} className={props.avtar ? "" : 'd-none'} alt="avatar" />
                          :
                          <img src={avtartwo} alt="avatar" className={props.avtar ? "" : 'd-none'} />
                      }
                      <div>
                        <div className="author_name mt-2" style={
                        {
                            color:props.nameTextcolor,
                            fontFamily:props.nameFontFamily,
                            fontSize:props.nameFontSize,
                            fontWeight:props.nameFontWeight,
                            lineHeight:props.nameLineHeight,
                            letterSpacing:props.nameLetterSpacing,
                            wordSpacing:props.nameWordSpacing,
                            paddingTop:props.namePaddingTop,
                            paddingRight:props.namePaddingRight,
                            paddingBottom:props.namePaddingBottom,
                            paddingLeft:props.namePaddingLeft
                        }
                        }>{data.reviewer_name}</div>
                        <div className="designation">{data.reviewer_handle}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
export default Template;