import temthreestar from "../Temimg/temthreestar.svg";
import avtarthree from "../Temimg/avtarthree.png";
import temthreeshadow from "../Temimg/temthreeshadow.png";
import temthreeshape from "../Temimg/temthreeshape.svg";
import React from "react";
import VideoPlayer from "./VideoPlayer";
import VimeoPlayer from "./VimeoPlayer";

function Template(props) {

    let padding = props.paddingvalue;
    let size = props.sizevalue;
    let total_padding = parseInt(padding) + parseInt(size);

    let default_css = { 
        backgroundImage: `url(${props.logoimg})`,
        backgroundRepeat: "no-repeat, repeat", 
        padding: (total_padding) + "px", 
        backgroundPosition: props.logoposition, 
        position: "relative", 
        bottom: "0", 
        backgroundSize: props.sizevalue + "px" 
    }
    if(!total_padding){
        default_css = { 
            backgroundImage: `url(${props.logoimg})`,
            backgroundRepeat: "no-repeat, repeat", 
            backgroundPosition: props.logoposition, 
            position: "relative", 
            bottom: "0", 
            backgroundSize: props.sizevalue + "px" 
        };
    }


    return (
        <>
            <div className="template_wrapper" id="image_dowload">
                {props.data.map(function (data, key) {
                    return (
                        <div className="temp_three common_temp position-relative mb-4" key={key} style={{ backgroundImage: `url(${props.BgImage})`, backgroundRepeat: "no-repeat, repeat", backgroundSize: "cover", backgroundColor: props.color }}>
                            <div className="temp_three_inner" style={default_css}>
                                <div className="wrap">
                                    <div className="author text-center">
                                        <div>
                                            {
                                                props.profileImg
                                                ?
                                                <img className={props.avtar ? "mb-3 rounded-circle" : "d-none"} src={props.profileImg} alt="avatar" />
                                                :
                                                data.reviewer_picture
                                                    ?
                                                    <img className={props.avtar ? "mb-3 rounded-circle" : "d-none"} src={data.reviewer_picture} alt="avatar" />
                                                    :
                                                    <img className={props.avtar ? "mb-3 rounded-circle" : "d-none"} src={avtarthree} alt="avatar" />
                                            }
                                            <img className="d-block m-auto" src={temthreeshadow} alt="temthreeshadow" />
                                            <div>
                                                <div className="author_name mt-2" style={
                                                {
                                                    color:props.nameTextcolor,
                                                    fontFamily:props.nameFontFamily,
                                                    fontSize:props.nameFontSize,
                                                    fontWeight:props.nameFontWeight,
                                                    lineHeight:props.nameLineHeight,
                                                    letterSpacing:props.nameLetterSpacing,
                                                    wordSpacing:props.nameWordSpacing,
                                                    paddingTop:props.namePaddingTop,
                                                    paddingRight:props.namePaddingRight,
                                                    paddingBottom:props.namePaddingBottom,
                                                    paddingLeft:props.namePaddingLeft
                                                }
                                                }>{data.reviewer_name}</div>
                                                <div className="designation">{data.reviewer_handle ? '@' + data.reviewer_handle : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <p className={data.text?"mb-0":"d-none"} style={
                                        {
                                            color:props.reviewTextcolor,
                                            fontFamily:props.reviewFontFamily,
                                            fontSize:props.reviewFontSize,
                                            fontWeight:props.reviewFontWeight,
                                            lineHeight:props.reviewLineHeight,
                                            letterSpacing:props.reviewLetterSpacing,
                                            wordSpacing:props.reviewWordSpacing,
                                            paddingTop:props.reviewPaddingTop,
                                            paddingRight:props.reviewPaddingRight,
                                            paddingBottom:props.reviewPaddingBottom,
                                            paddingLeft:props.reviewPaddingLeft
                                        }
                                        }>
                                            {data.text}
                                        </p>
                                        {data.files &&
                                            <div className='upload_post_image mt-3'>
                                                {
                                                    data.platform_name === 'proof_pudding'
                                                        ?
                                                        <VideoPlayer src={data.media[0].file_url} id={data.media[0].id} />
                                                        :
                                                        data.media[0].type === 'photo'
                                                            ?
                                                            <img src={data.media[0].file_url} alt="image" className='w-100' />
                                                            :
                                                            data.platform_name === 'vimeo'
                                                            ?
                                                            <VimeoPlayer key={data.review_id} videoId={data.review_id} />
                                                            :
                                                            <VideoPlayer src={data.media[0].file_url} id={data.media[0].id} />
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className={props.rating ? "stars text-center" : "d-none"}>
                                        {
                                            data.platform_name == 'google'
                                                ?
                                                <ul className="p-0 d-flex align-items-center justify-content-center">
                                                    {data.rating >= 1 && <li><img src={temthreestar} alt="temthreestar" className="w-100" /></li>}
                                                    {data.rating >= 2 && <li><img src={temthreestar} alt="temthreestar" className="w-100" /></li>}
                                                    {data.rating >= 3 && <li><img src={temthreestar} alt="temthreestar" className="w-100" /></li>}
                                                    {data.rating >= 4 && <li><img src={temthreestar} alt="temthreestar" className="w-100" /></li>}
                                                    {data.rating >= 5 && <li><img src={temthreestar} alt="temthreestar" className="w-100" /></li>}
                                                </ul>
                                                :
                                                <ul className="p-0 d-flex align-items-center justify-content-center">
                                                    <li><img src={temthreestar} alt="temthreestar" className="w-100" /></li>
                                                    <li><img src={temthreestar} alt="temthreestar" className="w-100" /></li>
                                                    <li><img src={temthreestar} alt="temthreestar" className="w-100" /></li>
                                                    <li><img src={temthreestar} alt="temthreestar" className="w-100" /></li>
                                                    <li><img src={temthreestar} alt="temthreestar" className="w-100" /></li>
                                                </ul>
                                        }
                                    </div>
                                    <div className="shape_image"><img src={temthreeshape} alt="temthreeshape" className="w-100" /></div>
                                </div>
                                <div className="call_us position-relative text-center">
                                    {/* <Button>
                                        Call Us Today
                                    </Button> */}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
export default Template;