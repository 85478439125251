import { Link } from 'react-router-dom';
import sidebarlogo from "../Images/sidebarlogo.png";
import dashicon from "../Images/dashicon.png";
import proofwall from "../Images/proofwall.png";
import affiliate from "../Images/affiliate.png";
import morereviews from "../Images/morereviews.png";
import setting from "../Images/setting.png";
import integrations from "../Images/integrations.png";
import logout from "../Images/logout.png";
import dashiconactive from "../Images/dashiconactive.png";
import proofwallactive from "../Images/proofwallactive.png";
import affiliateactive from "../Images/affiliateactive.png";
import morereviewsactive from "../Images/morereviewsactive.png";
import settingactive from "../Images/settingactive.png";
import integrationsactive from "../Images/integrationsactive.png";
import close from "../Images/close.png";
import logoutactive from "../Images/logoutactive.png";
import { useLocation } from "react-router-dom";
import socialproof from "../Images/socialproof.png";
import unfold from "../Images/unfold.svg";
import globen from "../Images/globen.svg";
import socialproofactive from "../Images/socialproofactive.png";
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import AddProject from "./AddProject";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

async function createProject(params,token) {
    return fetch(process.env.REACT_APP_API_URL + 'create-project', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(params)
    })
        .then(data => data.json())
}

async function changeToActiveProject(params,token) {
    return fetch(process.env.REACT_APP_API_URL + 'change-to-active-project', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(params)
    })
        .then(data => data.json())
}

function Sidebar() {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    const accessToken = localStorage.getItem('accessToken');
    if (!user || !user.userplan || !user.userplan.plan || !user.userplan.plan.no_of_business_profile) {
        window.location.href = '/';
    }
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const [projects, setProjects] = useState([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [projectName, setProjectName] = useState();

    const handleLogout = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        navigate('/');
    };
    function removeClass() {
        if (document.body.classList.contains('show')) {
            document.body.classList.remove('show');
        }
    };
    const getUserData = () => {
        fetch(process.env.REACT_APP_API_URL + 'get-user-data', {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            },
        }).then(response => response.json())
            .then(data => {
                localStorage.setItem('user', JSON.stringify(data.data));
                localStorage.setItem('default_widget', JSON.stringify(data.default_widget));
                setProjects(data.projects);
            });
    };
    const saveProject = async () => {
        const response = await createProject({
            'project_name': projectName
        }, accessToken);

        if(response.status){
            setProjects(response.projects);
            setModalShow(false);
        }else{
            toast.error(response.message);
        }
    }
    const changeActiveProject = async(projectId) => {
        const response = await changeToActiveProject({
            'project_id' : projectId
        },accessToken);

        if(response.status){
            setProjects(response.projects);
            navigate('/project-settings');
        }
    }

    useEffect(() => {
        getUserData();
        document.addEventListener('projectUpdated', handleChildData);
        return () => {
            document.removeEventListener('projectUpdated', handleChildData);
        };
    }, []);

    const handleChildData = (event) => {
        setProjects(event.detail.data);
    };

    return (
        <>
            <ToastContainer />
            <div className='sidebar'>
                <div className="sidebar_inner_wrap">
                    <div className='closebtn' onClick={removeClass}>
                        <img src={close} alt="close" />
                    </div>
                    <div className='logo ps-3 mb-3'>
                        <img src={sidebarlogo} alt="sidebarlogo" />
                    </div>
                    <ul className='p-0'>
                        <li className='ps-3'>
                            <Link to="/dashboard" className={splitLocation[1] === "dashboard" ? "active" : ""}>
                                <img src={dashicon} alt="dashicon" />
                                <img src={dashiconactive} alt="dashiconactive" className='activeviewimg' />
                                Dashboard
                            </Link>
                        </li>
                        <li className='ps-3'>
                            <Link to="/proof-wall/combined" className={splitLocation[1] === "proof-wall" ? "active" : ""}>
                                <img src={proofwall} alt="proofwall" />
                                <img src={proofwallactive} alt="proofwallactive" className='activeviewimg' />
                                Proof Wall
                            </Link>
                        </li>
                        <li className='ps-3'>
                            <Link to="/get-more-reviews" className={splitLocation[1] === "get-more-reviews" ? "active" : ""}>
                                <img src={morereviews} alt="morereviews" />
                                <img src={morereviewsactive} alt="morereviewsactive" className='activeviewimg' />
                                Get More Reviews
                            </Link>
                        </li>
                        <li className='ps-3'>
                            <Link to="/integrations" className={splitLocation[1] === "integrations" ? "active" : ""}>
                                <img src={integrations} alt="integrations" />
                                <img src={integrationsactive} alt="integrationsactive" className='activeviewimg' />
                                Integrations
                            </Link>
                        </li>
                        <li className='ps-3'>
                            <Link to="/social-proof" className={splitLocation[1] === "social-proof" ? "active" : ""}>
                                <img src={socialproof} alt="socialproof" />
                                <img src={socialproofactive} alt="socialproofactive" className='activeviewimg' />
                                Media Studio
                            </Link>
                        </li>
                        <li className='ps-3'>
                            <Link to="https://rvtechnologies.firstpromoter.com/" target="_blank">
                                <img src={affiliate} alt="affiliate" />
                                <img src={affiliateactive} alt="affiliateactive" className='activeviewimg' />
                                Affiliate
                            </Link>
                        </li>
                        <li className='ps-3'>
                            <Link to="/settings" className={splitLocation[1] === "settings" ? "active" : ""}>
                                <img src={setting} alt="setting" />
                                <img src={settingactive} alt="settingactive" className='activeviewimg' />
                                Settings
                            </Link>
                        </li>
                        <li className="ps-3 create-project">
                            <div className="create-project-inner">
                                {
                                    projects.length
                                    ?
                                    projects.map((project, key) => {
                                        if (project.active) {
                                            return (
                                                <label key={key}>
                                                    <img src={globen} alt="globen" />
                                                    {project.name}

                                                    <Link to="/project-settings">
                                                        <img src={setting} alt="setting" />
                                                    </Link>
                                                </label>
                                            );
                                        }
                                    })
                                    :
                                    <label key='1'><img src={globen} alt="globen" />Create a project</label>
                                }
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <img src={unfold} alt="unfold" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {
                                            projects.map((project, key) => {
                                                return (
                                                    <>
                                                        <Dropdown.Item 
                                                            className={project.active ? "" : ""} 
                                                            key={key}
                                                            onClick={()=>changeActiveProject(project.id)}
                                                        >{project.name}
                                                        </Dropdown.Item>
                                                    </>
                                                );
                                            })
                                        }
                                        <Dropdown.Item onClick={() => setModalShow(true)} className="create-project-btn">Create a New Project</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </li>
                        <li className='ps-3'>
                            <Link to="/" onClick={handleLogout}>
                                <img src={logout} alt="logout" />
                                <img src={logoutactive} alt="logoutactive" className='activeviewimg' />
                                Logout
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>

            <AddProject
                show={modalShow}
                onHide={() => setModalShow(false)}
                saveProject={saveProject}
                setProjectName={setProjectName}
            />
        </>
    );
}
export default Sidebar;