import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import save from "../Images/save.svg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from 'react';

export default function MyVerticallyCenteredModal(props) {
  const navigate = useNavigate();
  const [widgetName, setWidgetName] = useState(null);

  const moveToWidgetTemplate = () => {
    if (widgetName) {
      navigate('/social-proof/widgets/template?widget=' + widgetName);
    } else {
      toast.error('Please enter a widget name');
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      moveToWidgetTemplate();
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal {...props} size="lg" onKeyDown={handleKeyDown} aria-labelledby="contained-modal-title-vcenter" centered className='add_tile'>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create a widget
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Name this widget so you can find it later in <img src={save} alt="save" className='me-1 ms-1' />Saved</h5>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="widget_name"
                placeholder="Ex. Home Page Testimonials"
                onChange={(e) => setWidgetName(e.target.value)}
                required
                autoComplete="off"
              />
            </Form.Group>
          </Form>
          <Button onClick={moveToWidgetTemplate}>Create widget</Button>
        </Modal.Body>
      </Modal>
    </>
  );
}