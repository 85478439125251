import { Col, Row, Container } from 'react-bootstrap';
import Sidebar from './Sidebar';
import Header from './Header';
import filter from "../Images/filter.png";
import unlike from "../Images/unlike.png";
import like from "../Images/like.png";
import thump from "../Images/thump.png";
import twitter from "../Images/twitter.png";
import google from "../Images/googlicon.png";
import facebook from "../Images/facebookF.png";
import vimeo from "../Images/vimeo.png";
import trash from "../Images/trash.png";
import all from "../Images/all.png";
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classNames from 'classnames';
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import ImportReview from './ImportReview';
import Embeded from './Embeded'
import VideoPlayer from './VideoPlayer';
import VimeoPlayer from "./VimeoPlayer";
import copy from "../Images/copy.png";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Loader from './Loader';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

function ProofWall(props) {
    const { classes, droppableId, list, type } = props;
    const accessToken = localStorage.getItem('accessToken');
    const [posts, setPosts] = useState([]);
    const [puddingReviews, setPuddingReviews] = useState([]);
    const [googleReviews, setGoogleReviews] = useState([]);
    const [facebookReviews, setFacebookReviews] = useState([]);
    const [vimeoReviews, setVimeoReviews] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [filterPlatforms, setFilterPlatforms] = useState([]);
    const [isActive, setActive] = useState("month");

    const user = JSON.parse(localStorage.getItem('user'));
    const [shortName, setShortName] = useState('');

    const [openDropdown, setOpenDropdown] = useState(false);
    const [isImportOpen, setIsImportOpen] = useState(false);
    const [isUploaderOpen, setIsUploaderOpen] = useState(false);
    const [openEmbed, setOpenEmbed] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [SubDomain, setSubDomain] = useState();
    const [WidtgetId, setWidtgetId] = useState();

    const [isLoading, setIsLoading] = useState(true);

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        if (!isCheckAll) {
            setIsCheck(platforms.map(li => li.id));
            filterAll(isActive, platforms.map(li => li.id));
        } else {
            setIsCheck([]);
            filterAll(isActive, []);
        }
    };

    const handleClick = e => {
        const { value, checked } = e.target;
        let temp_arr = []
        if (!checked) {
            temp_arr = [];
            isCheck.map((val, ind) => {
                if (value != val) {
                    temp_arr.push(val);
                }
            })
            setIsCheck(temp_arr);
            filterAll(isActive, temp_arr);
        } else {
            temp_arr = [];
            isCheck.map((val, ind) => {
                temp_arr.push(val);
            })
            temp_arr.push(parseInt(value));
            setIsCheck(temp_arr);
            filterAll(isActive, temp_arr);
        }
        if (platforms.length != temp_arr.length) {
            setIsCheckAll(false);
        } else {
            setIsCheckAll(true);
        }
    };

    const logo_mark_style = {
        background: '#000000',
        color: 'white',
        padding: '2px',
        fontSize: '9px',
        fontWeight: '700',
        borderRadius: '5px'
    }

    const getAllReviewsSeparated = () => {
        fetch(process.env.REACT_APP_API_URL + 'get-all-reviews-separated', {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            },
        }).then(response => response.json())
            .then(data => {
                if(data.status){
                    setPosts(data.data.tweets);
                    setPuddingReviews(data.data.reviews);
                    setGoogleReviews(data.data.google);
                    setFacebookReviews(data.data.facebook);
                    setVimeoReviews(data.data.vimeo);
                }
            });
    }
    const getProofPuddingReviews = () => {
        fetch(process.env.REACT_APP_API_URL + 'get-proof-pudding-reviews', {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            },
        }).then(response => response.json())
            .then(data => {
                setPuddingReviews(data.data.reviews);
            });
    }

    const getPlatforms = () => {
        fetch(process.env.REACT_APP_API_URL + 'platforms', {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            },
        }).then(response => response.json())
            .then(data => {
                setPlatforms(data.data);
            });
    }

    const getFilterPlatforms = () => {
        fetch(process.env.REACT_APP_API_URL + 'filter-platforms', {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            },
        }).then(response => response.json())
            .then(data => {
                setFilterPlatforms(data.data);
                setIsCheck(data.data.map(li => li.id));
                setIsCheckAll(true);
            });
    }

    function deleteReview(id) {
        fetch(process.env.REACT_APP_API_URL + 'delete-separate-wall', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify({ 'id': id, 'filter_by': isActive, 'platforms': isCheck })
        })
            .then(data => data.json())
            .then(data => {
                if (data.status) {
                    setPosts(data.data.tweets);
                    setPuddingReviews(data.data.reviews);
                } else {
                    toast.error(data.message);
                }
            });
    }

    function likeReview(id, status) {
        fetch(process.env.REACT_APP_API_URL + 'like-separate-wall', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify({ 'id': id, 'status': status, 'filter_by': isActive, 'platforms': isCheck })
        })
            .then(data => data.json())
            .then(data => {
                if (data.status) {
                    setPosts(data.data.tweets);
                    setPuddingReviews(data.data.reviews);
                } else {
                    toast.error(data.message);
                }
            });
    }

    function filterReview(filter_by) {
        setActive(filter_by);
        filterAll(filter_by, isCheck);
    }

    function filterAll(filter_by, platforms) {
        fetch(process.env.REACT_APP_API_URL + 'filter-separate-wall-by-time', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify({ 'filter_by': filter_by, 'platforms': platforms })
        })
            .then(data => data.json())
            .then(data => {
                if (data.status) {
                    setPosts(data.data.tweets);
                    setPuddingReviews(data.data.reviews);
                    setGoogleReviews(data.data.google);
                    setFacebookReviews(data.data.facebook);
                    setVimeoReviews(data.data.vimeo);
                } else {
                    toast.error(data.message);
                }
            });
    }

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        const itemss = reorder(
            posts,
            result.source.index,
            result.destination.index
        );

        setPosts(itemss);
    }

    function onDaragPudding(result) {
        if (!result.destination) {
            return;
        }
        const puddings = reorder(
            puddingReviews,
            result.source.index,
            result.destination.index
        );

        setPuddingReviews(puddings);
    }

    const viewVideo = (review_id, type) => {
        fetch(process.env.REACT_APP_API_URL + 'view-count-increment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify({ review_id, type })
        })
            .then(data => data.json())
            .then(data => {
                console.log('video viewed');
            });
    }

    function getUserNameInitials(name_) {
        const slices = name_.split(' ');
        let name_initials = '';
        slices.forEach(item => {
            name_initials = name_initials + '' + item.charAt(0).toUpperCase();
        });
        setShortName(name_initials);
    }
      const getUserData = () => {
        fetch(process.env.REACT_APP_API_URL + 'get-user-data', {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            },
        }).then(response => response.json())
            .then(data => { 
                 
                  
                 if(data.projects[0]!=undefined){
                 var subDomain=data.projects[0].subdomain;   
                  
                 if(subDomain){

                    var checkDomainType = (subDomain.match(/\./g) || []).length;

                    if(checkDomainType>1){

                        var domainName = subDomain.split('.')[1]; 

                        var getNewUrl='<iframe src="http://'+domainName+'.'+process.env.REACT_APP_PROOFWALL_COMBINED_URL+'widgetProofWallSeprated/'+data.default_widget+'" style="height:700px;width:100%"></iframe>';
                      
                        setSubDomain(getNewUrl);
                    }else{

                     
                        var streetaddress = subDomain.split('.')[0];
                        var domainName = streetaddress.split('/').pop();

                        var getNewUrl='<iframe src="http://'+domainName+'.'+process.env.REACT_APP_PROOFWALL_COMBINED_URL+'widgetProofWallSeprated/'+data.default_widget+'" style="height:700px;width:100%"></iframe>';
                      
                        setSubDomain(getNewUrl);
                      
                }


                 }else{
                     var getNewUrl='<iframe src="https://'+process.env.REACT_APP_PROOFWALL_COMBINED_URL+'widgetProofWallSeprated/'+data.default_widget+'" style="height:700px;width:100%"></iframe>';
                      
                        setSubDomain(getNewUrl);   
                 }
                 }else{
                var getNewUrl='<iframe src="https://'+process.env.REACT_APP_PROOFWALL_COMBINED_URL+'widgetProofWallSeprated/'+data.default_widget+'" style="height:700px;width:100%"></iframe>';
                       
                        setSubDomain(getNewUrl); 
             }
                 
            });
    }

    useEffect(() => {
        getPlatforms();
        getFilterPlatforms();
        getUserNameInitials(user.name);
        getAllReviewsSeparated();
        getUserData(); 
        setIsLoading(false);
        
    }, []);

    const getReviews = () => {
        getProofPuddingReviews();
    }

    function openClosedropdown(slug) {
        setOpenDropdown(false);
        setIsImportOpen(false);
        setIsUploaderOpen(false);
        setOpenEmbed(false);
        if (slug == 'filter') {
            setOpenDropdown(!openDropdown);
        }
        if (slug == 'import') {
            setIsImportOpen(!isImportOpen);
        }
        if (slug == 'embed') {
            setOpenEmbed(!openEmbed);
        }
    }

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <Container fluid className="pe-0">
                <Sidebar />
                <div className='wrapper'>
                    <ToastContainer />
                    <Header title="Proof Wall" />
                    <div className='filter'>
                        <Row>
                            <Col md="4">
                                <div className='layout_switch'>
                                    <Link to="/proof-wall/combined">Combined</Link>
                                    <Link to="/proof-wall/separated" className='active'>Separate</Link>
                                </div>
                            </Col>
                            <Col md="8">
                                <div className='d-flex align-items-center justify-content-end right_side'>
                                    <div className='day_month_year me-3 d-none d-lg-block'>
                                        <ul className='p-0 d-flex'>
                                            <li className={isActive === 'month' ? 'active' : ''} onClick={() => filterReview('month')}>Month</li>
                                            <li className={isActive === 'day' ? 'active' : ''} onClick={() => filterReview('day')}>Day</li>
                                            <li className={isActive === 'year' ? 'active' : ''} onClick={() => filterReview('year')}>Year</li>
                                        </ul>
                                    </div>

                                    <div className='choose_filter d-flex align-items-center'>
                                        <img src={filter} alt="filter" className="me-1" />
                                        Filter
                                        <div className='custom_select' onClick={openClosedropdown.bind(this, 'filter')}>
                                            <div className='title'>
                                                <img src={all} alt="all" className="me-1" />
                                                All
                                                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" className='ms-2'>
                                                    <g clip-path="url(#clip0_239_4057)">
                                                        <path d="M9.92202 0.734381C9.87397 0.807657 9.8177 0.874662 9.75442 0.933966C8.31738 2.43877 6.88015 3.94139 5.44273 5.44184C5.09673 5.80299 4.90015 5.80477 4.56039 5.45015C3.10934 3.93426 1.65866 2.41778 0.208368 0.900703C-0.0933192 0.584694 -0.0632072 0.210473 0.271433 0.0530627C0.318401 0.0335587 0.366868 0.0182584 0.416311 0.00732529C0.650389 0.00732529 0.769133 0.133253 0.890717 0.259776C2.18723 1.61588 3.48716 2.96842 4.77743 4.33106C4.94162 4.5045 5.02457 4.53896 5.21036 4.34175C6.48983 2.98267 7.78521 1.63845 9.07378 0.290663C9.14325 0.210033 9.22142 0.138077 9.30672 0.0762296C9.39004 0.015948 9.49149 -0.0106008 9.59215 0.00152994C9.69281 0.0136607 9.78582 0.0636424 9.85385 0.142163C10.0243 0.312641 10.0453 0.522323 9.92202 0.734381Z" fill="black" />
                                                        <path d="M9.92202 0.734381C9.87397 0.807657 9.8177 0.874662 9.75442 0.933966C8.31738 2.43877 6.88015 3.94139 5.44273 5.44184C5.09673 5.80299 4.90015 5.80477 4.56039 5.45015C3.10934 3.93426 1.65866 2.41778 0.208368 0.900703C-0.0933192 0.584694 -0.0632072 0.210473 0.271433 0.0530627C0.318401 0.0335587 0.366868 0.0182584 0.416311 0.00732529C0.650389 0.00732529 0.769133 0.133253 0.890717 0.259776C2.18723 1.61588 3.48716 2.96842 4.77743 4.33106C4.94162 4.5045 5.02457 4.53896 5.21036 4.34175C6.48983 2.98267 7.78521 1.63845 9.07378 0.290663C9.14325 0.210033 9.22142 0.138077 9.30672 0.0762296C9.39004 0.015948 9.49149 -0.0106008 9.59215 0.00152994C9.69281 0.0136607 9.78582 0.0636424 9.85385 0.142163C10.0243 0.312641 10.0453 0.522323 9.92202 0.734381Z" fill="black" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_239_4057">
                                                            <rect width="10" height="5.71428" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className={"custom_dropdown " + (openDropdown ? '' : 'd-none')}>
                                            <div className='box'>
                                                <ul className='m-0 p-0'>

                                                    <li>
                                                        <div className='right'>
                                                            <label><img src={all} alt="all" className="me-1" />All</label>
                                                        </div>
                                                        <div className='custom_check'>
                                                            <input
                                                                type="checkbox"
                                                                onClick={handleSelectAll}
                                                                checked={isCheckAll}
                                                            />
                                                            <span></span>
                                                        </div>
                                                    </li>
                                                    {filterPlatforms.map(function (p, key) {
                                                        return (
                                                            <li key={key}>
                                                                <div className='right'>
                                                                    <label><img src={process.env.REACT_APP_PLATFORM_LOGO_URL + '' + p.logo} alt={p.slug} className="me-1" />{p.name}</label>
                                                                </div>
                                                                <div className='custom_check'>
                                                                    <input
                                                                        type="checkbox"
                                                                        value={p.id}
                                                                        id={p.id}
                                                                        onClick={handleClick}
                                                                        checked={isCheck.includes(p.id)}
                                                                    />
                                                                    <span></span>
                                                                </div>
                                                            </li>
                                                        );

                                                    })}

                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <ImportReview 
                                        openClosedropdownfunction={openClosedropdown} 
                                        setIsImportOpen={isImportOpen} 
                                        upload={isUploaderOpen} 
                                        platforms={platforms} 
                                        setIsLoading={setIsLoading} 
                                        getReviews={getReviews}
                                    />

                                    <div className='embed position-relative'>
                                        {/*<Embeded openClosedropdownfunction={openClosedropdown} embed={openEmbed} type="separated"/>*/}
                                     <Link onClick={openClosedropdown.bind(this, 'embed')}>Embed</Link>
                                    <div className={"custom_dropdown " + (openEmbed ? '' : 'd-none')}>
                                        <div className="box import p-2">
                                            <div className='embed_inner'>
                                                <label>Embed Code</label>
                                                <textarea name="text" 
                                                    readOnly id="textarea"
                                                    defaultValue={ SubDomain}
                                                >
                                                </textarea>
                                                <CopyToClipboard
                                                   text={SubDomain}
                                                    onCopy={() => {
                                                        toast.dismiss();
                                                        toast.success('Copied to clipboard!')
                                                    }}  >
                                                    <button><img src={copy} alt="copy" className='me-2' />Copy</button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='masonry'>
                        <Row>
                            <Col>
                                <div className='separate_wrapper'>
                                    <div className='twitter'>
                                        {
                                            posts.length
                                                ?
                                                <h4>Twitter</h4>
                                                :
                                                ''
                                        }
                                        <div className='separate_box'>

                                                <DragDropContext onDragEnd={onDragEnd}>
                                                    <Droppable droppableId="droppable">
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                className={classNames('masonry', snapshot.isDraggingOver && 'draggingOver')}
                                                                {...provided.droppableProps}
                                                            >
                                                            <ResponsiveMasonry
                                                                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                                                            >
                                                                    <Masonry gutter={10}>
                                                                        {posts.map(function (d, index1) {
                                                                            return (
                                                                                <Draggable key={d.id} draggableId={`draggable-${d.id}`} index={index1}>
                                                                                    {(provided, snapshot) => (
                                                                                        <div key={d.id}
                                                                                            className={classNames('card', snapshot.isDragging && 'dragging')}
                                                                                            style={provided.draggableProps.style}
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}

                                                                                        >
                                                                                            <div className='box'>
                                                                                                <div className='d-flex justify-content-between'>
                                                                                                    <div className='profile d-flex align-items-center'>
                                                                                                        <div className='avatar'>
                                                                                                            {
                                                                                                                d.reviewer_picture
                                                                                                                    ?
                                                                                                                    <img src={d.reviewer_picture} alt="userimage" />
                                                                                                                    :
                                                                                                                    <div className='profile_name d-flex justify-content-center align-items-center'>
                                                                                                                        {shortName}
                                                                                                                    </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <div className='name'>{d.reviewer_name}</div>
                                                                                                            <div className='email'>@{d.reviewer_handle}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='likeunlike position-relative' style={{ cursor: 'pointer' }}>
                                                                                                        {d.is_liked
                                                                                                            ?
                                                                                                            <img src={like} alt="like-tweet" onClick={() => likeReview(d.id, 0)} />
                                                                                                            :
                                                                                                            <img src={unlike} alt="unlike-tweet" onClick={() => likeReview(d.id, 1)} />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <p className='mt-3'>{d.text}</p>
                                                                                                {d.files && d.media[0]?
                                                                                                    <div className='upload_post_image mt-3' onClick={() => {
                                                                                                        viewVideo(d.media[0].id, d.media[0].type)
                                                                                                    }}>

                                                                                                        {
                                                                                                            d.platform_name === 'proof_pudding' && d.media[0] && d.media[0].file_url
                                                                                                                ?
                                                                                                                <VideoPlayer src={d.media[0].file_url} id={d.media[0].id} />
                                                                                                                :
                                                                                                                d.media[0].type === 'photo'
                                                                                                                    ?
                                                                                                                    <img src={d.media[0].file_url} alt="image" className='w-100' />
                                                                                                                    :
                                                                                                                    <VideoPlayer src={d.media[0].file_url} id={d.media[0].id} />
                                                                                                        }

                                                                                                    </div>
                                                                                                    : ''}
                                                                                                <p className='mt-3'></p>
                                                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                                                    <div className='d-flex align-items-center'>
                                                                                                        <div className='like_count d-flex align-items-center'>
                                                                                                            <img src={thump} alt="thump" />
                                                                                                            <span>{d.like_count}</span>
                                                                                                        </div>
                                                                                                        <div className='date'>{d.review_date_2} <img src={twitter} alt="twitter" /></div>
                                                                                                    </div>
                                                                                                    <div className='delete' onClick={() => deleteReview(d.id)} style={{ cursor: 'pointer' }}>
                                                                                                        <img src={trash} alt="trash" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            )
                                                                        })}
                                                                    </Masonry>
                                                             </ResponsiveMasonry>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                        </div>
                                    </div>
                                    <div className='linkedin mt-3'>
                                        {
                                            puddingReviews.length
                                                ?
                                                <h4>Proof Pudding</h4>
                                                :
                                                ''
                                        }
                                        <div className='separate_box'>
                                            <ResponsiveMasonry
                                                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                                            >
                                                <DragDropContext onDragEnd={onDaragPudding}>
                                                    <Droppable droppableId="droppable">
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                className={classNames('masonry', snapshot.isDraggingOver && 'draggingOver')}
                                                                {...provided.droppableProps}
                                                            >
                                                                <Masonry gutter={10}>
                                                                    {puddingReviews.map(function (d, index2) {
                                                                        return (
                                                                            <Draggable key={d.id} draggableId={`draggable-${d.id}`} index={index2}>
                                                                                {(provided, snapshot) => (
                                                                                    <div key={d.id}
                                                                                        className={classNames('card', snapshot.isDragging && 'dragging')}
                                                                                        style={provided.draggableProps.style}
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}

                                                                                    >
                                                                                        <div className='box'>
                                                                                            <div className='d-flex justify-content-between'>
                                                                                                <div className='profile d-flex align-items-center'>
                                                                                                    <div className='avatar'>
                                                                                                    {
                                                                                                        d.platform_name === 'proof_pudding'
                                                                                                            ?
                                                                                                            d.reviewer_picture
                                                                                                                ?
                                                                                                                <img src={d.reviewer_picture} alt="userimage" />
                                                                                                                :
                                                                                                                <div className='profile_name d-flex justify-content-center align-items-center'>
                                                                                                                    PP
                                                                                                                </div>
                                                                        
                                                                                                            :

                                                                                                            d.reviewer_picture
                                                                                                                ?
                                                                                                                <img src={d.reviewer_picture} alt="img" />
                                                                                                                :
                                                                                                                <div className='profile_name d-flex justify-content-center align-items-center'>
                                                                                                                    {shortName}
                                                                                                                </div>
                                                                                                    }
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <div className='name'>{d.reviewer_name}</div>
                                                                                                       { /* <div className='email'>@{d.reviewer_handle}</div> */ }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='likeunlike position-relative' style={{ cursor: 'pointer' }}>
                                                                                                    {d.is_liked
                                                                                                        ?
                                                                                                        <img src={like} alt="like-tweet" onClick={() => likeReview(d.id, 0)} />
                                                                                                        :
                                                                                                        <img src={unlike} alt="unlike-tweet" onClick={() => likeReview(d.id, 1)} />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <p className='mt-3'>{d.text}</p>
                                                                                            {d.files ?
                                                                                                <div className='upload_post_image mt-3' onClick={() => {
                                                                                                    viewVideo(d.media[0].id, d.media[0].type)
                                                                                                }}>
                                                                                                    <VideoPlayer src={d.media[0].file_url} id={d.media[0].id} />
                                                                                                </div>
                                                                                                : ''}
                                                                                            <p className='mt-3'></p>
                                                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                                                <div className='d-flex align-items-center'>
                                                                                                    <div className='like_count d-flex align-items-center'>
                                                                                                        <img src={thump} alt="thump" />
                                                                                                        <span>{d.like_count}</span>
                                                                                                    </div>

                                                                                                    {
                                                                                                        d.platform_name === 'proof_pudding'
                                                                                                            ?
                                                                                                            <div className='date'>{d.review_date_2} <span style={logo_mark_style}>PP</span></div>
                                                                                                            :
                                                                                                            <div className='date'>{d.review_date_2}  <img src={twitter} alt="twitter" /></div>
                                                                                                    }

                                                                                                </div>
                                                                                                <div className='delete' onClick={() => deleteReview(d.id)} style={{ cursor: 'pointer' }}>
                                                                                                    <img src={trash} alt="trash" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        )
                                                                    })}
                                                                </Masonry>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </ResponsiveMasonry>
                                        </div>
                                    </div>

                                    {/* vimeo reviews starts */}
                                    <div className='linkedin mt-3'>
                                        {
                                            vimeoReviews.length
                                                ?
                                                <h4>Vimeo</h4>
                                                :
                                                ''
                                        }
                                        <div className='separate_box'>
                                            <ResponsiveMasonry
                                                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                                            >
                                                <DragDropContext onDragEnd={onDaragPudding}>
                                                    <Droppable droppableId="droppable">
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                className={classNames('masonry', snapshot.isDraggingOver && 'draggingOver')}
                                                                {...provided.droppableProps}
                                                            >
                                                                <Masonry gutter={10}>
                                                                    {vimeoReviews.map(function (d, index2) {
                                                                        return (
                                                                            <Draggable key={d.id} draggableId={`draggable-${d.id}`} index={index2}>
                                                                                {(provided, snapshot) => (
                                                                                    <div key={d.id}
                                                                                        className={classNames('card', snapshot.isDragging && 'dragging')}
                                                                                        style={provided.draggableProps.style}
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}

                                                                                    >
                                                                                        <div className='box'>
                                                                                            <div className='d-flex justify-content-between'>
                                                                                                <div className='profile d-flex align-items-center'>
                                                                                                    <div className='avatar'>
                                                                                                        {
                                                                                                            d.reviewer_picture
                                                                                                                ?
                                                                                                                <img src={d.reviewer_picture} alt="userimage" />
                                                                                                                :
                                                                                                                <div className='profile_name d-flex justify-content-center align-items-center'>
                                                                                                                    {shortName}
                                                                                                                </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <div className='name'>{d.reviewer_name}</div>
                                                                                                        {/* <div className='email'>@{d.reviewer_handle}</div> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='likeunlike position-relative' style={{ cursor: 'pointer' }}>
                                                                                                    {d.is_liked
                                                                                                        ?
                                                                                                        <img src={like} alt="like-tweet" onClick={() => likeReview(d.id, 0)} />
                                                                                                        :
                                                                                                        <img src={unlike} alt="unlike-tweet" onClick={() => likeReview(d.id, 1)} />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <p className='mt-3'>{d.text}</p>
                                                                                            {d.files ?
                                                                                                <div className='upload_post_image mt-3' onClick={() => {
                                                                                                    viewVideo(d.media[0].id, d.media[0].type)
                                                                                                }}>
                                                                                                
                                                                                                    <VimeoPlayer key={d.review_id} videoId={d.review_id} />
                                                                                                </div>
                                                                                                : ''}
                                                                                            <p className='mt-3'></p>
                                                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                                                <div className='d-flex align-items-center'>
                                                                                                    <div className='like_count d-flex align-items-center'>
                                                                                                        <img src={thump} alt="thump" />
                                                                                                        <span>{d.like_count}</span>
                                                                                                    </div>

                                                                                                    {
                                                                                                        d.platform_name === 'proof_pudding'
                                                                                                            ?
                                                                                                            <div className='date'>{d.review_date_2} <span style={logo_mark_style}>PP</span></div>
                                                                                                            :
                                                                                                            <div className='date'>{d.review_date_2}  <img src={vimeo} alt="vimeo" /></div>
                                                                                                    }

                                                                                                </div>
                                                                                                <div className='delete' onClick={() => deleteReview(d.id)} style={{ cursor: 'pointer' }}>
                                                                                                    <img src={trash} alt="trash" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        )
                                                                    })}
                                                                </Masonry>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </ResponsiveMasonry>
                                        </div>
                                    </div>
                                    {/* vimeo reviews ends */}                                


                                    {/* google reviews starts */}
                                    <div className='linkedin mt-3'>
                                        {
                                            googleReviews.length
                                                ?
                                                <h4>Google</h4>
                                                :
                                                ''
                                        }
                                        <div className='separate_box'>
                                            <ResponsiveMasonry
                                                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                                            >
                                                <DragDropContext onDragEnd={onDaragPudding}>
                                                    <Droppable droppableId="droppable">
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                className={classNames('masonry', snapshot.isDraggingOver && 'draggingOver')}
                                                                {...provided.droppableProps}
                                                            >
                                                                <Masonry gutter={10}>
                                                                    {googleReviews.map(function (d, index2) {
                                                                        return (
                                                                            <Draggable key={d.id} draggableId={`draggable-${d.id}`} index={index2}>
                                                                                {(provided, snapshot) => (
                                                                                    <div key={d.id}
                                                                                        className={classNames('card', snapshot.isDragging && 'dragging')}
                                                                                        style={provided.draggableProps.style}
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}

                                                                                    >
                                                                                        <div className='box'>
                                                                                            <div className='d-flex justify-content-between'>
                                                                                                <div className='profile d-flex align-items-center'>
                                                                                                    <div className='avatar'>
                                                                                                        {
                                                                                                            d.reviewer_picture
                                                                                                                ?
                                                                                                                <img src={d.reviewer_picture} alt="userimage" />
                                                                                                                :
                                                                                                                <div className='profile_name d-flex justify-content-center align-items-center'>
                                                                                                                    {shortName}
                                                                                                                </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <div className='name'>{d.reviewer_name}</div>
                                                                                                        {/* <div className='email'>@{d.reviewer_handle}</div> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='likeunlike position-relative' style={{ cursor: 'pointer' }}>
                                                                                                    {d.is_liked
                                                                                                        ?
                                                                                                        <img src={like} alt="like-tweet" onClick={() => likeReview(d.id, 0)} />
                                                                                                        :
                                                                                                        <img src={unlike} alt="unlike-tweet" onClick={() => likeReview(d.id, 1)} />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <p className='mt-3'>{d.text}</p>
                                                                                            {d.files ?
                                                                                                <div className='upload_post_image mt-3' onClick={() => {
                                                                                                    viewVideo(d.media[0].id, d.media[0].type)
                                                                                                }}>
                                                                                                    <VideoPlayer src={d.media[0].file_url} id={d.media[0].id} />
                                                                                                </div>
                                                                                                : ''}
                                                                                            <p className='mt-3'></p>
                                                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                                                <div className='d-flex align-items-center'>
                                                                                                    <div className='like_count d-flex align-items-center'>
                                                                                                        <img src={thump} alt="thump" />
                                                                                                        <span>{d.like_count}</span>
                                                                                                    </div>

                                                                                                    {
                                                                                                        d.platform_name === 'proof_pudding'
                                                                                                            ?
                                                                                                            <div className='date'>{d.review_date_2} <span style={logo_mark_style}>PP</span></div>
                                                                                                            :
                                                                                                            <div className='date'>{d.review_date_2}  <img src={google} alt="google" /></div>
                                                                                                    }

                                                                                                </div>
                                                                                                <div className='delete' onClick={() => deleteReview(d.id)} style={{ cursor: 'pointer' }}>
                                                                                                    <img src={trash} alt="trash" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        )
                                                                    })}
                                                                </Masonry>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </ResponsiveMasonry>
                                        </div>
                                    </div>
                                    {/* google reviews ends */}

                                    {/* facebook reviews ends */}

                                    <div className='linkedin mt-3'>
                                        {
                                            facebookReviews.length
                                                ?
                                                <h4>Facebook</h4>
                                                :
                                                ''
                                        }
                                        <div className='separate_box'>
                                            <ResponsiveMasonry
                                                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                                            >
                                                <DragDropContext onDragEnd={onDaragPudding}>
                                                    <Droppable droppableId="droppable">
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                className={classNames('masonry', snapshot.isDraggingOver && 'draggingOver')}
                                                                {...provided.droppableProps}
                                                            >
                                                                <Masonry gutter={10}>
                                                                    {facebookReviews.map(function (d, index2) {
                                                                        return (
                                                                            <Draggable key={d.id} draggableId={`draggable-${d.id}`} index={index2}>
                                                                                {(provided, snapshot) => (
                                                                                    <div key={d.id}
                                                                                        className={classNames('card', snapshot.isDragging && 'dragging')}
                                                                                        style={provided.draggableProps.style}
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}

                                                                                    >
                                                                                        <div className='box'>
                                                                                            <div className='d-flex justify-content-between'>
                                                                                                <div className='profile d-flex align-items-center'>
                                                                                                    <div className='avatar'>
                                                                                                        {
                                                                                                            d.reviewer_picture
                                                                                                                ?
                                                                                                                <img src={d.reviewer_picture} alt="userimage" />
                                                                                                                :
                                                                                                                <div className='profile_name d-flex justify-content-center align-items-center'>
                                                                                                                    {shortName}
                                                                                                                </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <div className='name'>{d.reviewer_name}</div>
                                                                                                        {/* <div className='email'>@{d.reviewer_handle}</div> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='likeunlike position-relative' style={{ cursor: 'pointer' }}>
                                                                                                    {d.is_liked
                                                                                                        ?
                                                                                                        <img src={like} alt="like-tweet" onClick={() => likeReview(d.id, 0)} />
                                                                                                        :
                                                                                                        <img src={unlike} alt="unlike-tweet" onClick={() => likeReview(d.id, 1)} />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <p className='mt-3'>{d.text}</p>
                                                                                            {d.files ?
                                                                                                <div className='upload_post_image mt-3' onClick={() => {
                                                                                                    viewVideo(d.media[0].id, d.media[0].type)
                                                                                                }}>
                                                                                                    <VideoPlayer src={d.media[0].file_url} id={d.media[0].id} />
                                                                                                </div>
                                                                                                : ''}
                                                                                            <p className='mt-3'></p>
                                                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                                                <div className='d-flex align-items-center'>
                                                                                                    <div className='like_count d-flex align-items-center'>
                                                                                                        <img src={thump} alt="thump" />
                                                                                                        <span>{d.like_count}</span>
                                                                                                    </div>

                                                                                                    {
                                                                                                        d.platform_name === 'proof_pudding'
                                                                                                            ?
                                                                                                            <div className='date'>{d.review_date_2} <span style={logo_mark_style}>PP</span></div>
                                                                                                            :
                                                                                                            <div className='date'>{d.review_date_2}  <img src={facebook} alt="facebook" /></div>
                                                                                                    }

                                                                                                </div>
                                                                                                <div className='delete' onClick={() => deleteReview(d.id)} style={{ cursor: 'pointer' }}>
                                                                                                    <img src={trash} alt="trash" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        )
                                                                    })}
                                                                </Masonry>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </ResponsiveMasonry>
                                        </div>
                                    </div>
                                    {/* facebook reviews ends */}

                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        </>
    );
}
export default ProofWall;