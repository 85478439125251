import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import validator from 'validator'
import copy from "../Images/copy.png";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const SendEmbedCode = async function (params, access_token) {
    return fetch(process.env.REACT_APP_API_URL + 'update-insert-embed', {
        method: 'POST',
        headers: {
            authorization: 'Bearer ' + access_token,
            Accept: 'application/json'
        },
        body: new URLSearchParams(params),
    }).then((data) => data.json());
}

function Embeded(props) {
    const user = JSON.parse(localStorage.getItem('user'));
    const [openEmbed, setOpenEmbed] = useState(false);
    const [value, setValue] = useState();

    useEffect(() => {
        document.getElementById('textarea').innerHTML = value;
        setOpenEmbed(props.embed);
        console.log(localStorage);
        setValue(JSON.parse(localStorage.getItem('default_widget')));
    }, [props]);

    return (
        <>
            <Link onClick={props.openClosedropdownfunction.bind(this, 'embed')}>Embed</Link>
            <div className={"custom_dropdown " + (openEmbed ? '' : 'd-none')}>
                <div className="box import p-2">
                    <div className='embed_inner'>
                        <label>Embed Code</label>
                        <textarea name="text" 
                            readOnly id="textarea"
                            defaultValue={value}
                        >
                        </textarea>
                        <CopyToClipboard
                            text={value}
                            onCopy={() => {
                                toast.dismiss();
                                toast.success('Copied to clipboard!')
                            }}  >
                            <button><img src={copy} alt="copy" className='me-2' />Copy</button>
                        </CopyToClipboard>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Embeded;