import Widget_1 from "../Tem_images/Widget_1.png";
import Widget_2 from "../Tem_images/Widget_2.png";
import Modal from "./Modal";
import scratch from "../Images/scratch.svg";
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import React,{useState,useEffect} from "react";

export default function WidgetPartial() {

    const user = JSON.parse(localStorage.getItem('user'));
    const [modalShow, setModalShow] = React.useState(false);
    const [allowedWidgets, setAllowedWidgets] = useState();

    useEffect(()=>{
        setAllowedWidgets(user.userplan.plan.no_of_widgets==='unlimited'?100:user.userplan.plan.no_of_widgets);
    },[]);

    return (
        <Card className='mt-3'>
            <div className='card_box_wrapper'>
                <h4>Widgets</h4>
                <p>Embed testimonials on your site without code.</p>
                <div className="scroller widget_list">
                    <div className="scroller_inner">
                        <div className="start_from_scratch">
                            <Link to="" onClick={() => setModalShow(true)}>
                                <div className='start'>
                                    <img src={scratch} alt="scratch" />
                                    <h5>Start form <br />scratch</h5>
                                </div>
                            </Link>
                        </div>
                        <ul>
                            <li className={allowedWidgets>=1?'':'d-none'}>
                                <Link to="" onClick={() => setModalShow(true)}>
                                    <img src={Widget_1} alt="widget" />
                                </Link>
                            </li>
                            <li className={allowedWidgets>=2?'':'d-none'}>
                                <Link to="" onClick={() => setModalShow(true)}>
                                    <img src={Widget_2} alt="widget" />
                                </Link>
                            </li>
                        </ul>                        
                    </div>
                </div>

                <Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </div>
        </Card>
    );
}