import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Images/proof-wall-logo.png"
import Trash from "../../Images/trash.png"
import VideoPlayer from '../VideoPlayer';
import VimeoPlayer from "../VimeoPlayer";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { Card, Col, Container, Row } from 'react-bootstrap';
import dummylogo from "../../Images/dummylogo.png";
import userone from "../../Images/userone.png";
import usertwo from "../../Images/usertwo.png";
import userthree from "../../Images/userthree.png";

function Wallofloveone(props) {
    const handleRemove = (review_id) => {
        const testimonials_ids = localStorage.getItem('love_testimonials_ids');
        const array = testimonials_ids.split(",");
        const newArray = array.filter((item) => parseInt(item) != parseInt(review_id));
        const new_testimonials_ids = newArray.join(", ");

        if (new_testimonials_ids) {
            const eventData = { data: new_testimonials_ids };
            const event = new CustomEvent('lovewallData', { detail: eventData });
            document.dispatchEvent(event);
            localStorage.removeItem("love_testimonials_ids");
        }
    }
    const viewVideo = (review_id, type) => {
        fetch(process.env.REACT_APP_API_URL + 'view-count-increment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ review_id, type })
        })
            .then(data => data.json())
            .then(data => {
                console.log('video viewed');
            });
    }
    return (
        <>
            <div className="wall_template_wrapper" id="wall_of_love_theme" style={{ backgroundColor: props.Bgcolor }}>
                <div className="walltemp_one">
                    <div className="head d-flex justify-content-between align-items-start">
                        <div className="left">
                            <div className="logo">
                                <img src={props.logoimage != '' ? props.logoimage : dummylogo} style={{ height: props.size != 0 ? props.size + "px" : "" }} alt="logo" />
                            </div>
                        </div>
                        <div className="right">
                            <ul className="menu">
                                <li>
                                    <Link to={props.MenuOneLink}>{props.MenuOneTitle}</Link>
                                </li>
                                <li>
                                    <Link to={props.MenuTwoLink}>{props.MenuTwoTitle}</Link>
                                </li>
                                <li>
                                    <Link to={props.MenuThreeLink}>{props.MenuThreeTitle}</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="main_content">
                        <Container>
                            <Row>
                                <Col>
                                    <h3 className="text-center">{props.pageTitle}</h3>
                                    <ResponsiveMasonry
                                        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                                    >
                                        <Masonry gutter={10}>
                                            {props.data.length > 0 && (props.data).map((value, index) => (
                                                <Card key={index}>
                                                    <button 
                                                        onClick={() => handleRemove(value.id)} 
                                                        className={props.showDelete ? "remove" : 'd-none'}
                                                    ><img src={Trash}/>
                                                    </button>
                                                    <div className="box" style={{ backgroundColor: props.reviewColor }}>
                                                        <div className="head d-flex">
                                                            <div className="avatar">
                                                                {
                                                                    value.reviewer_picture
                                                                        ?
                                                                        <img src={value.reviewer_picture} alt="userimg" />
                                                                        :
                                                                        <img src={userthree} alt="userimg" />
                                                                }
                                                            </div>
                                                            <div>
                                                                <div className="name" style={{ color: props.fontColor, fontFamily: props.fontFamily, fontSize: props.authorFont }}>{value.reviewer_name}</div>
                                                                <div className="designation" style={{ color: props.fontColor, fontFamily: props.fontFamily, fontSize: props.authorFont }}>{value.reviewer_handle ? '@' + value.reviewer_handle : ''}</div>
                                                            </div>
                                                        </div>
                                                        <div className="review">
                                                            <p style={{color:props.fontColor,fontFamily:props.fontFamily,fontSize:props.reviewFont}}>
                                                                {value.text}
                                                            </p>

                                                            {value.files ?
                                                                <div className='upload_post_image mt-3' onClick={() => {
                                                                    viewVideo(value.media[0].id, value.media[0].type)
                                                                }}>
                                                                    {
                                                                        value.platform_name === 'proof_pudding'
                                                                            ?
                                                                            <VideoPlayer src={value.media[0].file_url} id={value.media[0].id} />
                                                                            :
                                                                            value.media[0].type === 'photo'
                                                                                ?
                                                                                <img src={value.media[0].file_url} alt="image" className='w-100' />
                                                                                :
                                                                                value.platform_name === 'vimeo'
                                                                                    ?
                                                                                    <VimeoPlayer key={value.review_id} videoId={value.review_id} />
                                                                                    :
                                                                                    <VideoPlayer src={value.media[0].file_url} id={value.media[0].id} />
                                                                    }
                                                                </div>
                                                                : ''}

                                                        </div>
                                                        <div className="date" style={{ color: props.fontColor, fontFamily: props.fontFamily, fontSize: props.authorFont }}>{value.review_date_2}</div>
                                                    </div>
                                                </Card>
                                            ))}
                                        </Masonry>
                                    </ResponsiveMasonry>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Wallofloveone;