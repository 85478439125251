import { Col, Row, Container, Form, Button, FormGroup, InputGroup } from 'react-bootstrap';
import ProofPuddingLogo from "../Images/ProofPuddingLogo.png";
import resetimage from "../Images/reset.png";
import resetgraphic from "../Images/resetgraphic.svg"
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import showPwdImg from '../Images/show-password.svg';
import hidePwdImg from '../Images/hide-password.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useNavigate } from "react-router-dom";

async function ForgotPasswordConfirm(credentials) {
    // const navigate = useNavigate();
    return fetch(process.env.REACT_APP_API_URL + 'password-reset', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
        .then((response) => {

            if (response.status) {
                toast.success(response.message);
                setTimeout(() => {
                    // navigate('/')
                    window.location = '/';
                }, 500);
            } else {
                toast.error(response.message);
            }
        })
}

function ResetPassword() {

    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isRevealPwd2, setIsRevealPwd2] = useState(false);

    const formSchema = Yup.object().shape({
        password: Yup.string()
            .required('New Password is required')
            .min(8, 'Password must be at 8 character'),
        confirmPwd: Yup.string()
            .required('Confirm New Password is required')
            .oneOf([Yup.ref('password')], 'Confirm New Password must be equal to New Password'),
    })
    const formOptions = { resolver: yupResolver(formSchema) }
    const { register, handleSubmit, reset, formState } = useForm(formOptions)
    const { errors } = formState
    function onSubmit(data) {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const token = params.get('token');

        const pass = data.password;
        const cpass = data.confirmPwd;

        const response = ForgotPasswordConfirm({
            token,
            pass,
            cpass,
        });

    }

    return (
        <>
            <div className='form_header'>
                <ToastContainer />
                <Container fluid>
                    <Row>
                        <Col>
                            <div className='logo reset_logo'>
                                <img src={ProofPuddingLogo} alt="ProofPuddingLogo" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='content_wrapper'>
                <Container fluid className='h-100'>
                    <Row className='h-100'>
                        <Col lg={6} md={12} className='p-0'>
                            <div className='form_wrapper d-flex align-items-center justify-content-center h-100'>
                                <div className='inner_wrapper'>
                                    <h2 className='text-center'><img className='reset' src={resetimage} alt="reset" /> Reset password</h2>
                                    <p className='text-center'>Strong password include numbers, letters, and <br />punctuation marks</p>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Group className="mb-3 position-relative" controlId="formBasicNewPassword">
                                            <Form.Label>New Password</Form.Label>

                                            <InputGroup hasValidation>
                                                <Form.Control
                                                    placeholder="New Password"
                                                    aria-describedby="inputGroupPrepend"
                                                    name="password"
                                                    type={isRevealPwd ? "text" : "password"}
                                                    {...register('password')}
                                                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.password?.message}
                                                </Form.Control.Feedback>
                                            </InputGroup>

                                            {/* <Form.Control placeholder="New Password" type={isRevealPwd ? "text" : "password"} value={pwd} onChange={e => setPwd(e.target.value)} /> */}

                                            <img
                                                title={isRevealPwd ? "Hide password" : "Show password"}
                                                src={isRevealPwd ? hidePwdImg : showPwdImg}
                                                onClick={() => setIsRevealPwd(prevState => !prevState)}
                                                alt="hide-show-pass"
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3 position-relative" controlId="formBasicConfirmPassword">
                                            <Form.Label>Confirm New Password</Form.Label>

                                            <InputGroup hasValidation>
                                                <Form.Control
                                                    placeholder="Confirm New Password"
                                                    aria-describedby="inputGroupPrepend"

                                                    type={isRevealPwd2 ? "text" : "password"}
                                                    name="confirmPwd"
                                                    {...register('confirmPwd')}
                                                    className={`form-control ${errors.confirmPwd ? 'is-invalid' : ''}`}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.confirmPwd?.message}
                                                </Form.Control.Feedback>
                                            </InputGroup>

                                            {/* <Form.Control placeholder="Confirm New Password" type={isRevealPwd2 ? "text" : "password"} value={pwd2} onChange={e => setPwd2(e.target.value)} /> */}

                                            <img
                                                title={isRevealPwd2 ? "Hide password" : "Show password"}
                                                src={isRevealPwd2 ? hidePwdImg : showPwdImg}
                                                onClick={() => setIsRevealPwd2(prevState2 => !prevState2)}
                                                alt="hide-show-pass"
                                            />
                                        </Form.Group>
                                        <Button variant="primary" type="submit" className='m-auto mb-4 mt-3'>
                                            Change Password
                                        </Button>
                                        <FormGroup>
                                            <div className='link text-center'>
                                                <Link className='ms-1' to="/">Back to Sign in</Link>
                                            </div>
                                        </FormGroup>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} className='p-0'>
                            <div className='grahic_wrapper d-flex align-items-center justify-content-center h-100'>
                                <img src={resetgraphic} alt="resetgraphic" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
export default ResetPassword;