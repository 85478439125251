import mastercard from "../Images/mastercard.png";
import visa from "../Images/visa.svg";
import amex from "../Images/amex.svg";
import { Col, Button } from 'react-bootstrap';
import { React, useState, useEffect } from 'react'

export default function Savedcards(props) {

    const accessToken = localStorage.getItem('accessToken');
    const [cards, SetCards] = useState([]);

    const deleteCard = (id) => {
        fetch(process.env.REACT_APP_API_URL + 'delete-card', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+accessToken
            },
            body: JSON.stringify({"id":id})
        })
            .then(data => data.json())
            .then(response =>{
                if(response.status)
                SetCards(response.saved_cards);
            })
    }

    useEffect(() => {
        SetCards(props.cards);
    }, [props]);

    return (
        <Col lg="6">
            <div className={cards && cards.length?'payment_method':'payment_method d-none'}>
                <h6>Payment Method</h6>
                <label>Change how you pay for your plan</label>
                <div className='card_list'>
                    <ul>
                        {cards && cards.map((card, key) => {
                            let logo = mastercard;
                            let slug = 'mastercard';
                            if(card.brand==='Visa'){
                                logo = visa;
                                slug = 'visa';
                            }else if(card.brand==='American Express'){
                                logo = amex;
                                slug = 'amex';
                            }
                            return (
                                <li key={key} className='d-flex align-items-center justify-content-between'>
                                    <div className='left d-flex align-items-center'>
                                        <div className='card_logo me-2'>
                                            <img src={logo} alt={slug} />
                                        </div>
                                        <div className='card_info'>
                                            <div className='card_name'>{card.brand} ending in {card.last_4_digit}</div>
                                            <div className='expiry_date'>Expiry {card.exp_month}/{card.exp_year}</div>
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <Button className='edit' onClick={()=>deleteCard(card.id)}>Delete</Button>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </Col>
    );
}