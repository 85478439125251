import { Container, Form} from 'react-bootstrap';
import templateone from "../Images/templateone.png";
import templatetwo from "../Images/templatetwo.png";
import Bg1 from "../Images/Bg1.png";
import Bg2 from "../Images/Bg2.png";
import Bg3 from "../Images/Bg3.png";
import Bg4 from "../Images/Bg4.png";
import Bg5 from "../Images/Bg5.png";
import Bg6 from "../Images/Bg6.png";
import Bg7 from "../Images/Bg7.png";
import Bg8 from "../Images/Bg8.png";
import upload from "../Images/upload.svg";
import React from "react";
import { Link } from 'react-router-dom';
import StudioSidebar from './StudioSidebar';
import TestimonialSection from './TestimonialSection';
import StudioHeader from './StudioHeader';

function SocialProof() {
  return (
    <>  
        <Container fluid className="p-0">             
            <div className='edit_wrapper'>
                <StudioHeader/>
                <div className='edit_content_wrapper d-flex'>
                    <div className='edit_sidebar'>
                        <StudioSidebar page="design"/>
                    </div>
                    <div className='edit_testimonial_list'>
                        <div className='style_wrap'>
                            <h5>Style</h5>
                            <ul className='p-0 m-0'>
                                <li>
                                    <Link to="">
                                        <img src={ templateone } alt="templateone"/>
                                    </Link>                            
                                </li>
                                <li>
                                    <Link to="">
                                        <img src={ templatetwo } alt="templatetwo"/>
                                    </Link>                                
                                </li>                            
                            </ul>
                        </div>
                        <div className='logo_wrap'>
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicLogoupload">
                                    <Form.Label>Logo</Form.Label>
                                    <div className='custom_upload'>
                                        <img src={ upload } alt="upload"/>
                                        <label>Upload</label>
                                        <Form.Control type="file" />
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicLogoposition">
                                    <Form.Label>Logo Position</Form.Label>
                                    <Form.Select>
                                        <option>Top Left</option>
                                        <option>Top Right</option>
                                        <option>Bottom Left</option>
                                        <option>Bottom Right</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3 position-relative" controlId="formBasicLogosize">
                                    <Form.Label>Logo Size</Form.Label>
                                    <Form.Range />
                                    <div className='ranecount'>00</div>
                                </Form.Group>
                                <Form.Group className="mb-3 position-relative" controlId="formBasicLogopadding">
                                    <Form.Label>Logo Padding</Form.Label>
                                    <Form.Range />
                                    <div className='ranecount'>00</div>
                                </Form.Group>                                                                
                            </Form>
                        </div>
                        <div className='background_style'>
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicBackgroundstyle">
                                    <Form.Label>Background Style</Form.Label>
                                    <Form.Select>
                                        <option>Image</option>
                                        <option>Solid</option>
                                    </Form.Select>
                                    <div className='images_list'>
                                        <ul>
                                            <li>
                                                <Link to=""><img src={ Bg1 } alt="Bg1"/></Link>
                                            </li>
                                            <li>
                                                <Link to=""><img src={ Bg2 } alt="Bg2"/></Link>
                                            </li>
                                             <li>
                                                <Link to=""><img src={ Bg3 } alt="Bg3"/></Link>
                                            </li>
                                            <li>
                                                <Link to=""><img src={ Bg4 } alt="Bg4"/></Link>
                                            </li>
                                            <li>
                                                <Link to=""><img src={ Bg5 } alt="Bg5"/></Link>
                                            </li>
                                            <li>
                                                <Link to=""><img src={ Bg6 } alt="Bg6"/></Link>
                                            </li>
                                            <li>
                                                <Link to=""><img src={ Bg7 } alt="Bg7"/></Link>
                                            </li>
                                            <li>
                                                <Link to=""><img src={ Bg8 } alt="Bg8"/></Link>
                                            </li>                                                                                                                                                                                                                                                                                                                                                                                                      
                                        </ul>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicUploadbackground">
                                    <Form.Label>Your Uploaded Backgrounds</Form.Label>
                                    <div className='custom_upload'>
                                        <img src={ upload } alt="upload"/>
                                        <label>Upload</label>
                                        <Form.Control type="file" />
                                    </div>
                                </Form.Group>                                                                                                
                            </Form>                           
                        </div>
                    </div>
                    <div className='testimonial_view'>
                       <TestimonialSection/>
                    </div>
                </div>
            </div>
        </Container>
    </>
  );
}


export default SocialProof;

