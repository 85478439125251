import { Col, Row, Container, Card } from 'react-bootstrap';
import Sidebar from './Sidebar';
import Header from './Header';
import copy from "../Images/copy.png";
import { Player } from 'video-react';
import overviews from '../Video/overview.mp4';
import React,{useEffect,useState} from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function GetMoreReviews() {

  const user = JSON.parse(localStorage.getItem('user'));
  const [reviewsAllowed,setReviewsAllowed] = useState();
  const [reviewsAdded,setReviewsAdded] = useState();
  
  useEffect(()=>{
    if(user && user.userplan && user.userplan.no_of_reviews_added>=0 && user.userplan.plan.no_of_reviews_from_get_more_reviews){
      setReviewsAdded(user.userplan.no_of_reviews_added);
      setReviewsAllowed(user.userplan.plan.no_of_reviews_from_get_more_reviews==='unlimited'?1000000:user.userplan.plan.no_of_reviews_from_get_more_reviews);
    }
  },[]);

  return (
    <>  
        <Container fluid className="pe-0">
                <Sidebar />              
                <div className='wrapper'>
                    <Header title="Get More Reviews"/>
                    <div className='inner_content_wrapper'>
                        <Row>
                            <Col>
                                <Card>
                                    <div className='watch_video'>
                                        <h4 className='mb-2'>Watch This Video</h4>
                                        <div className='reviewvideo_box'>
                                            <Player poster='../Images/demo3.png'>
                                                <source src={ overviews } type="video/mp4"/>
                                            </Player> 
                                        </div>
                                    </div>
                                </Card>
                                <Card className={reviewsAdded < reviewsAllowed?'mt-3':'d-none'}>
                                    <div className='watch_video'>
                                        <h4 className='mb-1'>Get More Reviews</h4>
                                        <p>Send this link to your customers to get more reviews</p>
                                        <div className='copy_link'>
                                          <div className='code'>
                                            {process.env.REACT_APP_BASE_URL+'?business_name='+user.business_name}
                                          </div>

                                          <CopyToClipboard text={process.env.REACT_APP_BASE_URL+'?business_name='+user.business_name}
                                            onCopy={() => {
                                              toast.dismiss();
                                              toast.success('Copied to clipboard!')
                                            }}
                                          >
                                            <button><img src={copy} alt="copy" className='me-2' />Copy The Link</button>
                                          </CopyToClipboard>

                                        </div>
                                    </div>
                                </Card>                            
                            </Col>
                        </Row>
                    </div>
                </div>
        </Container>
    </>
  );
}
export default GetMoreReviews;