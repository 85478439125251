import importing from "../Images/importing.png";
import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { forEach } from "jszip";
import all from "../Images/all.png";
export default function ImportReview(props) {
    const navigate = useNavigate();
    const accessToken = localStorage.getItem('accessToken');
    const [file, setFile] = useState(null);
    const [fileKey, setFileKey] = useState(0);
    const [platforms, setPlatforms] = useState([]);
    const [isImportOpen, setIsImportOpen] = useState(false);
    const [isUploaderOpen, setIsUploaderOpen] = useState(false);
    const [isChecked, setIsChecked] = useState([]);
    const importFile = useRef(null);

    function onImportClick() {
        importFile.current.click();
    }
    const handleImport = (flag) => {
        if (flag) {
            setIsImportOpen(false);
            props.setIsImportOpen=false;
        }
    }
    const handleClick = (e) => {
        e.preventDefault();
        let temp_arr = [];
        const { id, value } = e.target;
        temp_arr.push(value);
        setIsChecked(temp_arr);
        if (value === 'twitter') {
            setIsUploaderOpen(true);
        } else {
            toast.info('Sorry, import is currently not working for ' + id + '!');
        }
    }
    async function handleFileChange(e) {
        setFileKey(fileKey+1);
        setIsUploaderOpen(false);
        const files = Array.from(e.target.files);
        if(files.length<=5){
            handleFiles(files);
        }else{
            toast.error('Please select 5 or less files at a time.');
        }
    }

    const handleFiles = (myArray) => {
        props.setIsLoading(true);
        let counter = 0;
        myArray.map((item) => {
            counter++;
            const reader = new FileReader();
            reader.readAsDataURL(item);
            reader.onload = () => {
                uploadVideos(reader.result);
                if(counter===myArray.length){
                    setTimeout(()=>{
                        props.setIsLoading(false);
                        props.getReviews();
                    },1000);
                }
            }
        });
    };

    const uploadVideos = (files) => {
        fetch(process.env.REACT_APP_API_URL + 'import-videos', {
            method: 'POST',
            mode: 'cors',
            headers: {
                authorization: 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "file":files }),
            maxContentLength: 10000000
        })
            .then(data => data.json())
            .then((response) => {
                if(response.status){
                    // do something
                }
            })
    }

    useEffect(() => {
        setIsImportOpen(props.setIsImportOpen);
        setIsUploaderOpen(props.upload);
        setPlatforms(props.platforms)
    },[props]);

    return (
        <>
            <div className='import position-relative'>
                <Link onClick={()=>setIsUploaderOpen(!isUploaderOpen)}>
                    <img src={importing} alt="importing" className="me-2" />
                    Import
                </Link>
                <div className={isImportOpen ? 'custom_dropdown' : 'custom_dropdown d-none'}>
                    <div className="box import">
                        <h6>Choose a Platform</h6>
                        <ul className='m-0'>
                            {
                                platforms.map((platform, index) => {
                                    return (
                                        <li className='position-relative' key={index} onClick={() => {
                                            handleImport(!isUploaderOpen);
                                        }}>
                                            <input
                                                type="checkbox"
                                                name="check"
                                                value={platform.slug}
                                                id={platform.name}
                                                onClick={handleClick}
                                                checked={isChecked.includes(platform.slug)}
                                                onChange={()=> console.log('do norhing') }
                                            />
                                            <div className='box_inner'>
                                                <img src={process.env.REACT_APP_PLATFORM_LOGO_URL + '' + platform.logo} alt={platform.slug} className="me-1" />
                                                {platform.name}
                                            </div>
                                        </li>
                                    );
                                })

                            }
                           
                        </ul>
                    </div>
                </div>
                <div className={isUploaderOpen ? 'custom_dropdown' : 'custom_dropdown d-none'}>

                    <div className="box import p-2">
                     
                        <div>
                            <label><Link to="/integrations">All Integrations</Link></label>
                        </div>
                         
                     
                        <div className='import_wrap'>
                            <input
                                type="file"
                                key={fileKey}
                                name="file"
                                ref={importFile}
                                onChange={handleFileChange}
                                accept="video/*" 
                                multiple
                            />
                            <div className='upload'>

                                <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_414_303)">
                                        <path d="M36.0164 28.0017C34.9357 28.252 33.8382 28.2486 32.7441 28.2016C32.1329 28.1747 31.9978 28.3948 32.0029 28.9643C32.0282 31.9883 32.0181 35.0122 32.013 38.0362C32.013 40.7863 30.4934 42.3 27.748 42.3016C25.6655 42.3016 23.5831 42.3016 21.5007 42.3016C19.3912 42.3016 17.2823 42.3016 15.174 42.3016C12.5383 42.3016 10.9968 40.7628 10.9883 38.1252C10.9883 35.3835 10.9883 32.6418 10.9883 29.8933C10.9883 28.2134 10.9883 28.1932 9.33533 28.2217C7.27372 28.2587 5.3894 27.7413 3.70769 26.5418C-1.69538 22.7047 -1.08922 14.5686 4.8474 11.6034C5.39109 11.3313 5.6089 11.0558 5.61059 10.4274C5.62337 8.1745 6.36529 5.98561 7.72642 4.18511C9.08755 2.38461 10.9955 1.06832 13.1674 0.431304C15.3394 -0.205715 17.6598 -0.129571 19.7848 0.648454C21.9099 1.42648 23.7265 2.86499 24.9654 4.7508C25.3031 5.2548 25.5699 5.35055 26.1693 5.23463C29.8265 4.524 33.1578 6.52486 34.3245 10.0898C34.4512 10.4795 34.5896 10.6811 35.0455 10.7298C39.4811 11.217 42.7432 14.5938 42.9813 18.908C43.2261 23.1146 40.2291 27.029 36.0164 28.0017Z" fill="#F7F9FA" />
                                        <path d="M36.0164 28.0017C34.9357 28.252 33.8382 28.2486 32.7441 28.2016C32.1329 28.1747 31.9978 28.3948 32.0029 28.9643C32.0282 31.9883 32.0181 35.0122 32.013 38.0362C32.013 40.7863 30.4934 42.3 27.748 42.3016C25.6655 42.3016 23.5831 42.3016 21.5007 42.3016C19.3912 42.3016 17.2823 42.3016 15.174 42.3016C12.5383 42.3016 10.9968 40.7628 10.9883 38.1252C10.9883 35.3835 10.9883 32.6418 10.9883 29.8933C10.9883 28.2134 10.9883 28.1932 9.33533 28.2217C7.27372 28.2587 5.3894 27.7413 3.70769 26.5418C-1.69538 22.7047 -1.08922 14.5686 4.8474 11.6034C5.39109 11.3313 5.6089 11.0558 5.61059 10.4274C5.62337 8.1745 6.36529 5.98561 7.72642 4.18511C9.08755 2.38461 10.9955 1.06832 13.1674 0.431304C15.3394 -0.205715 17.6598 -0.129571 19.7848 0.648454C21.9099 1.42648 23.7265 2.86499 24.9654 4.7508C25.3031 5.2548 25.5699 5.35055 26.1693 5.23463C29.8265 4.524 33.1578 6.52486 34.3245 10.0898C34.4512 10.4795 34.5896 10.6811 35.0455 10.7298C39.4811 11.217 42.7432 14.5938 42.9813 18.908C43.2261 23.1146 40.2291 27.029 36.0164 28.0017Z" fill="#402713" />
                                        <path d="M21.4996 16.7309C19.3637 16.7309 17.2261 16.7208 15.0835 16.7309C12.5288 16.746 11.0008 18.2664 10.9839 20.8049C10.9721 22.762 10.9839 24.7192 10.9839 26.6848C10.9839 26.962 11.1088 27.3215 10.5972 27.3568C7.50061 27.5332 4.71803 26.8796 2.66149 24.3681C-0.593865 20.4033 0.821064 14.3806 5.53187 12.2975C6.30687 11.9615 6.52637 11.5751 6.51117 10.7536C6.44755 8.59609 7.11836 6.48051 8.41478 4.74996C9.7112 3.01942 11.5572 1.7754 13.6534 1.21969C17.9049 0.0823419 22.2966 1.90848 24.5439 5.77746C24.8529 6.31002 25.1298 6.43434 25.7478 6.25626C29.2564 5.23483 32.6519 7.17856 33.5603 10.7048C33.7004 11.2491 33.8456 11.5633 34.5244 11.5885C38.5412 11.7414 41.8726 14.9333 42.0988 18.7754C42.3403 22.8611 39.4378 26.5168 35.4345 27.2039C34.5109 27.3719 33.5772 27.3047 32.662 27.3534C32.12 27.382 32.0035 27.1686 32.0103 26.6814C32.0339 24.7226 32.0289 22.7654 32.0103 20.8015C31.9951 18.2647 30.4653 16.746 27.9073 16.7309C25.7748 16.7208 23.6372 16.7309 21.4996 16.7309Z" fill="#FEFEFE" />
                                        <path d="M11.8779 29.4164C11.8779 26.5649 11.8779 23.7129 11.8779 20.8603C11.8779 18.7822 13.0328 17.6196 15.0961 17.6179C19.3735 17.6179 23.6459 17.6179 27.9132 17.6179C29.9393 17.6179 31.1212 18.7939 31.1212 20.783C31.128 26.5991 31.128 32.4152 31.1212 38.2312C31.1212 40.1397 29.9815 41.3829 28.0702 41.4014C23.6802 41.445 19.2953 41.445 14.9154 41.4014C13.0024 41.3812 11.8762 40.138 11.8762 38.2245C11.8728 35.2896 11.8779 32.353 11.8779 29.4164Z" fill="url(#paint0_linear_414_303)" />
                                        <path d="M18.1711 33.5122C18.1711 32.6184 18.1576 31.7247 18.1711 30.8242C18.1812 30.3774 18.0478 30.1522 17.5531 30.169C16.9537 30.1926 16.4387 30.001 16.1804 29.4013C15.922 28.8015 16.1804 28.3295 16.5586 27.8725C17.8283 26.3084 19.0811 24.7309 20.3559 23.1686C21.1343 22.216 21.8468 22.211 22.6337 23.1686C23.9152 24.7242 25.1663 26.3034 26.4378 27.8725C26.8109 28.3311 27.0726 28.8049 26.8193 29.403C26.5661 30.001 26.0511 30.206 25.4517 30.1757C24.8523 30.1455 24.8253 30.4664 24.8287 30.9116C24.8405 32.558 24.8388 34.206 24.8287 35.8541C24.8287 37.1342 24.3913 37.5592 23.0997 37.566C21.9752 37.566 20.8523 37.5794 19.7228 37.566C18.6421 37.5508 18.1795 37.0956 18.1592 36.0288C18.1592 35.1888 18.1727 34.3505 18.1711 33.5122Z" fill="#402713" />
                                        <path d="M19.1067 33.3374C19.1067 32.4974 19.1168 31.6574 19.1067 30.8175C19.0932 29.7624 18.7082 29.3508 17.6664 29.2534C17.4216 29.2316 17.1227 29.3055 17.013 29.0031C16.9252 28.7561 17.1497 28.5982 17.2848 28.4285C18.5258 26.8779 19.7804 25.3391 20.9994 23.7733C21.4047 23.2576 21.6748 23.355 22.0277 23.8136C23.2383 25.3525 24.4776 26.8695 25.7001 28.4C25.847 28.5848 26.1155 28.7528 25.9703 29.0434C25.825 29.334 25.5346 29.2299 25.2949 29.2534C24.3156 29.3492 23.9137 29.7725 23.9069 30.7839C23.8918 32.4638 23.8799 34.1438 23.9069 35.812C23.9188 36.4118 23.7719 36.6705 23.1151 36.6419C22.0209 36.5932 20.9201 36.6067 19.831 36.6419C19.2958 36.6571 19.0661 36.4991 19.0915 35.9347C19.1354 35.0695 19.1067 34.2026 19.1067 33.3374Z" fill="#FEFEFE" />
                                    </g>
                                    <defs>
                                        <linearGradient id="paint0_linear_414_303" x1="21.5006" y1="17.6179" x2="21.5006" y2="41.4341" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#B68865" />
                                            <stop offset="1" stopColor="#A56B3C" />
                                        </linearGradient>
                                        <clipPath id="clip0_414_303">
                                            <rect width="43" height="42.2951" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <label>Upload</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}