import { Col, Row, Container, Card } from 'react-bootstrap';
import Sidebar from './Sidebar';
import Header from './Header';
import menu from "../Images/menu.svg";
import copy from "../Images/copy.png";
import { Player } from 'video-react';
import overviews from '../Video/overview.mp4';
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function Installthewall() {

  const [data, setData] = useState([]);

  useEffect(() => {
    setData('<iframe src="http://localhost:3000/install-the-wall" sandbox></iframe>');
  }, []);

  return (
    <>
      <ToastContainer />
      <Container fluid className="pe-0">
        <Sidebar />
        <div className='wrapper'>
          <Header title="Install The Wall"/>
          <div className='inner_content_wrapper'>
            <Row>
              <Col>
                <Card>
                  <div className='watch_video'>
                    <h4 className='mb-2'>Watch This Video</h4>
                    <div className='reviewvideo_box'>
                      <Player poster='../Images/demo3.png'>
                          <source src={ overviews } type="video/mp4"/>
                      </Player>
                    </div>
                  </div>
                </Card>
                <Card className='mt-3'>
                  <div className='installthewall'>
                    <h4 className='mb-2'>Install The Wall</h4>
                    <div className='copy_link'>
                      <div className='title'>
                        <h4>Try Embed Code </h4>
                        <label>Embed the wall of your website</label>
                      </div>
                      <div className='code'>
                        <div className='code_inner'>
                          {data}
                        </div>
                      </div>
                      <CopyToClipboard text={data}
                        onCopy={() => {
                          toast.dismiss();
                          toast.success('copied!')
                        }}
                      >
                        <button><img src={copy} alt="copy" className='me-2' />Copy The Link</button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
}
export default Installthewall;