import { Col, Row, Container } from 'react-bootstrap';
 
import filter from "../../Images/filter.png";
import unlike from "../../Images/unlike.png";
import like from "../../Images/like.png";
import thump from "../../Images/thump.png";
import twitter from "../../Images/twitter.png";
import google from "../../Images/googlicon.png";
import vimeo from "../../Images/vimeo.png";
import facebook from "../../Images/facebookF.png";
import trash from "../../Images/trash.png";
import all from "../../Images/all.png";
import { Link } from 'react-router-dom';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classNames from 'classnames';
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import VideoPlayer from './../VideoPlayer';
import ImportReview from './../ImportReview';
import Embeded from './../Embeded';
import Loader from './../Loader';
import VimeoPlayer from "./../VimeoPlayer";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

function ProofWall(props) {
    const { classes, droppableId, list } = props;
    const accessToken = localStorage.getItem('accessToken');
    const [posts, setPosts] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [filterPlatforms, setFilterPlatforms] = useState([]);
    const [isActive, setActive] = useState("month");
    const user = JSON.parse(localStorage.getItem('user'));
    const [file, setFile] = useState(null);
    const [shortName, setShortName] = useState('');
    const [openDropdown, setOpenDropdown] = useState(false);
    const [isImportOpen, setIsImportOpen] = useState(false);
    const [isUploaderOpen, setIsUploaderOpen] = useState(false);
    const [openEmbed, setOpenEmbed] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        if (!isCheckAll) {
            setIsCheck(filterPlatforms.map(li => li.id));
            filterByPlatform(filterPlatforms.map(li => li.id));
        } else {
            setIsCheck([]);
            filterByPlatform([]);
        }
    };

    const handleClick = e => {
        const { value, checked } = e.target;
        let temp_arr = []
        if (!checked) {
            temp_arr = [];
            isCheck.map((val, ind) => {
                if (value != val) {
                    temp_arr.push(val);
                }
            })
            setIsCheck(temp_arr);
            filterByPlatform(temp_arr);
        } else {
            temp_arr = [];
            isCheck.map((val, ind) => {
                temp_arr.push(val);
            })
            temp_arr.push(parseInt(value));
            setIsCheck(temp_arr);
            filterByPlatform(temp_arr);
        }
        if (platforms.length != temp_arr.length) {
            setIsCheckAll(false);
        } else {
            setIsCheckAll(true);
        }
    };

    const logo_mark_style = {
        background: '#000000',
        color: 'white',
        padding: '2px',
        fontSize: '9px',
        fontWeight: '700',
        borderRadius: '5px'
    }

    const getReviews = () => {
        fetch(process.env.REACT_APP_API_URL + 'get-all-reviews-proofwall?slug='+props.slug, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            },
        }).then(response => response.json())
            .then(data => {
                setPosts(data.data.reviews);
            });
    }

    const getPlatforms = () => {
        fetch(process.env.REACT_APP_API_URL + 'platforms', {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            },
        }).then(response => response.json())
            .then(data => {
                setPlatforms(data.data);
            });
    }

    const getFilterPlatforms = () => {
        fetch(process.env.REACT_APP_API_URL + 'filter-platforms', {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            },
        }).then(response => response.json())
            .then(data => {
                setFilterPlatforms(data.data);
                setIsCheck(data.data.map(li => li.id));
                setIsCheckAll(true);
            });
    }

    function deleteReview(id) {
        fetch(process.env.REACT_APP_API_URL + 'delete-review', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify({ 'id': id, 'filter_by': isActive, 'platforms': isCheck })
        })
            .then(data => data.json())
            .then(data => {
                if (data.status) {
                    setPosts(data.data.reviews);
                } else {
                    toast.error(data.message);
                }
            });
    }

    function likeReview(id, status) {
        fetch(process.env.REACT_APP_API_URL + 'like-review', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify({ 'id': id, 'status': status, 'filter_by': isActive, 'platforms': isCheck })
        })
            .then(data => data.json())
            .then(data => {
                if (data.status) {
                    setPosts(data.data.reviews);
                } else {
                    toast.error(data.message);
                }
            });
    }

    function filterReviews(filter_by) {
        setActive(filter_by);
        filterAll(filter_by, isCheck);
    }

    const filterByPlatform = (selected_platforms) => {
        filterAll(isActive, selected_platforms);
    }

    function filterAll(filter_by, selected_platforms) {
        fetch(process.env.REACT_APP_API_URL + 'filter-reviews', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify({ 'filter_by': filter_by, 'platforms': selected_platforms })
        })
            .then(data => data.json())
            .then(data => {
                if (data.status) {
                    setPosts(data.data.reviews);
                } else {
                    toast.error(data.message);
                }
            });
    }

    function getUserNameInitials(name_) {
        const slices = name_.split(' ');
        let name_initials = '';
        slices.forEach(item => {
            name_initials = name_initials + '' + item.charAt(0).toUpperCase();
        });
        setShortName(name_initials);
    }

    const viewVideo = (review_id, type) => {
        fetch(process.env.REACT_APP_API_URL + 'view-count-increment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ review_id, type })
        })
            .then(data => data.json())
            .then(data => {
                console.log('video viewed');
            });
    }

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        const itemss = reorder(
            posts,
            result.source.index,
            result.destination.index
        );
        setPosts(itemss);
    }

    useEffect(() => {
        getReviews();
        // getPlatforms();
        // getFilterPlatforms();
        // getUserNameInitials(user.name);
        setIsLoading(false);
    }, []);

    function openClosedropdown(slug) {
        setOpenDropdown(false);
        setIsImportOpen(false);
        setIsUploaderOpen(false);
        setOpenEmbed(false);
        if (slug == 'filter') {
            setOpenDropdown(!openDropdown);
        }
        if (slug == 'import') {
            setIsImportOpen(!isImportOpen);
        }
        if (slug == 'embed') {
            setOpenEmbed(!openEmbed);
        }
    }
    if (isLoading) {
        return <Loader />;
    }

    return (
        <>    
            <Container fluid className="pe-0">
                 {/* className='wrapper'  */}
                <div className='' style={{ marginLeft:110,backgroundColor: 'transparent' }}>
                    
                    
                     
                    <div className='masonry'>
                        <Row>
                            <Col>
                                <div className='combined_view'>

                                        <DragDropContext onDragEnd={onDragEnd} isCombineEnabled>
                                            <Droppable droppableId="droppable">
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        className={classNames('masonry', snapshot.isDraggingOver && 'draggingOver')}
                                                        {...provided.droppableProps}
                                                    >
                                                    <ResponsiveMasonry
                                                        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                                                    >                                                    
                                                        <Masonry gutter={10}>

                                                            {posts.map(function (d, index) {
                                                                return (
                                                                    <Draggable key={d.id} draggableId={`draggable-${d.id}`} index={index}>
                                                                        {(provided, snapshot) => (
                                                                            <div key={d.id}
                                                                                className={classNames('card', snapshot.isDragging && 'dragging')}
                                                                                style={provided.draggableProps.style}
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}

                                                                            style={{paddingTop: 20,paddingLeft:15,paddingBottom:10}}>
                                                                                <div className='box'>
                                                                                    <div className='d-flex justify-content-between'>
                                                                                        <div className='profile d-flex align-items-center'>

                                                                                            <div className='avatar'>
                                                                                                {
                                                                                                    d.platform_name === 'proof_pudding'
                                                                                                        ?
                                                                                                        d.reviewer_picture
                                                                                                            ?
                                                                                                            <img src={d.reviewer_picture} alt="userimage" />
                                                                                                            :
                                                                                                            <div className='profile_name d-flex justify-content-center align-items-center'>
                                                                                                                PP
                                                                                                            </div>
                                                                    
                                                                                                        :

                                                                                                        d.reviewer_picture
                                                                                                            ?
                                                                                                            <img src={d.reviewer_picture} alt="img" />
                                                                                                            :
                                                                                                            <div className='profile_name d-flex justify-content-center align-items-center'>
                                                                                                                {shortName}
                                                                                                            </div>
                                                                                                }
                                                                                            </div>

                                                                                            <div>
                                                                                                <div className='name'>{d.reviewer_name}</div>
                                                                                                {
                                                                                                    d.platform_name === 'google' || d.platform_name === 'facebook'
                                                                                                    ?
                                                                                                    <></>
                                                                                                    :
                                                                                                    <div className='email'>{d.reviewer_handle?'@'+d.reviewer_handle:""}</div>
                                                                                                }
                                                                                                
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                    <p className='mt-3'>{d.text}</p>
                                                                                    {d.files && d.media[0]?
                                                                                        <div className='upload_post_image mt-3' onClick={() => {
                                                                                            viewVideo(d.media[0].id, d.media[0].type)
                                                                                        }}>
                                                                                            {
                                                                                                d.platform_name === 'proof_pudding' && d.media[0] && d.media[0].file_url
                                                                                                    ?
                                                                                                    <VideoPlayer src={d.media[0].file_url} id={d.media[0].id} />
                                                                                                    :
                                                                                                    d.media[0].type === 'photo'
                                                                                                        ?
                                                                                                        <img src={d.media[0].file_url} alt="image" className='w-100' />
                                                                                                        :
                                                                                                        d.platform_name === 'vimeo'
                                                                                                        ?
                                                                                                        <VimeoPlayer key={d.review_id} videoId={d.review_id} />
                                                                                                        :
                                                                                                        <VideoPlayer src={d.media[0].file_url} id={d.media[0].id} />
                                                                                            }
                                                                                        </div>
                                                                                        : ''}
                                                                                    <p className='mt-3'></p>
                                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                                        <div className='d-flex align-items-center'>
                                                                                            <div className='like_count d-flex align-items-center'>
                                                                                                <img src={thump} alt="thump" />
                                                                                                <span>{d.like_count}</span>
                                                                                            </div>

                                                                                            {
                                                                                                d.platform_name === 'proof_pudding'
                                                                                                    ?
                                                                                                    <div className='date'>{d.review_date_2} <span style={logo_mark_style}>PP</span></div>
                                                                                                    :
                                                                                                    d.platform_name === 'twitter'
                                                                                                    ?
                                                                                                    <div className='date'>{d.review_date_2}  <img src={twitter} alt="twitter" /></div>
                                                                                                    :
                                                                                                    d.platform_name === 'facebook'
                                                                                                    ?
                                                                                                    <div className='date'>{d.review_date_2}  <img src={facebook} alt="facebook" /></div>
                                                                                                    :
                                                                                                    d.platform_name === 'vimeo'
                                                                                                    ?
                                                                                                    <div className='date'>{d.review_date_2}  <img src={vimeo} alt="vimeo" /></div>
                                                                                                    :
                                                                                                    <div className='date'>{d.review_date_2}  <img src={google} alt="google" /></div>
                                                                                            }

                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                )

                                                                // endpostcard
                                                            })}
                                                            {provided.placeholder}
                                                        </Masonry>
                                                    </ResponsiveMasonry>   
                                                                                                         
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        </>
    );
}
export default ProofWall;