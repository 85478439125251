import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import { Col, Row, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

async function stripeTokenHandler(token, access_token, plan_id,saveCard) {
    const paymentData = { 
        'token': token.id, 
        'plan_id': plan_id,
        'save_card' : saveCard 
    };

    return fetch(process.env.REACT_APP_API_URL + 'charge', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
        body: JSON.stringify(paymentData),
    }).then(data => data.json());
}

export default function CheckoutForm(props) {

    const accessToken = localStorage.getItem('accessToken');
    const stripe = useStripe();
    const elements = useElements();
    const [saveCard,setSaveCard] = useState(true);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);

        if (result.error) {
            console.log(result.error.message);
            toast.error(result.error.message);
        } else {
            const result_ = await stripeTokenHandler(result.token, accessToken, props.plan_id,saveCard);
            if(result_.status){
                navigate('/settings');
            }else{
                toast.error(result_.message);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <CardSection />
            <button disabled={!stripe}>Pay</button>
        </form >
    );
}