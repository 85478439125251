import { Col, Row, Container, Card, Button } from 'react-bootstrap';
import Sidebar from './Sidebar';
import Header from './Header';
import { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PaymentModal from "./PaymentModal";
import React from "react";
import plancheck from "../Images/plancheck.svg";
import professional from "../Images/professional.svg";
import next from "../Images/next.svg";
import free from "../Images/free.svg";
import team from "../Images/team.svg";

function Plans() {

    const accessToken = localStorage.getItem('accessToken');
    const [plans, setPlans] = useState([]);
    const [Userplan, setUserplan] = useState([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [choosenPlan, setChoosenPlan] = React.useState(null);
    const [savedCards, setSavedCards] = React.useState([]);

    const getUser = () => {
        fetch(process.env.REACT_APP_API_URL + 'plans', {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            },
        }).then(response => response.json())
            .then(data => {
                setPlans(data.plans);
                setUserplan(data.userplan);
                setSavedCards(data.saved_cards);
            });
    }

    useEffect(() => {
        getUser();
    }, []);

    return (
        <>
            <ToastContainer />
            <Container fluid className="pe-0">
                <Sidebar />
                <div className='wrapper'>
                    <Header title="Upgrade Plan" />
                    <div className='inner_content_wrapper'>
                        <Row>
                            <Col>
                                <Card>
                                    <div className='plans_wrapper'>
                                        <div className='header'>
                                            <h3>Choose your plan</h3>
                                            <p>Choose a Plan that works best for you and your team.</p>
                                        </div>
                                        <div className='plans d-flex flex-wrap justify-content-between'>

                                            {plans.map((value, index) => (
                                                <div className='plan_box' key={index}>
                                                    <div className='icon'>
                                                        {
                                                            value.type === 'free' ?
                                                                <img src={free} alt="professional" />
                                                                :
                                                                value.price === 47 ?
                                                                    <img src={professional} alt="professional" /> :
                                                                    <img src={team} alt="team" />
                                                        }
                                                    </div>
                                                    <div className='title'>{value.name}</div>
                                                    
                                                    <div className='amonut'>${value.price ? value.price : 0}</div>
                                                    <p style={{textAlign: "center"}}>Monthly</p>
                                                    <ul className='p-0 m-0'>
                                                        <li style={{height: "576px"}}  >
                                                        {/* <img src={plancheck} alt="plancheck" />*/}
                                                        <div dangerouslySetInnerHTML={{ __html: value.description }} /></li>
                                                    </ul>

                                                    <button 
                                                        className={Userplan.plan_id == value.id ? 'buy_btn opacity-50' : value.type==='free' ? 'buy_btn opacity-50' : 'buy_btn'} 
                                                        disabled={Userplan.plan_id == value.id ? true : value.type==='free' ? true :false} 
                                                        onClick={() => {
                                                            setModalShow(true);
                                                            setChoosenPlan(value.id);
                                                        }}>{value.type==='free' ? 'Default' : Userplan.plan_id == value.id?'Active Plan':'Choose Plan'} 
                                                        <img src={next} alt="next" className='ms-2' />
                                                    </button>
                                                </div>
                                            ))}

                                            <PaymentModal
                                                show={modalShow}
                                                onHide={() => setModalShow(false)}
                                                plan_id={choosenPlan}
                                                cards={savedCards}
                                            />

                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default Plans;