import React, { useEffect, useState } from "react";
import TemplateOne from './Templateone';
import TemplateTwo from './Templatetwo';
import TemplateThree from './Templatethree';
import TemplateFour from './Templatefour';
import TemplateFive from './Templatefive';
import TemplateEight from './Templateeight';

function Template(props) {
  const accessToken = localStorage.getItem('accessToken');
  const [data, setData] = useState([]);
  const template = props.title;

  function getSingleTestimonial(data, accessToken) {
    return fetch(process.env.REACT_APP_API_URL + 'get-single-testimonial', {
      method: 'post',
      headers: {
        authorization: 'Bearer ' + accessToken,
        Accept: 'application/json',
      },
      body: new URLSearchParams(data),
    })
      .then(response => response.json())
      .then(data => {
        setData(data.data);
      });
  }

  useEffect(() => {
    const response = getSingleTestimonial({ 'testimonial_id': props.seltestimonial }, accessToken);
  }, [props.seltestimonial]);

  if (template == 'TemplateOne') {
    return (
      <TemplateOne 
        data={data} 
        rating={props.rating} 
        avtar={props.avtar} 
        BgImage={props.BgImage} 
        color={props.color} 
        sizevalue={props.sizevalue} 
        paddingvalue={props.paddingvalue} 
        logoposition={props.logoposition} 
        logoimg={props.logoimg} 

        nameTextcolor={props.nameTextcolor}
        nameFontFamily={props.nameFontFamily}
        nameFontSize={props.nameFontSize}
        nameFontWeight={props.nameFontWeight}
        nameLineHeight={props.nameLineHeight}
        nameLetterSpacing={props.nameLetterSpacing}
        nameWordSpacing={props.nameWordSpacing}
        namePaddingTop={props.namePaddingTop}
        namePaddingRight={props.namePaddingRight}
        namePaddingBottom={props.namePaddingBottom}
        namePaddingLeft={props.namePaddingLeft}

        reviewTextcolor={props.reviewTextcolor}
        reviewFontFamily={props.reviewFontFamily}
        reviewFontSize={props.reviewFontSize}
        reviewFontWeight={props.reviewFontWeight}
        reviewLineHeight={props.reviewLineHeight}
        reviewLetterSpacing={props.reviewLetterSpacing}
        reviewWordSpacing={props.reviewWordSpacing}
        reviewPaddingTop={props.reviewPaddingTop}
        reviewPaddingRight={props.reviewPaddingRight}
        reviewPaddingBottom={props.reviewPaddingBottom}
        reviewPaddingLeft={props.reviewPaddingLeft}

        profileImg={props.profileImg}
      />
    );
  } else if (template == 'TemplateTwo') {
    return (
      <TemplateTwo 
        data={data} 
        rating={props.rating} 
        avtar={props.avtar} 
        BgImage={props.BgImage} 
        color={props.color} 
        sizevalue={props.sizevalue} 
        paddingvalue={props.paddingvalue} 
        logoposition={props.logoposition} 
        logoimg={props.logoimg} 

        nameTextcolor={props.nameTextcolor}
        nameFontFamily={props.nameFontFamily}
        nameFontSize={props.nameFontSize}
        nameFontWeight={props.nameFontWeight}
        nameLineHeight={props.nameLineHeight}
        nameLetterSpacing={props.nameLetterSpacing}
        nameWordSpacing={props.nameWordSpacing}
        namePaddingTop={props.namePaddingTop}
        namePaddingRight={props.namePaddingRight}
        namePaddingBottom={props.namePaddingBottom}
        namePaddingLeft={props.namePaddingLeft}

        reviewTextcolor={props.reviewTextcolor}
        reviewFontFamily={props.reviewFontFamily}
        reviewFontSize={props.reviewFontSize}
        reviewFontWeight={props.reviewFontWeight}
        reviewLineHeight={props.reviewLineHeight}
        reviewLetterSpacing={props.reviewLetterSpacing}
        reviewWordSpacing={props.reviewWordSpacing}
        reviewPaddingTop={props.reviewPaddingTop}
        reviewPaddingRight={props.reviewPaddingRight}
        reviewPaddingBottom={props.reviewPaddingBottom}
        reviewPaddingLeft={props.reviewPaddingLeft}

        profileImg={props.profileImg}
      />
    );
  }else if (template == 'TemplateThree') {
    return (
      <TemplateThree 
        data={data} 
        rating={props.rating} 
        avtar={props.avtar} 
        BgImage={props.BgImage} 
        color={props.color} 
        sizevalue={props.sizevalue} 
        paddingvalue={props.paddingvalue} 
        logoposition={props.logoposition} 
        logoimg={props.logoimg} 

        nameTextcolor={props.nameTextcolor}
        nameFontFamily={props.nameFontFamily}
        nameFontSize={props.nameFontSize}
        nameFontWeight={props.nameFontWeight}
        nameLineHeight={props.nameLineHeight}
        nameLetterSpacing={props.nameLetterSpacing}
        nameWordSpacing={props.nameWordSpacing}
        namePaddingTop={props.namePaddingTop}
        namePaddingRight={props.namePaddingRight}
        namePaddingBottom={props.namePaddingBottom}
        namePaddingLeft={props.namePaddingLeft}

        reviewTextcolor={props.reviewTextcolor}
        reviewFontFamily={props.reviewFontFamily}
        reviewFontSize={props.reviewFontSize}
        reviewFontWeight={props.reviewFontWeight}
        reviewLineHeight={props.reviewLineHeight}
        reviewLetterSpacing={props.reviewLetterSpacing}
        reviewWordSpacing={props.reviewWordSpacing}
        reviewPaddingTop={props.reviewPaddingTop}
        reviewPaddingRight={props.reviewPaddingRight}
        reviewPaddingBottom={props.reviewPaddingBottom}
        reviewPaddingLeft={props.reviewPaddingLeft}

        profileImg={props.profileImg}
      />
    );
  }else if (template == 'TemplateFour') {
    return (
      <TemplateFour 
        data={data} 
        rating={props.rating} 
        avtar={props.avtar} 
        BgImage={props.BgImage} 
        color={props.color} 
        sizevalue={props.sizevalue} 
        paddingvalue={props.paddingvalue} 
        logoposition={props.logoposition} 
        logoimg={props.logoimg} 

        nameTextcolor={props.nameTextcolor}
        nameFontFamily={props.nameFontFamily}
        nameFontSize={props.nameFontSize}
        nameFontWeight={props.nameFontWeight}
        nameLineHeight={props.nameLineHeight}
        nameLetterSpacing={props.nameLetterSpacing}
        nameWordSpacing={props.nameWordSpacing}
        namePaddingTop={props.namePaddingTop}
        namePaddingRight={props.namePaddingRight}
        namePaddingBottom={props.namePaddingBottom}
        namePaddingLeft={props.namePaddingLeft}

        reviewTextcolor={props.reviewTextcolor}
        reviewFontFamily={props.reviewFontFamily}
        reviewFontSize={props.reviewFontSize}
        reviewFontWeight={props.reviewFontWeight}
        reviewLineHeight={props.reviewLineHeight}
        reviewLetterSpacing={props.reviewLetterSpacing}
        reviewWordSpacing={props.reviewWordSpacing}
        reviewPaddingTop={props.reviewPaddingTop}
        reviewPaddingRight={props.reviewPaddingRight}
        reviewPaddingBottom={props.reviewPaddingBottom}
        reviewPaddingLeft={props.reviewPaddingLeft}

        profileImg={props.profileImg}
      />
    );
  }else if (template == 'TemplateFive') {
    return (
      <TemplateFive 
        data={data} 
        rating={props.rating} 
        avtar={props.avtar} 
        BgImage={props.BgImage} 
        color={props.color} 
        sizevalue={props.sizevalue} 
        paddingvalue={props.paddingvalue} 
        logoposition={props.logoposition} 
        logoimg={props.logoimg} 

        nameTextcolor={props.nameTextcolor}
        nameFontFamily={props.nameFontFamily}
        nameFontSize={props.nameFontSize}
        nameFontWeight={props.nameFontWeight}
        nameLineHeight={props.nameLineHeight}
        nameLetterSpacing={props.nameLetterSpacing}
        nameWordSpacing={props.nameWordSpacing}
        namePaddingTop={props.namePaddingTop}
        namePaddingRight={props.namePaddingRight}
        namePaddingBottom={props.namePaddingBottom}
        namePaddingLeft={props.namePaddingLeft}

        reviewTextcolor={props.reviewTextcolor}
        reviewFontFamily={props.reviewFontFamily}
        reviewFontSize={props.reviewFontSize}
        reviewFontWeight={props.reviewFontWeight}
        reviewLineHeight={props.reviewLineHeight}
        reviewLetterSpacing={props.reviewLetterSpacing}
        reviewWordSpacing={props.reviewWordSpacing}
        reviewPaddingTop={props.reviewPaddingTop}
        reviewPaddingRight={props.reviewPaddingRight}
        reviewPaddingBottom={props.reviewPaddingBottom}
        reviewPaddingLeft={props.reviewPaddingLeft}

        profileImg={props.profileImg}
      />
    );
  }
  else if (template == 'TemplateEight') {
    return (
      <TemplateEight 
        data={data} 
        rating={props.rating} 
        avtar={props.avtar} 
        BgImage={props.BgImage} 
        color={props.color} 
        sizevalue={props.sizevalue} 
        paddingvalue={props.paddingvalue} 
        logoposition={props.logoposition} 
        logoimg={props.logoimg} 

        nameTextcolor={props.nameTextcolor}
        nameFontFamily={props.nameFontFamily}
        nameFontSize={props.nameFontSize}
        nameFontWeight={props.nameFontWeight}
        nameLineHeight={props.nameLineHeight}
        nameLetterSpacing={props.nameLetterSpacing}
        nameWordSpacing={props.nameWordSpacing}
        namePaddingTop={props.namePaddingTop}
        namePaddingRight={props.namePaddingRight}
        namePaddingBottom={props.namePaddingBottom}
        namePaddingLeft={props.namePaddingLeft}

        reviewTextcolor={props.reviewTextcolor}
        reviewFontFamily={props.reviewFontFamily}
        reviewFontSize={props.reviewFontSize}
        reviewFontWeight={props.reviewFontWeight}
        reviewLineHeight={props.reviewLineHeight}
        reviewLetterSpacing={props.reviewLetterSpacing}
        reviewWordSpacing={props.reviewWordSpacing}
        reviewPaddingTop={props.reviewPaddingTop}
        reviewPaddingRight={props.reviewPaddingRight}
        reviewPaddingBottom={props.reviewPaddingBottom}
        reviewPaddingLeft={props.reviewPaddingLeft}

        profileImg={props.profileImg}
      />
    );
  }
}
export default Template;