import { Container, Form } from 'react-bootstrap';
import React from "react";
import WidgetSection from './WidgetSection';
import WidgetHeader from './WidgetHeader';

export default function WidgetSettings() {

    return (
        <>
            <Container fluid className="p-0">
                <div className='edit_wrapper'>
                    <WidgetHeader/>
                    <div className='edit_content_wrapper d-flex'>
                        <div className='edit_sidebar'>
                            {/* sidebar will be added here */}
                        </div>
                        <div className='edit_testimonial_list'>
                            <div className='swtich_wrapper'>
                                
                                <Form className='custom_swtich'>
                                    <Form.Group className="mt-3" controlId="formBasicSwitch">
                                        <Form.Check type="switch" id="custom-switch" label="Show dates and likes" checked />
                                    </Form.Group>
                                </Form>
                                <Form className='custom_swtich'>
                                    <Form.Group className="mt-3" controlId="formBasicSwitch">
                                        <Form.Check type="switch" id="custom-switch" label="Show avatars" checked />
                                    </Form.Group>
                                </Form>
                                <Form className='custom_swtich'>
                                    <Form.Group className="mt-3" controlId="formBasicSwitch">
                                        <Form.Check type="switch" id="custom-switch" label="Show rating" checked />
                                    </Form.Group>
                                </Form>
                                
                            </div>
                        </div>
                        <WidgetSection/>
                    </div>
                </div>
            </Container>
        </>
    );
}

