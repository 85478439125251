import { Container, Form } from 'react-bootstrap';
import React from "react";
import StudioSidebar from './StudioSidebar';
import TestimonialSection from './TestimonialSection';
import StudioHeader from './StudioHeader';

function SocialProof() {

    return (
        <>
            <Container fluid className="p-0">
                <div className='edit_wrapper'>
                    <StudioHeader/>
                    <div className='edit_content_wrapper d-flex'>
                        <div className='edit_sidebar'>
                            <StudioSidebar page="settings"/>
                        </div>
                        <div className='edit_testimonial_list'>
                            <div className='swtich_wrapper'>
                                <Form className='custom_swtich'>
                                    <Form.Group className="mt-3" controlId="formBasicSwitch">
                                        <Form.Check type="switch" id="custom-switch" label="Check this switch" checked />
                                    </Form.Group>
                                </Form>
                                <Form className='custom_swtich'>
                                    <Form.Group className="mt-3" controlId="formBasicSwitch">
                                        <Form.Check type="switch" id="custom-switch" label="Show Rating" checked />
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                        <div className='testimonial_view'>
                            <TestimonialSection/>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
}
export default SocialProof;

