import { Col, Row, Container, Card, ProgressBar } from 'react-bootstrap';
import Sidebar from './Sidebar';
import Header from './Header';
import startwo from "../Images/startwo.png";
import time from "../Images/time.png";
import watchtime from "../Images/watchtime.png";
import Chart from "./Chart"
import covervid from '../Video/video.mp4';
import 'react-circular-progressbar/dist/styles.css';
import { Player } from 'video-react';
import React, { useState, useEffect } from 'react';

function Dashboard() {

  const accessToken = localStorage.getItem('accessToken');
  const user = JSON.parse(localStorage.getItem('user'));
  const [userPlan, setUserPlan] = useState();
  const [showPlatformDiversity, setShowPlatformDiversity] = useState(false);
  const [showMostViewedVideos, setShowMostViewedVideos] = useState(false);
  const [showAverageWatchTime,setShowAverageWatchTime] = useState(false);

  const [currentMonthViews, setCurrentMonthViews] = useState(0);
  const [platformsViews, setPlatformsViews] = useState([]);
  const [activities, setActivities] = useState([]);
  const [reviewscart, setMonthlyreviews] = useState([]);
  const [mostViewedVideos, setMostViewedVideos] = useState([]);
  const [isActive, setisActive] = useState('month');
  const [totalPlayTime, setTotalPlayTime] = useState(0);
  const [averageWatchTime, setAverageWatchTime] = useState(0);
  const [viewsPercentage, setViewsPercentage] = useState(0);
  const [totalPlayTimePercentage, setTotalPlayTimePercentage] = useState(0);
  const [averageWatchTimePercentage, setAverageWatchTimePercentage] = useState(0);

  const getDashboardData = () => {
    fetch(process.env.REACT_APP_API_URL + 'user-dashboard', {
      method: 'GET',
      headers: {
        authorization: 'Bearer ' + accessToken,
        Accept: 'application/json',
      },
    }).then(response => response.json())
      .then(data => {
        setCurrentMonthViews(data.data.current_month_views);
        setPlatformsViews(data.data.platforms_views);
        setActivities(data.data.activities);
        setMonthlyreviews(data.data.monthly_reviews);
        setTotalPlayTime(data.data.total_play_time);
        setAverageWatchTime(data.data.average_watch_time);
        setViewsPercentage(data.data.views_percentage);
        setTotalPlayTimePercentage(data.data.total_play_time_percentage);
        setAverageWatchTimePercentage(data.data.average_watch_time_percentage);
      });
  }

  const getMostViewedVideos = () => {
    fetch(process.env.REACT_APP_API_URL + 'most-viewed-videos', {
      method: 'GET',
      headers: {
        authorization: 'Bearer ' + accessToken,
        Accept: 'application/json',
      },
    }).then(response => response.json())
      .then(data => {
        if(data.status){
          setMostViewedVideos(data.data);
        }else{
          console.log('error--');
          console.log(data.message);
        }
      });
  }

  function millisToMinutesAndSeconds(millis){
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }

  useEffect(() => {
    getDashboardData();
    getMostViewedVideos();
    setUserPlan(user.userplan.plan);

    if(user.userplan.plan.analytic_access_except_for.includes('platform_diversity')){
      setShowPlatformDiversity(false);
    }else{
      setShowPlatformDiversity(true);
    }
    if(user.userplan.plan.analytic_access_except_for.includes('most_viewed_videos')){
      setShowMostViewedVideos(false);
    }else{
      setShowMostViewedVideos(true);
    }
    if(user.userplan.plan.analytic_access_except_for.includes('average_watch_time')){
      setShowAverageWatchTime(false);
    }else{
      setShowAverageWatchTime(true);
    }
    
  }, []);

  function DayWiseData() {
    setisActive('day');
    fetch(process.env.REACT_APP_API_URL + 'day-wise-reviews', {
      method: 'GET',
      headers: {
        authorization: 'Bearer ' + accessToken,
        Accept: 'application/json',
      },
    }).then(response => response.json())
      .then(data => {
        setMonthlyreviews(data.data.reviews);
      });
  }

  function yearWiseData() {
    setisActive('year');
    fetch(process.env.REACT_APP_API_URL + 'year-wise-reviews', {
      method: 'GET',
      headers: {
        authorization: 'Bearer ' + accessToken,
        Accept: 'application/json',
      },
    }).then(response => response.json())
      .then(data => {
        setMonthlyreviews(data.data.reviews);
      });
  }

  function monthWiseData() {
    setisActive('month');
    getDashboardData();
  }
 
  
  return (
    <>
      <Container fluid className="pe-0">
        <Sidebar />
        <div className='wrapper'>
          <Header title="Dashboard" />
          <Row>
            <Col lg="9" md="12">
              <div className='inner_content_wrapper'>
                <Row>
                  <Col md="4" className='pe-md-0'>
                    <Card>
                      <div className='box_wrapper'>
                        <h4>View Count</h4>
                        <span>{currentMonthViews}</span>
                        <ProgressBar now={viewsPercentage}></ProgressBar>
                        <div className='d-flex align-items-center justify-content-between'>
                          <label>Last Month</label>
                          <label>{viewsPercentage}%</label>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col md="4" className='pe-md-0'>
                    <Card className='my-3 my-md-0'>
                      <div className='box_wrapper'>
                        <h4>Total Play Time</h4>
                        <span>{totalPlayTime}</span>
                        <ProgressBar variant="warning" now={totalPlayTimePercentage}></ProgressBar>
                        <div className='d-flex align-items-center justify-content-between'>
                          <label>Last Month</label>
                          <label>{totalPlayTimePercentage}%</label>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className={showAverageWatchTime?'':'d-none'}>
                      <div className='box_wrapper'>
                        <h4>Average Watch Time</h4>
                        <span>{averageWatchTime}</span>
                        <ProgressBar variant="danger" now={averageWatchTimePercentage}></ProgressBar>
                        <div className='d-flex align-items-center justify-content-between'>
                          <label>Last Month</label>
                          <label>{averageWatchTimePercentage}%</label>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className='chart_wrapper'>
                      <div className='total_view'>
                        <Chart data={reviewscart} isactive={isActive} dayFunction={DayWiseData} yearFunction={yearWiseData} monthFunction={monthWiseData} />
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className={showMostViewedVideos && showPlatformDiversity?'':'d-none'}>
                      <Row>
                        <Col md="6" className={showMostViewedVideos?'pe-2':'d-none'}>
                          <div className='top_video_review'>
                            <h4 className='mb-2'>Top Video/ Review</h4>
                            <div className='inner_wrapper d-flex flex-wrap w-100'>
                              <div className='most_watched pe-1'>
                                <h5>Most Watched</h5>
                                {mostViewedVideos.map(function (video, index) {
                                  return (
                                    <div className='video_box' key={index}>
                                      <Player>
                                        <source src={video.media[0].file_url} type="video/mp4"
                                        />
                                      </Player>
                                    </div>
                                  );
                                })}

                              </div>
                              <div className='total_watch_time ps-1'>
                                <h5>Total Watch Time</h5>

                                {mostViewedVideos.map(function (video, index) {
                                  return (
                                    <div className='time_box' key={index}>
                                      <img src={watchtime} alt="watchtime" className="me-1" />{millisToMinutesAndSeconds(video.media[0].duration_ms)}
                                    </div>
                                  );
                                })}
                                
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md="6" className={showPlatformDiversity?'ps-2':'d-none'}>
                          <div className='platform_diversity'>
                            <h4 className='mb-2'>Platform Diversity</h4>
                            <div className='inner_wrapper d-flex flex-wrap w-100'>
                              <div className='platforms pe-1'>
                                <h5>Platform</h5>

                                {platformsViews.map(function (platform, index) {
                                  return (
                                    <div className='platform_box' key={index}>
                                      <img src={process.env.REACT_APP_PLATFORM_LOGO_URL + '' + platform.platform_logo} alt={platform.platform} className="me-1" />{platform.platform}
                                    </div>
                                  );
                                })}

                              </div>
                              <div className='total_reviews ps-1'>
                                <h5>Total Reviews</h5>

                                {platformsViews.map(function (platform, index) {
                                  return (
                                    <div className='review_box' key={index}>
                                      <div className='count'>{platform.reviews}</div>Reviews
                                    </div>
                                  );
                                })}

                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

              </div>
            </Col>
            <Col lg="3" md="12" className='ps-lg-0'>
              <Card>
                <div className='recent_activities'>
                  <h4 className='mb-2'>Recent Activity</h4>

                  {activities.map(function (activity, index) {
                    return (
                      <div className='activity_box' key={index}>
                        <label className='d-flex'>
                          <div className='img_wrap'>
                            <img src={startwo} alt="startwo" />
                          </div>
                          {activity.title}
                        </label>
                        <div className='time'><img src={time} alt="time" />{activity.activity_time}</div>
                      </div>
                    );
                  })}

                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}
export default Dashboard;