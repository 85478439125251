import { Col, Row, Container, Form, Button, FormGroup, InputGroup } from 'react-bootstrap';
import Sidebarlogo from "../Images/sidebarlogo.png";
import hand from "../Images/hand.png";
import signingraphic from "../Images/signingraphic.png"
import React, { useState } from 'react';
import showPwdImg from '../Images/show-password.svg';
import hidePwdImg from '../Images/hide-password.svg';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

async function loginUser(credentials) {
    return fetch(process.env.REACT_APP_API_URL + 'login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}
function Login() {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    
    const [email, setEmail] = useState();
    const [password, setPassword] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [validated, setValidated] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            const response = await loginUser({
                email,
                password,
            });
            console.log(response);
            if (response['status']) {
                toast.success(response.message)
                setTimeout(() => {
                    localStorage.setItem('accessToken', response['data']['access_token']);
                    localStorage.setItem('user', JSON.stringify(response['data']['user']));
                    navigate('/dashboard');
                }, 1000);
            } else {
                toast.error(response.message);
            }
        }
    };

    if(user && user.userplan && user.userplan.plan && user.userplan.plan.no_of_business_profile){
        window.location.href = '/dashboard';
    }else{
        return (
            <>
                <div className='form_header'>
                    <ToastContainer />
                </div>
                <div className='content_wrapper outer_form'>
                    <Container fluid className='h-100'>
                        <Row className='h-100'>
                            <Col lg={7} md={12} className='p-0'>
                                <div className='form_wrapper d-flex align-items-center justify-content-center h-100'>
                                    <div className='inner_wrapper'>
                                        <div className='logo'>
                                            <img src={Sidebarlogo} alt="Sidebarlogo" />
                                        </div>                                
                                        <h2 className='text-center'>Welcome Back!</h2>
                                        <p className='text-center'>Thank you for returning, please fill out your log in <br/>information below or sign up for a new account.</p>
                                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Email</Form.Label>
    
    
                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Enter your Email"
                                                        aria-describedby="inputGroupPrepend"
                                                        required
                                                        onChange={e => setEmail(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Email is required
                                                    </Form.Control.Feedback>
                                                </InputGroup>
    
                                            </Form.Group>
                                            <Form.Group className="mb-2 position-relative" controlId="formBasicPassword">
                                                <Form.Label>Password</Form.Label>
    
                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                        type={isRevealPwd ? "text" : "password"}
                                                        placeholder="Enter your Password"
                                                        aria-describedby="inputGroupPrepend"
                                                        required
                                                        value={password}
                                                        onChange={e => setPassword(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Password is required
                                                    </Form.Control.Feedback>
                                                </InputGroup>
    
                                                <img
                                                    title={isRevealPwd ? "Hide password" : "Show password"}
                                                    src={isRevealPwd ? hidePwdImg : showPwdImg}
                                                    onClick={() => setIsRevealPwd(prevState => !prevState)}
                                                    alt="hide-show-pass"
                                                />
                                            </Form.Group>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-0" controlId="formBasicCheckbox">
                                                        <Form.Check type="checkbox" label="Remember me" />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <div className='forgot_password d-flex justify-content-end align-items-center h-100'>
                                                        {/* <a href="Forgotpassword.jsx">Forgot password?</a> */}
                                                        <Link to="/forgot-password">Forgot password?</Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Button variant="primary" type="submit" className='m-auto mt-md-3 mt-lg-5 mb-2 mt-3'>
                                                Sign in
                                            </Button>
                                            <FormGroup>
                                                <div className='link text-center'>
                                                    Don't have an account?
                                                    <Link className='ms-1' to="/register">Sign up</Link>
                                                </div>
                                            </FormGroup>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={5} md={12} className='p-0 d-none d-md-block'>
                                <div className='grahic_wrapper d-flex align-items-center justify-content-center h-100'>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }

}
export default Login;