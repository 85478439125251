import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

export default function SelectGoogleBusinessName(props) {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const email = user.email;
  const [placeId, setPlaceId]=useState('');
  const [placeName, setPlaceName]=useState('');
  const [newPlaceId, setNewPlaceId]=useState('newPlaceId'); 
  const accessToken = localStorage.getItem('accessToken');

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      // props.saveGoogleReviwes();
    }
  };
   const saveGoogleReviwes = () => {
        fetch(process.env.REACT_APP_API_URL + 'get-google-reviews', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ place_id: placeId,email:email,newPlaceId:newPlaceId,place_name:placeName })
        }).then(response => response.json())
            .then(data => {
                
                if(data.code==2){
 
                    setName(data.data);
                    // console.log(data.data);
                    return false;
                }
                if (data.status) { 
                    toast.success(data.message);
                    setTimeout(() => {
                        navigate('/proof-wall/combined');
                    }, 500);
                } else { 
                    toast.error(data.message);
                }
            });
    }
  
  return (
    <>
      <Modal {...props} size="lg" onKeyDown={handleKeyDown} aria-labelledby="contained-modal-title-vcenter" centered className='add_tile'>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Select your Business Name
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>  
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              
               
              
                  <div style={{marginTop:'15px'}}>
                  {props.name.map(function (platform) {
                    return (<> <input key={platform.place_id} type="radio" style={{height:'14px'}} onChange={(e)=>{setPlaceId(e.target.value); setPlaceName(platform.name)}}  required value={platform.place_id} name="place_id" /> {platform.name}<br></br><br></br></>)
                    }) }
                  </div>
                 
             
            </Form.Group>
          </Form>
          <Button onClick={saveGoogleReviwes}>Submit</Button>
        </Modal.Body>
      </Modal>
    </>
  );
}