import testimonialvector from "../Images/testimonialvector.png";
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import Modaltestimonial from "./Modaltestimonial";

export default function TestimonialSection(props) {
    const [modalShow, setModalShow] = React.useState(false);
    const [testimonial,setTestimonial]=useState([]);
    
    useEffect(()=>{
        setTestimonial(props.testimonials);
       
    },[props]);

 
    return (
           <div className='create_testimonial'>
                <Link to="" onClick={() => setModalShow(true)}>
                    <div className='vector'><img src={testimonialvector} alt="testimonialvector" /></div>
                    <div className='label'>Pick up the testimonial to get started</div>
                </Link>
                <Modaltestimonial
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    testimonial={testimonial}
                />
            </div>
    );  
}