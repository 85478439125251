import temfivestar from "../Temimg/temfivestar.png";
import avtarfive from "../Temimg/avtarfive.png";
import React from "react";
import VideoPlayer from "./VideoPlayer";
import VimeoPlayer from "./VimeoPlayer";

function Template(props) {

    let padding = props.paddingvalue;
    let size = props.sizevalue;
    let total_padding = parseInt(padding) + parseInt(size);

    return (
        <>
            <div className="template_wrapper" id="image_dowload" >
                {props.data.map(function (data, key) {
                    return (
                        <div className="temp_five common_temp position-relative mb-4" key={key} style={{ backgroundImage: `url(${props.BgImage})`, backgroundRepeat: "no-repeat, repeat", backgroundSize: "cover", backgroundColor: props.color }}>
                            <div className="temp_five_inner" style={{ backgroundImage: `url(${props.logoimg})`, backgroundRepeat: "no-repeat, repeat", padding: (total_padding) + "px", backgroundPosition: props.logoposition, position: "relative", bottom: "0", backgroundSize: props.sizevalue + "px" }}>
                                <div className="wrap">
                                    <div className={props.rating ? "stars text-end" : "d-none"}>
                                        <img src={temfivestar} alt="temfivestar" />
                                    </div>
                                    <div className="content">
                                        <p className="mb-0" style={
                                        {
                                            color:props.reviewTextcolor,
                                            fontFamily:props.reviewFontFamily,
                                            fontSize:props.reviewFontSize,
                                            fontWeight:props.reviewFontWeight,
                                            lineHeight:props.reviewLineHeight,
                                            letterSpacing:props.reviewLetterSpacing,
                                            wordSpacing:props.reviewWordSpacing,
                                            paddingTop:props.reviewPaddingTop,
                                            paddingRight:props.reviewPaddingRight,
                                            paddingBottom:props.reviewPaddingBottom,
                                            paddingLeft:props.reviewPaddingLeft
                                        }
                                        }>
                                            {data.text}

                                            {data.files &&
                                                <div className='upload_post_image mt-3'>
                                                    {
                                                        data.platform_name === 'proof_pudding'
                                                            ?
                                                            <VideoPlayer src={data.media[0].file_url} id={data.media[0].id} />
                                                            :
                                                            data.media[0].type === 'photo'
                                                                ?
                                                                <img src={data.media[0].file_url} alt="image" className='w-100' />
                                                                :
                                                                data.platform_name === 'vimeo'
                                                                ?
                                                                <VimeoPlayer key={data.review_id} videoId={data.review_id} />
                                                                :
                                                                <VideoPlayer src={data.media[0].file_url} id={data.media[0].id} />
                                                    }
                                                </div>
                                            }
                                        </p>
                                    </div>
                                    <div className="author">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                {
                                                    props.profileImg
                                                    ?
                                                    <img className={props.avtar ? "mb-0 rounded-circle" : "d-none"} src={props.profileImg} alt="avatar" />
                                                    :
                                                    data.reviewer_picture
                                                        ?
                                                        <img className={props.avtar ? "mb-0 rounded-circle" : "d-none"} src={data.reviewer_picture} alt="avatar" />
                                                        :
                                                        <img className={props.avtar ? "mb-0" : ""} src={avtarfive} alt="avatar" />
                                                }
                                            </div>
                                            <div>
                                                <div className="author_name" style={
                                                {
                                                    color:props.nameTextcolor,
                                                    fontFamily:props.nameFontFamily,
                                                    fontSize:props.nameFontSize,
                                                    fontWeight:props.nameFontWeight,
                                                    lineHeight:props.nameLineHeight,
                                                    letterSpacing:props.nameLetterSpacing,
                                                    wordSpacing:props.nameWordSpacing,
                                                    paddingTop:props.namePaddingTop,
                                                    paddingRight:props.namePaddingRight,
                                                    paddingBottom:props.namePaddingBottom,
                                                    paddingLeft:props.namePaddingLeft
                                                }
                                                }>{data.reviewer_name}</div>
                                                <div className="designation">{data.reviewer_handle && '@' + data.reviewer_handle}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}



export default Template;