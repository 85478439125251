import { Col, Row, Container, Card, Form, Button } from 'react-bootstrap';
import Sidebar from './Sidebar';
import menu from "../Images/menu.svg";
import camera from "../Images/camera.png";
import contactemail from "../Images/contactemail.png";
import contactphone from "../Images/contactphone.png";
import contactloaction from "../Images/contactloaction.png";
import { Link } from 'react-router-dom';
import { React, useState, useEffect, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserPlan from './UserPlan';
import SavedCards from './SavedCards';

async function updateUserProfile(credentials, accessToken) {
    return fetch(process.env.REACT_APP_API_URL + 'update-profile', {
        method: 'POST',
        mode: 'cors',
        headers: {
            authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

const UpdateNotification = async function (param, accessToken) {
    return fetch(process.env.REACT_APP_API_URL + 'update-user-notification', {
        method: 'POST',
        headers: {
            authorization: 'Bearer ' + accessToken,
            Accept: 'application/json'
        },
        body: new URLSearchParams(param)
    }).then(response => response.json());
}

function Settings() {

    const accessToken = localStorage.getItem('accessToken');
    const [Users, setUsers] = useState([]);
    const [name, setUserName] = useState();
    const [business_name, setbusinessName] = useState();
    const [facebook_page_id, setFacebookPageId] = useState();
    const [g2_product_name, setg2ProductName] = useState();
    const [trustpilot_place_name, setTrustpilotPlaceName] = useState();
    const [yelp_place_name, setYelpPlaceName] = useState();
    const [capterra_place_id, setCapterraPlaceId] = useState();
    const [youtube_channel_id, setYoutubeChannelId] = useState();
    const [zillow_uid, setZillowUid] = useState();
    const [tripadvisor_rental_id, setTripadvisorRentalId] = useState();
    const [realtor_advertiser_id, setRealtorAdvertiserId] = useState();

    const [industry, setIndustry] = useState();
    const [validated, setValidated] = useState(false);
    const [showfile, setShowFile] = useState([]);
    const [file, setFile] = useState(null);
    const [shortName, setShortName] = useState('');
    const [notification, setnotification] = useState(null);
    const [Emailnotification, setEmailnotification] = useState(null);
    const [activePlan, setActivePlan] = useState(null);
    const [savedCards, setSavedCards] = useState(null);
    const [serviceText, setServiceText] = useState('');
    const profileImage = useRef(null);

    const [bulletPoints, setBulletPoints] = useState([]);

    const handleTextareaChange = (event) => {
        setServiceText(event.target.value);
    };

    function toggleClass() {
        if (document.body.classList.contains('show')) {
            document.body.classList.remove('show');
        } else {
            document.body.classList.add('show');
        }
    };
    function handleFileChange(e) {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            setFile(reader.result);
        }
        setShowFile(URL.createObjectURL(e.target.files[0]));
    }
    const getUser = () => {
        fetch(process.env.REACT_APP_API_URL + 'get-user', {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            },
        }).then(response => response.json())
            .then(data => {
                setUsers(data.data);
                setUserName(data.data.name);
                setbusinessName(data.data.business_name);
                setFacebookPageId(data.data.facebook_page_id);
                setg2ProductName(data.data.g2_product_name);
                setTrustpilotPlaceName(data.data.trustpilot_place_name);
                setYelpPlaceName(data.data.yelp_place_name);
                setCapterraPlaceId(data.data.capterra_place_id);
                setYoutubeChannelId(data.data.youtube_channel_id);
                setZillowUid(data.data.zillow_uid);
                setTripadvisorRentalId(data.data.tripadvisor_rental_id);
                setRealtorAdvertiserId(data.data.realtor_advertiser_id);

                setIndustry(data.data.industry);
                getUserNameInitials(data.data.name);
                setShowFile(data.data.image);
                setnotification(data.data.notification == 0 ? false : true);
                setEmailnotification(data.data.email_notification);
                setActivePlan(data.active_plan);
                setSavedCards(data.saved_cards);
                setServiceText(data.data.service_questions);
            });
    }
    function getUserNameInitials(name_) {
        const slices = name_.split(' ');
        let name_initials = '';
        slices.forEach(item => {
            name_initials = name_initials + '' + item.charAt(0).toUpperCase();
        });
        setShortName(name_initials);
    }

    useEffect(() => {
        getUser();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            const response = await updateUserProfile({
                name,
                business_name,
                facebook_page_id,
                g2_product_name,
                trustpilot_place_name,
                yelp_place_name,
                capterra_place_id,
                youtube_channel_id,
                zillow_uid,
                tripadvisor_rental_id,
                realtor_advertiser_id,
                industry,
                file,
                serviceText
            }, accessToken);

            if (response.status) {
                toast.success(response.message);
                localStorage.setItem('user', JSON.stringify(response.data));
                setUsers(response.data);
                setUserName(response.data.name);
                setbusinessName(response.data.business_name);
                setFacebookPageId(response.data.facebook_page_id);
                setg2ProductName(response.data.g2_product_name);
                setTrustpilotPlaceName(response.data.trustpilot_place_name);
                setYelpPlaceName(response.data.yelp_place_name);
                setCapterraPlaceId(response.data.capterra_place_id);
                setYoutubeChannelId(response.data.youtube_channel_id);
                setZillowUid(response.data.zillow_uid);
                setTripadvisorRentalId(response.data.tripadvisor_rental_id);
                setRealtorAdvertiserId(response.data.realtor_advertiser_id);

                setIndustry(response.data.industry);
                getUserNameInitials(response.data.name);
                setShowFile(response.data.image);
            } else {
                toast.error(response.message);
            }
        }
    };
    async function getEmailNotification(e) {
        let email_notification = e.target.checked !== true ? 0 : 1;
        const response = await UpdateNotification({
            'email_notification': email_notification,
        }, accessToken);
    }
    async function getNotification(e) {
        let notification = e.target.checked !== true ? 0 : 1;
        const response = await UpdateNotification({
            'notification': notification,
        }, accessToken);
    }
    return (
        <>
            <Container fluid className="pe-0">
                <Sidebar />
                <div className='wrapper'>
                    <div className='header pb-3'>
                        <Row>
                            <Col>
                                <div className='d-flex align-items-center'>
                                    <div className='sidebar_toggle' onClick={toggleClass}>
                                        <img src={menu} alt="menu" />
                                    </div>
                                    <h2 className='mb-0 h-100'>Settings</h2>
                                </div>
                            </Col>
                            <Col>
                                <div className='d-flex justify-content-end'>
                                    {
                                        showfile.length
                                            ?
                                            <img className='profile_name d-flex justify-content-center align-items-center' src={showfile} />
                                            :
                                            <div className='profile_name d-flex justify-content-center align-items-center'>
                                                {shortName}
                                            </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='inner_content_wrapper'>
                        <Row>
                            <Col>
                                <Card>
                                    <div className='profilesetup'>
                                        <h4 className='mb-2'>Profile Settings</h4>
                                        <div className='profileform'>
                                            <div className='avatar_wrap' onClick={() => { profileImage.current.click(); }}>
                                                {
                                                    showfile.length
                                                        ?
                                                        <img className='profileimg' src={showfile} />
                                                        :
                                                        <div className='profileimg'>{shortName}</div>
                                                }
                                                <div className='imagechange' ><img src={camera} alt="camera" /></div>
                                                <input 
                                                    type="file" 
                                                    name="profileImage" 
                                                    ref={profileImage} 
                                                    onChange={handleFileChange} 
                                                    className="d-none" 
                                                    accept="image/jpg, image/jpeg,image/png" 
                                                />
                                            </div>
                                            <div className='user_name text-center mt-2'>{Users.name}</div>
                                            <div className='user_email text-center'>{Users.email}</div>
                                            <div className='form_wrapper pt-0 mt-3'>
                                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                    <Row>
                                                        <Col lg="6" className='pe-2'>
                                                            <input 
                                                                type="hidden" 
                                                                name="id" 
                                                                id="id" 
                                                                value={Users.id}
                                                                onChange={()=>console.log('do nothing')} 
                                                            />
                                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                                <Form.Label>Name</Form.Label>
                                                                <Form.Control type="name" placeholder="Enter your Name"
                                                                    value={Users.name}
                                                                    required
                                                                    onChange={e => setUserName(e.target.value)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Name is required
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="6" className='ps-2'>
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Label>Email</Form.Label>
                                                                <Form.Control 
                                                                    type="email" 
                                                                    placeholder="Enter your Email" 
                                                                    value={Users.email} 
                                                                    readOnly 
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                       { /*  <Col lg="6" className='pe-2'>
                                                            <Form.Group className="mb-3" controlId="formBasicBusiness">
                                                                <Form.Label>Business Name</Form.Label>
                                                                <Form.Control 
                                                                    type="name" 
                                                                    placeholder="Enter your Business Name" 
                                                                    value={Users.business_name}
                                                                    required
                                                                    onChange={e => setbusinessName(e.target.value)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Business Name is required
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>


                                                         <Col lg="6" className='pe-2'>

                                                            <Form.Group className="mb-3" controlId="formBasicFacebook">
                                                                <Form.Label>Facebook Page Id</Form.Label>
                                                                <Form.Control 
                                                                    type="name" 
                                                                    placeholder="Page Id. E.g, metmuseum of https://www.facebook.com/metmuseum" 
                                                                    value={Users.facebook_page_id}

                                                                    onChange={e => setFacebookPageId(e.target.value)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Facebook Page Id is required
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                         <Col lg="6" className='pe-2'>
                                                            <Form.Group className="mb-3" controlId="formBasicG2ProductName">
                                                                <Form.Label>G2 Product Name</Form.Label>
                                                                <Form.Control 
                                                                    type="name" 
                                                                    placeholder="Please enter G2 product name e.g  slack" 
                                                                    value={Users.g2_product_name}

                                                                    onChange={e => setg2ProductName(e.target.value)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    G2 Product Name is required
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="6" className='pe-2'>
                                                            <Form.Group className="mb-3" controlId="formBasicTrustpilotPlaceName">
                                                                <Form.Label>Trustpilot Place Name</Form.Label>
                                                                <Form.Control 
                                                                    type="name" 
                                                                    placeholder="Please enter place name in Trustpilot  e.g  slack.com" 
                                                                    value={Users.trustpilot_place_name}

                                                                    onChange={e => setTrustpilotPlaceName(e.target.value)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Trustpilot Place Name is required
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="6" className='pe-2'>
                                                            <Form.Group className="mb-3" controlId="formBasicYelpPlaceName">
                                                                <Form.Label>Yelp Place Name</Form.Label>
                                                                <Form.Control 
                                                                    type="name" 
                                                                    placeholder="Please enter place name in Yelp    e.g  'best-buy-midtown-manhattan-44th-and-5th-new-york'" 
                                                                    value={Users.yelp_place_name}

                                                                    onChange={e => setYelpPlaceName(e.target.value)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Yelp Place Name is required
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="6" className='pe-2'>
                                                            <Form.Group className="mb-3" controlId="formBasicCapterraPlaceId">
                                                                <Form.Label>Capterra Place Id</Form.Label>
                                                                <Form.Control 
                                                                    type="name" 
                                                                    placeholder="Please enter place id in Capterra  e.g  135003" 
                                                                    value={Users.capterra_place_id}

                                                                    onChange={e => setCapterraPlaceId(e.target.value)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Capterra Place Id is required
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="6" className='pe-2'>
                                                            <Form.Group className="mb-3" controlId="formBasicYoutubeChannelId">
                                                                <Form.Label>Youtube Channel Id</Form.Label>
                                                                <Form.Control 
                                                                    type="name" 
                                                                    placeholder="Please enter Youtube channel id  e.g  UCqNPE7v_Dqwgw4BQXlFl1er" 
                                                                    value={Users.youtube_channel_id}

                                                                    onChange={e => setYoutubeChannelId(e.target.value)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Youtube Channel Id is required
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="6" className='pe-2'>
                                                            <Form.Group className="mb-3" controlId="formBasicZillowUid">
                                                                <Form.Label>Zillow Uid</Form.Label>
                                                                <Form.Control 
                                                                    type="name" 
                                                                    placeholder="Please enter zillow uid e.g  X1-ZUz0nmomozy2o9_9bpwk" 
                                                                    value={Users.zillow_uid}

                                                                    onChange={e => setZillowUid(e.target.value)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Zillow Uid is required
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="6" className='pe-2'>
                                                            <Form.Group className="mb-3" controlId="formBasicTripadvisorRentalId">
                                                                <Form.Label>Tripadvisor Rental Id</Form.Label>
                                                                <Form.Control 
                                                                    type="name" 
                                                                    placeholder="Please enter tripadvisor rental id e.g  93437" 
                                                                    value={Users.tripadvisor_rental_id}

                                                                    onChange={e => setTripadvisorRentalId(e.target.value)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Tripadvisor Rental Id is required
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="6" className='pe-2'>
                                                            <Form.Group className="mb-3" controlId="formBasicRealtorAdvertiserId">
                                                                <Form.Label>Realtor Advertiser Id</Form.Label>
                                                                <Form.Control 
                                                                    type="name" 
                                                                    placeholder="Please enter realtor advertiser id   e.g  1633379" 
                                                                    value={Users.realtor_advertiser_id}

                                                                    onChange={e => setRealtorAdvertiserId(e.target.value)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Realtor Advertiser Id is required
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>  */ }

                                                        <Col lg="6" className='ps-2'>
                                                            <Form.Group className="mb-3" controlId="formBasicIndustry">
                                                                <Form.Label>Industry</Form.Label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    placeholder="Enter your Industry" 
                                                                    value={Users.industry}
                                                                    required
                                                                    onChange={e => setIndustry(e.target.value)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Industry is required
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Control.Feedback type="invalid">
                                                                Name is required
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                        <Col className='ps-2'>
                                                              <Form.Group className="mb-3">
                                                                <Form.Label className="mb-0">Review Prompts</Form.Label>
                                                                <p>What would you like your customer to say about your service</p>
                                                                <textarea 
                                                                onChange={handleTextareaChange}
                                                                    placeholder="What did you like about us?
                                                                    How did our product/service help you?
                                                                    Would you recommend us to a friend?
                                                                    Did you find our business easy to work with?
                                                                    Would you say our business exceeded your expectations?"      
                                                                    defaultValue={serviceText}     
                                                                >
                                                                </textarea>
                                                              </Form.Group>
                                                        </Col>                                                       
                                                        <Col md="12">
                                                            <Button type="submit">Save My Profile</Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                                <Card className='mt-3'>
                                    <div className='billing_info'>
                                        <h4>Billing information</h4>
                                        <p>Manage your billing and payment Details.</p>
                                        <Row>
                                            <UserPlan plan={activePlan} />
                                            <SavedCards cards={savedCards} />
                                        </Row>
                                    </div>
                                </Card>
                                <Card className='mt-3'>
                                    <div className='contact_information'>
                                        <h4 className='mb-2'>Support</h4>
                                        <div className='contatc_inner_wrapper'>
                                            <p> For support inquires, please reach us at: </p>
                                            <ul>
                                                <li><img src={contactemail} alt="contactemail" className='me-2' />Email: support@proofpudding.io</li>
                                                {/*<li><img src={contactphone} alt="contactphone" className='me-2' />Phone: 202-5555-0130</li>*/}
                                                {/*<li className='m-0'><img src={contactloaction} alt="contactloaction" className='me-2' />Address: 48 North Promenade Dolgoch United Kingdom</li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </Card>
                                <Card className='mt-3'>
                                    <div className='general_settings '>
                                        <h4>General Settings</h4>
                                        <ul>
                                            <li>
                                                <div className='custom_check'>
                                                    <input 
                                                        type="checkbox" 
                                                        defaultChecked={notification} 
                                                        onClick={getNotification.bind(this)} 
                                                        onChange={()=>console.log('do nothing')} 
                                                    />
                                                    <span></span>
                                                    Notifications
                                                </div>
                                            </li>
                                            <li className='mt-2'>
                                                <div className='custom_check'>
                                                    <input 
                                                        type="checkbox" 
                                                        defaultChecked={Emailnotification} 
                                                        onClick={getEmailNotification.bind(this)} 
                                                        onChange={()=>console.log('do nothing')} 
                                                    />
                                                    <span></span>
                                                    Email Notifications
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="card_list mt-3">
                                            <div className='right'>
                                                <Link to="/settings/reset-password"><Button className='edit'>Change Password</Button></Link>
                                            </div>
                                        </div>
                                        <div className='links_wrap'>
                                            <Link to="/privacy-policy">Privacy Policy</Link>
                                            <Link to="/terms">Terms and conditions</Link>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        </>
    );
}
export default Settings;