import React, { useEffect, useState } from "react";
import WallLoveModal from "./WallLoveModal";
import LoveTemplate from './LoveTemplate'

async function getTestimonialsByIds(params, access_token) {
    return fetch(process.env.REACT_APP_API_URL + 'get-testimonials-by-ids', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
        body: JSON.stringify(params)
    })
        .then(data => data.json())
}

export default function WidgetSection(props) {
    const accessToken = localStorage.getItem('accessToken');
    const [modalShow, setModalShow] = useState(false);
    const [testimonialsIds, setTestimonialsIds] = useState([]);
    const [data, setData] = useState([]);

    const handleChildData = async (event) => {
        let arr = event.detail.data.split(',');
        setTestimonialsIds(arr);
        const response = await getTestimonialsByIds({
            'ids': arr
        }, accessToken);
        setData(response.data);

        localStorage.setItem('love_Template', props.activeTemplate);
        localStorage.setItem('love_testimonials_ids', arr);
    };
    useEffect(() => {
        setData(props.testimonials);
        document.addEventListener('lovewallData', handleChildData);
        return () => {
            document.removeEventListener('lovewallData', handleChildData);
        };
    }, [props.testimonials]);
    
    return (
        <div className='testimonial_view wall_template'>
            <div className="testimonial_view_inner"  >
                <LoveTemplate 
                    testimonials={data} 
                    title={props.activeTemplate} 
                    logoimage={props.logoimage} 
                    logosize={props.logosize} 
                    Bgcolor={props.Bgcolor}
                    pageTitle={props.pageTitle} 
                    ctaTitle={props.ctaTitle}
                    subtitle={props.subtitle}
                    ctaURL={props.ctaURL}
                    MenuOneTitle={props.MenuOneTitle}
                    MenuOneLink={props.MenuOneLink}
                    MenuTwoTitle={props.MenuTwoTitle}
                    MenuTwoLink={props.MenuTwoLink}
                    MenuThreeTitle={props.MenuThreeTitle}
                    MenuThreeLink={props.MenuThreeLink}
                    showDelete={true}
                    buttonColor={props.buttonColor}
                    reviewColor={props.reviewColor}
                    fontColor={props.fontColor}
                    fontFamily={props.fontFamily}
                    authorFont={props.authorFont}
                    reviewFont={props.reviewFont}
                />
            </div>
            <WallLoveModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                testimonials={props.testimonials}
            />
        </div>
    );
}