import { Col, Row, Container, Card, Form, Button } from 'react-bootstrap';
import Sidebar from './Sidebar';
import Header from './Header';
import React, { useState, useEffect } from 'react';
import Loader from './Loader';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

async function updateActiveProject(params, accessToken) {
    return fetch(process.env.REACT_APP_API_URL + 'update-active-project', {
        method: 'POST',
        mode: 'cors',
        headers: {
            authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    })
        .then(data => data.json())
}

export default function ProjectSettings(props) {
    const accessToken = localStorage.getItem('accessToken');
    const user = JSON.parse(localStorage.getItem('user'));
    const [isLoading, setIsLoading] = useState(true);
    const [projectId, setProjectId] = useState();
    const [name, setName] = useState();
    const [slug, setSlug] = useState();
    const [validated, setValidated] = useState(false);

    function generateSlug(inputString) {
        return inputString.toLowerCase().replace(/\s+/g, '-');
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            const response = await updateActiveProject({
                projectId,
                name,
                slug
            },accessToken);

            if(response.status){
                setName(response.project.name);
                setSlug(response.project.slug);
                toast.success(response.message);
                const eventData = { data: response.projects };
                const event = new CustomEvent('projectUpdated', { detail: eventData });
                document.dispatchEvent(event);
            }else{
                toast.error(response.message);
            }
        }
    };
      
    const getActiveProject = () => {
        fetch(process.env.REACT_APP_API_URL + 'get-active-project', {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            },
        }).then(response => response.json())
            .then(data => {
                setProjectId(data.id);
                setName(data.name);
                setSlug(data.slug);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getActiveProject();
    }, []);

    if (isLoading) {
        return <Loader />;
    }
    return (
        <>
            <Container fluid className="pe-0">
                <Sidebar />
                <div className='wrapper'>
                    <Header title="Project Settings" />
                    <div className='filter'>
                        <Row>
                            <Col sm="3" md="3" lg="4">
                                <div className='layout_switch'>
                                    <Link to="/project-settings" className='active'>General</Link>
                                    <Link to="/project-settings/domain">Domain</Link>
                                    <Link to="/project-settings/danger">Danger Zone</Link>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Row>
                        <Col>
                            <div className='project-settings-wrapper mt-4'>
                                <div className='form_wrapper pt-0'>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Row>
                                            <Col lg="6" className='pe-2'>
                                                <Form.Group className="mb-3" controlId="formBasicName">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control 
                                                        type="text" 
                                                        value={name}
                                                        required
                                                        onChange={(e)=>{
                                                            setName(e.target.value);
                                                            setSlug(generateSlug(e.target.value));
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col> 
                                            <Col lg="6">
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Slug</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        readOnly
                                                        value={slug}
                                                        onChange={(e)=>setSlug(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>    
                                            <Col>
                                                <Button type="submit" className="update-btn">Update</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
}