import { Col, Row, Container, Form, Button, FormGroup } from 'react-bootstrap';
import ProofPuddingLogo from "../Images/ProofPuddingLogo.png";
import smile from "../Images/smile.png";
import signingraphic from "../Images/signingraphic.svg"
import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

async function savePlatforms(credentials, accessToken) {
    return fetch(process.env.REACT_APP_API_URL + 'save-platforms', {
        mode: 'cors',
        method: 'POST',
        headers: {
            authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
    .then(data => data.json())
}

function Form_wrap() {
    const navigate = useNavigate();
    const accessToken = localStorage.getItem('accessToken');
    const [platforms, setPlatforms] = useState([]);
    const [selectedPlatforms, setSelectedPlatforms] = useState([]);
    

    const getPlatforms = () => {
        fetch(process.env.REACT_APP_API_URL + 'choose-platform', {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            },
        }).then(response => response.json())
            .then(data => {
                setPlatforms(data.data);
                setSelectedPlatforms(data.user_platform);
            });
    }

    const checkPlatform = (platform_id) => {

        if (selectedPlatforms.indexOf(platform_id) !== -1) {
            selectedPlatforms.splice(selectedPlatforms.indexOf(platform_id), 1);
        } else {
            selectedPlatforms.push(platform_id);
        }
    }

    const addPlatforms = async() => {

        if(selectedPlatforms.length){
            const response = await savePlatforms({
                selectedPlatforms
            }, accessToken);

            if(response.status){
                navigate('/dashboard')
            }

        }else{
            toast.error('Please select at least one platform');
        }

    }

    useEffect(() => {
        getPlatforms();
    }, []);
 
    console.log(selectedPlatforms);

    return (
        <>
            <ToastContainer />
            <div className='form_header'>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className='logo'>
                                <img src={ProofPuddingLogo} alt="ProofPuddingLogo" />
                            </div>
                        </Col>
                        <Col>
                           
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='content_wrapper platformlist-wrapper'>
                <Container fluid className='h-100'>
                    <Row className='h-100'>
                        <Col lg={6} md={12} className='p-0'>
                            <div className='form_wrapper d-flex align-items-center justify-content-center h-100'>
                                <div className='inner_wrapper'>
                                    <h2><img className='smile' src={smile} alt="smile" /> Where are you going to embded your testimonials?</h2>
                                    <Form>
                                        <div className='plateforms'>
                                            <ul>
                                                {platforms.map(function (platform, index) {
                                                    if (platform.slug != 'proof_pudding') {

                                                        return (
                                                            <li key={index} onClick={() => {
                                                                checkPlatform(platform.id)
                                                            }}>
                                                              
                                                                <div className='box'>
                                                                    <input type="checkbox" name="check" defaultChecked={selectedPlatforms.includes(platform.id)} disabled={selectedPlatforms.includes(platform.id)} />
                                                                    <div className='box_inner'>
                                                                        <div>
                                                                            <img src={process.env.REACT_APP_PLATFORM_LOGO_URL + '' + platform.logo} alt={platform.name} className="me-1" />
                                                                            <label>{platform.name}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    }
                                                })}

                                            </ul>
                                        </div>
                                        <Button variant="primary" type="button" onClick={addPlatforms} className='m-auto mb-4 mt-3'>
                                            Next
                                        </Button>
                                       
                                    </Form>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} className='p-0'>
                            <div className='grahic_wrapper d-flex align-items-center justify-content-center h-100'>
                                <img src={signingraphic} alt="signingraphic" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
export default Form_wrap;