import scratch from "../Images/scratch.svg";
import templateone from "../Tem_images/1.png";
import templatetwo from "../Tem_images/2.png";
import templatethree from "../Tem_images/3.png";
import templatefour from "../Tem_images/4.png";
import templatefive from "../Tem_images/5.png";
import templateeight from "../Tem_images/8.png";
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import React, { useEffect, useState } from "react";

export default function ImagesPartial(props) {

    const user = JSON.parse(localStorage.getItem('user'));
    const [allowedSocialImages, setAllowedSocialImages] = useState();

    useEffect(()=>{
        setAllowedSocialImages(user.userplan.plan.no_of_social_images==='unlimited'?100:user.userplan.plan.no_of_social_images);
    },[]);

    return (
        <Card className='mt-3'>
            <div className='card_box_wrapper'>
                <h4>Social Images</h4>
                <p>Create beautiful images from your testimonials.</p>
                <div className="scroller">
                    <div className="scroller_inner">
                        <div className="start_from_scratch">
                            <Link to="/social-proof/images/template">
                                <div className='start'>
                                    <img src={scratch} alt="scratch"  />
                                    <h5>Start form <br />scratch</h5>
                                </div>
                            </Link>
                        </div>
                        <ul>
                          {/*  <li className={allowedSocialImages>=1?'':'d-none'}>
                                <Link to="/social-proof/images/template">
                                    <img src={templateone} alt="templateone" onClick={props.themefunction.bind(this,'TemplateOne')} />
                                </Link>
                            </li>*/}
                            <li className={allowedSocialImages>=2?'':'d-none'}>
                                <Link to="/social-proof/images/template">
                                    <img src={templatetwo} alt="templatetwo"  onClick={props.themefunction.bind(this,'TemplateTwo')}  />
                                </Link>
                            </li>
                            <li className={allowedSocialImages>=3?'':'d-none'}>
                                <Link to="/social-proof/images/template">
                                    <img src={templatethree} alt="templatethree" onClick={props.themefunction.bind(this,'TemplateThree')} />
                                </Link>
                            </li>
                           {/* <li className={allowedSocialImages>=4?'':'d-none'}>
                                <Link to="/social-proof/images/template">
                                    <img src={templatefour} alt="templatefour"  onClick={props.themefunction.bind(this,'TemplateFour')}  />
                                </Link>
                            </li>*/}
                            <li className={allowedSocialImages>=5?'':'d-none'}>
                                <Link to="/social-proof/images/template">
                                    <img src={templatefive} alt="templatefive" onClick={props.themefunction.bind(this,'TemplateFive')} />
                                </Link>
                            </li>

                            <li className={allowedSocialImages>=6?'':'d-none'}>
                                <Link to="/social-proof/images/template">
                                    <img src={templateeight} alt="templateeight" onClick={props.themefunction.bind(this,'TemplateEight')} />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Card>
    );
}