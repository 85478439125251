import { Col, Row, Container, Card, Form, Button } from 'react-bootstrap';
import Sidebar from './Sidebar';
import Header from './Header';
import React, { useState, useEffect } from 'react';
import Loader from './Loader';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

async function deleteProject(params, accessToken) {
    return fetch(process.env.REACT_APP_API_URL + 'delete-project', {
        method: 'POST',
        mode: 'cors',
        headers: {
            authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    })
        .then(data => data.json())
}

export default function ProjectSettings(props) {
    const accessToken = localStorage.getItem('accessToken');
    const user = JSON.parse(localStorage.getItem('user'));
    const [isLoading, setIsLoading] = useState(true);
    const [projectId, setProjectId] = useState();
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        confirmAlert({
            // title: 'Confirm Delete',
            message: 'Are you sure you want to delete this project?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async() => {
                        const response = await deleteProject({
                            'project_id' : projectId
                        },accessToken);

                        if(response.status){
                            toast.success(response.message);
                            const eventData = { data: response.projects };
                            const event = new CustomEvent('projectUpdated', { detail: eventData });
                            document.dispatchEvent(event);
                            navigate('/project-settings');
                        }else{
                            toast.error(response.message);
                        }
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    };

    const getActiveProject = () => {
        fetch(process.env.REACT_APP_API_URL + 'get-active-project', {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            },
        }).then(response => response.json())
            .then(data => {
                setProjectId(data.id);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getActiveProject();
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <Container fluid className="pe-0">
                <Sidebar />
                <div className='wrapper'>
                    <Header title="Project Settings" />
                    <div className='filter'>
                        <Row>
                            <Col sm="3" md="3" lg="4">
                                <div className='layout_switch'>
                                    <Link to="/project-settings">General</Link>
                                    <Link to="/project-settings/domain">Domain</Link>
                                    <Link to="/project-settings/danger" className='active'>Danger Zone</Link>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Row>
                        <Col>
                            <div className='project-settings-wrapper mt-4'>
                                <div className='form_wrapper pt-0 mt-3'>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Row>
                                            <Col>
                                                <div className="title">Delete Project</div>
                                                <p className="mb-3">This will permanently delete your entire project. All your testimonials, forms and widgets will be deleted permanently.</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button type="submit" className="delete_btn">Delete</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
}