import React, { useEffect, useState } from "react";
import WidgetHeader from './WidgetHeader';
import WidgetSection from './WidgetSection';
import { Link } from 'react-router-dom';
import Widget_1 from "../../Tem_images/Widget_1.png";
import Widget_2 from "../../Tem_images/Widget_2.png";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WidgetModal from "./WidgetModal";
import { Container, Tab, Tabs, Form } from 'react-bootstrap';
import { SketchPicker } from 'react-color';

export default function SocialProof() {

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [widget, setWidget] = useState(params.get('widget'));
    const [editWidget, setEditWidget] = useState(params.get('id'));
    const [editTemplate, setEditTemplate] = useState(null);
    const accessToken = localStorage.getItem('accessToken');
    const [activeTemplate, setActiveTemplate] = useState();
    const [testimonials, setTestimonials] = useState([]);
    localStorage.removeItem("testimonials_ids");

    const [activeTab, setActiveTab] = useState('templates');
    const [modalShow, setModalShow] = useState(false);

    const [showDatesAndLikes, setShowDatesAndLikes] = useState(true);
    const [showAvatars, setShowAvatars] = useState(true);
    const [showRatings, setShowRatings] = useState(true);

    const [color, setColor] = useState('#000000');
    const [showPicker, setShowPicker] = useState(false);
    const [textColor, setTextColor] = useState('#ffffff');
    const [showPickerText, setShowPickerText] = useState(false);
    const [fontFamily, setFontFamily] = useState("'Montserrat', sans-serif");
    const [borderRadius, setBorderRadius] = useState();
    const [margin, setMargin] = useState();

    const user = JSON.parse(localStorage.getItem('user'));
    const [allowedWidgets, setAllowedWidgets] = useState();

    const handleClick = () => {
        setShowPicker(!showPicker);
    };
    const handleChange = (newColor) => {
        setColor(newColor.hex);
    };
    const handleClickText = () => {
        setShowPickerText(!showPickerText);
    };
    const handleChangeText = (newColor) => {
        setTextColor(newColor.hex);
    };
    const handleFontChange = (event) => {
        setFontFamily(event.target.value);
    };
    const handleBorderRadius = (event) => {
        setBorderRadius(event.target.value);
    }
    const handleMargin = (event) => {
        setMargin(event.target.value);
    }
    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (tab === 'testimonials') {
            setModalShow(true);
        }
    };

    function getTestimonial() {
        fetch(process.env.REACT_APP_API_URL + 'get-testimonial', {
            method: 'Get',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            }
        })
            .then(response => response.json())
            .then(data => {
                setTestimonials(data.data);
            });
    }

    const handleTemplate = (active) => {
        setActiveTemplate(active);
        if(active===2){
            setFontFamily("'Dancing Script', cursive");
            setColor('#434445');
            setBorderRadius('0px');
        }else{
            setFontFamily("'Montserrat', sans-serif");
            setColor('#000000');
            setBorderRadius('1rem');
        }
        localStorage.setItem('Widget_Template', active);
    }

    const getWidget = (id) => {
        fetch(process.env.REACT_APP_API_URL + 'get-widget', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify({ 'id': id })
        })
            .then(data => data.json())
            .then(data => {
                if (data.status) {
                    const testimonials_ids = data.widget.testimonials_ids;
                    if (testimonials_ids) {
                        const eventData = { data: testimonials_ids };
                        const event = new CustomEvent('widgetData', { detail: eventData });
                        document.dispatchEvent(event);
                        localStorage.removeItem("testimonials_ids");
                        setActiveTemplate(data.widget.template);
                        localStorage.setItem('Widget_Template', data.widget.template);
                        setEditTemplate(data.widget.template);
                        setShowAvatars(data.widget.show_avatar);
                        setShowDatesAndLikes(data.widget.show_date);
                        setShowRatings(data.widget.show_rating);
                        setColor(data.widget.background);
                        setTextColor(data.widget.color);
                        setFontFamily(data.widget.font_family);
                        setBorderRadius(data.widget.border_radius);
                        setMargin(data.widget.spacing);
                    }
                }
            });
    }

    useEffect(() => {
        getTestimonial();
        localStorage.removeItem('Widget_Template');
        localStorage.removeItem('Widget_Testimonials');
        localStorage.removeItem('Widget_Name');
        localStorage.setItem('Widget_Name', widget ? widget : 'Proof Pudding Widget');

        if (editWidget) {
            getWidget(atob(editWidget));
        } else {
            setActiveTemplate(1);
            localStorage.setItem('Widget_Template', 1);
            setBorderRadius('0px');
            setMargin('0px -3px');
            setColor('#000000');
        }

        setAllowedWidgets(user.userplan.plan.no_of_widgets==='unlimited'?100:user.userplan.plan.no_of_widgets);
    }, []);

    return (
        <>
            <ToastContainer />
            <Container fluid className="p-0">
                <div className='edit_wrapper'>
                    <WidgetHeader
                        title={widget}
                        date={showDatesAndLikes}
                        rating={showRatings}
                        avatar={showAvatars}
                        background={color}
                        color={textColor}
                        fontFamily={fontFamily}
                        borderRadius={borderRadius}
                        margin={margin}
                    />
                    <div className='edit_content_wrapper d-flex'>
                        <div className='edit_sidebar'>
                            {/* sidebar */}
                            <Tabs
                                id="uncontrolled-tab-example"
                                className="mb-3"

                                activeKey={activeTab}
                                onSelect={handleTabClick}
                            >
                                <Tab eventKey="testimonials" title="Testimonials">

                                    <WidgetModal
                                        show={modalShow}
                                        onHide={() => {
                                            setModalShow(false);
                                            setActiveTab("templates");
                                        }}
                                        testimonials={testimonials}
                                    />

                                </Tab>
                                <Tab eventKey="templates" title="Templates">
                                    <div className='edit_testimonial_list'>
                                        <ul className='p-0 m-0'>
                                            <li className={allowedWidgets>=1?'':'d-none'}>
                                                <Link onClick={(e) => {
                                                    e.preventDefault();
                                                    handleTemplate(1);
                                                }}>
                                                    <img src={Widget_1} alt="widget" />
                                                </Link>
                                            </li>
                                            <li className={allowedWidgets>=2?'':'d-none'}>
                                                <Link onClick={(e) => {
                                                    e.preventDefault();
                                                    handleTemplate(2);
                                                }}>
                                                    <img src={Widget_2} alt="widget" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </Tab>
                                {/* customization */}
                                <Tab eventKey="design" title="Design">
                                    <div className='edit_testimonial_list'>
                                        <div className='style_wrap'>

                                            <div className='color_inner_wrapper'>
                                                <div className='box'>
                                                    <h5>Background Color</h5>
                                                    <div className='colorpicker' onClick={handleClick}>
                                                        <div className='color' style={{ backgroundColor: color }} />
                                                        {showPicker && (
                                                            <SketchPicker color={color} onChange={handleChange} />
                                                        )}
                                                        <p>{color}</p>
                                                    </div>
                                                </div>
                                                <div className='box mt-3'>
                                                    <h5>Text Color</h5>
                                                    <div className='colorpicker' onClick={handleClickText}>
                                                        <div className='color' style={{ backgroundColor: textColor }} />
                                                        {showPickerText && (
                                                            <SketchPicker color={textColor} onChange={handleChangeText} />
                                                        )}
                                                        <p>{textColor}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <Form>
                                                <Form.Group className="mb-3 mt-3" controlId="formBasicLogoposition">
                                                    <Form.Label>Font</Form.Label>
                                                    <Form.Select value={fontFamily} onChange={handleFontChange}>
                                                        <option value="'Montserrat', sans-serif">'Montserrat', sans-serif</option>
                                                        <option value="Arial">Arial</option>
                                                        <option value="Verdana">Verdana</option>
                                                        <option value="Courier New">Courier New</option>
                                                        <option value="'Dancing Script', cursive">Dancing Script, cursive</option>
                                                        
                                                    </Form.Select>
                                                </Form.Group>

                                                <Form.Group className="mb-3 mt-3" controlId="formBasicLogoposition">
                                                    <Form.Label>Border Radius</Form.Label>
                                                    <Form.Select value={borderRadius} onChange={handleBorderRadius}>
                                                        <option value="0px">None</option>
                                                        <option value="0.375rem">Smaill</option>
                                                        <option value="0.5rem">Medium</option>
                                                        <option value="0.75rem">Large</option>
                                                        <option value="1rem">Extra Large</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="settings" title="Settings">
                                    <div className='edit_testimonial_list'>
                                        <div className='swtich_wrapper'>
                                            {/* <Form>
                                                <Form.Group className="mb-3 mt-3" controlId="formBasicLogoposition">
                                                    <Form.Label>Spacing</Form.Label>
                                                    <Form.Select value={margin} onChange={handleMargin}>
                                                        <option value="0px -3px">Small</option>
                                                        <option value="0px -1px">Medium</option>
                                                        <option value="0px 1px">Large</option>
                                                        <option value="0px 3px">Extra Large</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Form> */}
                                            <Form className='custom_swtich'>
                                                <Form.Group className="mt-3" controlId="formBasicSwitch2">
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        label="Show avatars"
                                                        checked={showAvatars}
                                                        onChange={() => setShowAvatars(!showAvatars)}
                                                    />
                                                </Form.Group>
                                            </Form>
                                            <Form className='custom_swtich'>
                                                <Form.Group className="mt-3" controlId="formBasicSwitch3">
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        label="Show rating"
                                                        checked={showRatings}
                                                        onChange={() => setShowRatings(!showRatings)}
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                            {/* sidebar */}
                        </div>
                        <WidgetSection
                            testimonials={testimonials}
                            activeTemplate={activeTemplate}
                            avatar={showAvatars}
                            date={showDatesAndLikes}
                            rating={showRatings}
                            background={color}
                            color={textColor}
                            fontFamily={fontFamily}
                            borderRadius={borderRadius}
                            margin={margin}
                        />
                    </div>
                </div>
            </Container>
        </>
    );
}

