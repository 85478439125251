import React, { Component } from 'react';
import { Player, ControlBar } from 'video-react';
// import { Button } from 'reactstrap';

const sources = {
  sintelTrailer: 'http://media.w3.org/2010/05/sintel/trailer.mp4',
  bunnyTrailer: 'http://media.w3.org/2010/05/bunny/trailer.mp4',
  bunnyMovie: 'http://media.w3.org/2010/05/bunny/movie.mp4',
  test: 'http://media.w3.org/2010/05/video/movie_300.webm'
};

function videoPlayTime(params,accessToken) {

  fetch(process.env.REACT_APP_API_URL+'video-play-time', {
    method: 'POST',
    headers: {
      authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  })
  .then(data => data.json())
  .then(response => {
    console.log('video play time');
    console.log(response);
  })
}

export default class VideoPlayer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      source: sources.bunnyMovie
    };

    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.load = this.load.bind(this);
    this.changeCurrentTime = this.changeCurrentTime.bind(this);
    this.seek = this.seek.bind(this);
    this.changePlaybackRateRate = this.changePlaybackRateRate.bind(this);
    this.changeVolume = this.changeVolume.bind(this);
    this.setMuted = this.setMuted.bind(this);
  }

  componentDidMount() {

    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    let counter = 1;
    let prev_id = null;
    let old_watch_time = null;
    const access_token = localStorage.getItem('accessToken');

    this.interval = setInterval(() => {
      const player_ = this.state.player;
      if(player_){
        let current_time = player_.currentTime;
        let duration = player_.duration;
        let video_id = this.props.id;

        if(current_time!==0){
          if(counter===1){
            prev_id = video_id;
            counter = counter + 1;
            let type = 'new';
            videoPlayTime({
                video_id,
                current_time,
                duration,
                counter,
                type
            },access_token);

          }else if(prev_id!==video_id){
            prev_id = video_id;
            counter = counter + 1;
            let type = 'new';
            videoPlayTime({
                video_id,
                current_time,
                duration,
                counter,
                type
            },access_token);
          }else{
            if(old_watch_time!==current_time){
              if(old_watch_time<current_time){
                let type = 'update';
                videoPlayTime({
                    video_id,
                    current_time,
                    duration,
                    counter,
                    type
                },access_token);
              }else{
                counter = counter + 1;
                let type = 'new';
                videoPlayTime({
                  video_id,
                  current_time,
                  duration,
                  counter,
                  type
                },access_token);
              }
            }
            old_watch_time = current_time;
          }
        }
      }
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setMuted(muted) {
    return () => {
      this.player.muted = muted;
    };
  }

  handleStateChange(state) {
    this.setState({
      player: state
    });
  }

  play() {
    this.player.play();
  }

  pause() {
    this.player.pause();
  }

  load() {
    this.player.load();
  }

  changeCurrentTime(seconds) {
      return () => {
        const { player } = this.player.getState();
        this.player.seek(player.currentTime + seconds);
    };
  }

  seek(seconds) {
    return () => {
      this.player.seek(seconds);
    };
  }

  changePlaybackRateRate(steps) {
    return () => {
      const { player } = this.player.getState();
      this.player.playbackRate = player.playbackRate + steps;
    };
  }

  changeVolume(steps) {
    return () => {
      const { player } = this.player.getState();
      this.player.volume = player.volume + steps;
    };
  }

  changeSource(name) {
    return () => {
      this.setState({
        source: sources[name]
      });
      this.player.load();
    };
  }

  render() {
    return (
      <div>
        <Player
          ref={player => {
            this.player = player;
          }}
        >
          <source src={this.props.src} />
          <ControlBar autoHide={false} />
        </Player>  
        
      </div>
    );
  }
}