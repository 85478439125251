import React, { useEffect, useState } from "react";
import VideoPlayer from "./VideoPlayer";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import VimeoPlayer from "./VimeoPlayer";

function Widgets(props) {
  const [data, setData] = useState([]);
 
  useEffect(() => {
    setData(props.data)
  }, [props]);


  return (
    <>
      <div className="masonry mt-0">
          <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >                   
              <Masonry gutter={10}>                    
                {data.map((value, index) => (
                  <div className='box position-relative' key={index}>
                    <input type="checkbox" name='radio' onClick={props.setCurrentThemefunction.bind(this)} value={value.id} />
                    <label className='custom_radio'></label>
                    <div className="widgets flex">
                      <div className="widgets_one_inner" >
                        <div className="customer_image text-start">
                        {
                            value.reviewer_picture
                            ?
                            <img src={value.reviewer_picture} alt="customerimage" className="rounded-circle" />
                            :
                            <img src="https://senjaio.b-cdn.net/public/media/default-avatar_EWOV398Bs.jpeg?width=48&height=48" alt="customerimage" className="rounded-circle" />
                        }
                        </div>
                        <div className="customer_name text-start pt-1">
                          {value.reviewer_name}
                        </div> 
                        <div className="quote text-start">
                          
                          <p className="mb-0">
                              {value.text}
                          </p>
                          {value.files ?
                              <div className='upload_post_image mt-3'>
                                  {
                                      value.platform_name === 'proof_pudding'
                                          ?
                                          <VideoPlayer src={value.media[0].file_url} id={value.media[0].id} />
                                          :
                                          value.media[0].type === 'photo'
                                              ?
                                              <img src={value.media[0].file_url} alt="image" className='w-100' />
                                              :
                                              value.platform_name === 'vimeo'
                                              ?
                                              <VimeoPlayer key={value.review_id} videoId={value.review_id} />
                                              :
                                              <VideoPlayer src={value.media[0].file_url} id={value.media[0].id} />
                                  }
                              </div>
                              : ''}
                          <p className="mb-0 mt-2">
                              {value.review_date_2}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Masonry>
          </ResponsiveMasonry>                    
      </div>    

    </>
  );
}
 
export default Widgets;