import { Card } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import more from "../../Images/more.svg";
import code from "../../Images/code.svg";
import duplicate from "../../Images/duplicate.svg";
import remove from "../../Images/remove.svg";
import edit from "../../Images/edit.svg";
import { Link, useNavigate } from 'react-router-dom';
import React, { useRef } from 'react';
import { toast } from 'react-toastify';

export default function SavedWidget(props) {

    const navigate = useNavigate();
    const textRef = useRef(null);

    const handleCopyClick = () => {
        const text = textRef.current.value;
        navigator.clipboard.writeText(text).then(() => {
            toast.success("Link copied to clipboard");
        });
    };
    const editWidgetTemplate = (widgetName, widget_id) => {
        navigate('/social-proof/widgets/template?widget=' + widgetName + '&id=' + btoa(widget_id));
    }
    const handleDelete = (id) => {
        props.onDeleteClick(id);
    }
    const handleDuplicate = (id) => {
        props.onDuplicateClick(id);
    }

    return (
        <Card className='mt-3'>
            <div className='card_box_wrapper'>
                <h4>Your Widgets</h4>
                <p>Embed testimonials on your site without code.</p>
                    <div className="scroll">
                      <div className="scroller_inner">
                            <ul>
                                {props.widgets && props.widgets.map((widget, key) => {

                                    let url = process.env.REACT_APP_WIDGET_WALL_URL + widget.unique_id;
                                    let embed_code = '<iframe src=' + widget.widget_url + ' height="500" width="100%"></iframe>';

                                    return (
                                        <li key={key}>
                                            <Link to="">
                                                <div className='title'>{widget.widget_name}</div>
                                                <img src={process.env.REACT_APP_WIDGET_URL + widget.thumbnail} alt={widget.widget_name} />
                                            </Link>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    <img src={more} alt="more" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="" onClick={() => editWidgetTemplate(widget.widget_name, widget.id)}><img src={edit} alt="edit" className='me-2' />Edit</Dropdown.Item>

                                                    <textarea ref={textRef} value={embed_code} style={{ display: 'none' }} />

                                                    <Dropdown.Item href="" onClick={handleCopyClick}>
                                                        <img src={code} alt="code" className='me-2' />Copy Code
                                                    </Dropdown.Item>

                                                    <Dropdown.Item href="" onClick={() => handleDuplicate(widget.id)}><img src={duplicate} alt="duplicate" className='me-2' />Duplicate</Dropdown.Item>
                                                    <Dropdown.Item href="" onClick={() => handleDelete(widget.id)}><img src={remove} alt="remove" className='me-2' />Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </li>
                                    );
                                })}
                            </ul>
                      </div>
                    </div>                  
            </div>
        </Card>
    );
}