import { Container, Col, Button, Form } from 'react-bootstrap';
import recordvideo from "../Images/recordvideo.png";
import uploadicon from "../Images/uploadicon.png";
import star from "../Images/star.png";
import React, {useState,useEffect} from 'react';
import { useNavigate } from "react-router-dom";

function Dashboard() {
    const [shortName, setShortName] = useState('');
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const business_name = urlParams.get('business_name');
    const [user,setUser] = useState();
    const [serviceQuestions, setServiceQuestions] = useState([]);
    const [displayProofPuddingBrand, setDisplayProofPuddingBrand] = useState('none');

    function getUserNameInitials(name_) {
        const slices = name_.split(' ');
        let name_initials = '';
        slices.forEach(item => {
            name_initials = name_initials + '' + item.charAt(0).toUpperCase();
        });
        setShortName(name_initials);
    }

    async function getBusiness(params) {
        return fetch(process.env.REACT_APP_API_URL + 'get-business', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(data => data.json())
            .then(response => {
                setUser(response.data);
                if(response.data){
                    getUserNameInitials(response.data.name);
                    setServiceQuestions(response.data.service_questions);
                    
                    if(response.data.userplan.plan.type=='paid'){
                        setDisplayProofPuddingBrand('none');
                    }else{
                        setDisplayProofPuddingBrand('block');
                    }
                }
            });
    }

    useEffect(() => {
        getBusiness({business_name});
    }, []);

  return (
    <>  
        <Container fluid className="p-0">
            <div className='add_review'>
                <Col>
                    <div className='add_reviews_inner position-relative m-auto'>
                        <div className='head_wrap d-flex justify-content-between'>
                            {
                              user && user.image
                              ?
                              <img className='logo_review' src={user.image} />
                              :
                              <div className='logo_review'>{shortName?shortName:'PP'}</div>
                            }
                            <h4 className='mt-0'>{business_name?business_name:'Business Name'}</h4>
                        </div>
                        <div className='common_content_wrapper pt-2'>
                            <h3 className='text-center'>Submit Video Review</h3>
                            <p style={{whiteSpace: "pre-wrap"}}>{serviceQuestions}</p>
                            <ul>
                                {/*{serviceQuestions && serviceQuestions.map((ques,key)=>{
                                    return <li key={key}>{ques.text}</li>
                                })}*/}
                            </ul>
                            <div className='common_btn_wrapper mt-4'>
                                <Button className='mb-2' onClick={() => (navigate('/record-a-video?business_name=' + business_name))}><img src={ recordvideo } alt="recordvideo"/>Record a video</Button>

                                <Button className='mb-2 custom_upload' style={{backgroundColor:'#DCE5E1'}} onClick={() => (navigate('/upload-video?business_name=' + business_name))}><img src={uploadicon} alt="uploadicon" className='me-2'/>Upload video</Button>
                            </div>
                        </div>
                        <div className='powered_by' style={{display:displayProofPuddingBrand}}>
                            <h6 className='mb-0'>Powered by Proof Pudding<img src={ star } alt="star" className='ms-1'/></h6>
                        </div>
                    </div>
                </Col>
            </div>
        </Container>
    </>
  );
}
export default Dashboard;