import { Col, Row, Container, Card, Form, Button, FormGroup, InputGroup } from 'react-bootstrap';
import Sidebar from './Sidebar';
import { useState, useEffect, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './Header'
import showPwdImg from '../Images/show-password.svg';
import hidePwdImg from '../Images/hide-password.svg';
import { useForm } from 'react-hook-form'
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

function Generalresetpassword() {

    const navigate = useNavigate();
    const accessToken = localStorage.getItem('accessToken');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isRevealPwd2, setIsRevealPwd2] = useState(false);
    const [isRevealPwd3, setIsRevealPwd3] = useState(false);

    const formSchema = Yup.object().shape({
        old_password:Yup.string().required('Old Password is required'),
        password: Yup.string()
            .notOneOf([Yup.ref('old_password')], 'New Password not equal to old Password')
            .required('New Password is required')
            .min(8, 'Password must be at 8 character'),
        confirmPwd: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password')], 'Confirm Password must be equal to New Password'),
    })

    const formOptions = { resolver: yupResolver(formSchema) }
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    function onSubmit(data) {
        const old_pass=data.old_password;
        const pass = data.password;
        const cpass = data.confirmPwd;
        console.log(data);

        const response = ForgotPasswordConfirm({
            old_pass,
            pass,
            cpass,
        },accessToken);
    }

    async function ForgotPasswordConfirm(credentials,access_Token) {
        return fetch(process.env.REACT_APP_API_URL + 'settings-reset-password', {
            method: 'POST',
            headers: {
                authorization: 'Bearer ' + access_Token,
                Accept: 'application/json',
            },
            body:new URLSearchParams(credentials)
        })
        .then(data => data.json())
        .then((response) => {
            
            if (response.status) {
                toast.success(response.message);
                
                setTimeout(() => {
                    navigate('/settings')
                }, 500);
            } else {
                toast.error(response.message);
            }
        })
    }

    return (
        <>
            <ToastContainer />
            <Container fluid className="pe-0">
                <Sidebar />
                <div className='wrapper'>
                    <Header  title="Change Password"/>

                    <div className='inner_content_wrapper '>
                        <Row>
                            <Col>
                                <Card>
                                    <div className='profilesetup'>
                                        {/* <h4 className='mb-4'>Reset Password</h4> */}
                                        <div className='profileform settings-reset-password'>
                                            <div className='form_wrapper pt-0 mt-3'>
                                                <Form onSubmit={handleSubmit(onSubmit)} >
                                                    <Row>
                                                        <Col md="6">
                                                            <Form.Group className="mb-3 position-relative  m-auto" controlId="formBasicNewPassword">
                                                                <Form.Label>Old Password</Form.Label>

                                                                <InputGroup hasValidation>
                                                                    <Form.Control
                                                                        placeholder="Old Password"
                                                                        aria-describedby="inputGroupPrepend"
                                                                        name="old_password"
                                                                        type={isRevealPwd3 ? "text" : "password"}
                                                                        {...register('old_password')}
                                                                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.old_password?.message}
                                                                    </Form.Control.Feedback>
                                                                </InputGroup>
                                                                <img
                                                                    title={isRevealPwd3 ? "Hide password" : "Show password"}
                                                                    src={isRevealPwd3 ? hidePwdImg : showPwdImg}
                                                                    onClick={() => setIsRevealPwd3(prevState3 => !prevState3)}
                                                                    alt="hide-show-pass"
                                                                    style={{ height: '15px', border: 'none' }}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="6">
                                                            <Form.Group className="mb-3 position-relative m-auto" controlId="formBasicNewPassword">
                                                                <Form.Label>New Password</Form.Label>

                                                                <InputGroup hasValidation>
                                                                    <Form.Control
                                                                        placeholder="New Password"
                                                                        aria-describedby="inputGroupPrepend"
                                                                        name="password"
                                                                        type={isRevealPwd ? "text" : "password"}
                                                                        {...register('password')}
                                                                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.password?.message}
                                                                    </Form.Control.Feedback>
                                                                </InputGroup>
                                                                <img
                                                                    title={isRevealPwd ? "Hide password" : "Show password"}
                                                                    src={isRevealPwd ? hidePwdImg : showPwdImg}
                                                                    onClick={() => setIsRevealPwd(prevState => !prevState)}
                                                                    alt="hide-show-pass"
                                                                    style={{ height: '15px', border: 'none' }}

                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="6">
                                                            <Form.Group className="mb-3 position-relative m-auto" controlId="formBasicConfirmPassword">
                                                                <Form.Label>Confirm Password</Form.Label>

                                                                <InputGroup hasValidation>
                                                                    <Form.Control
                                                                        placeholder="Confirm Password"
                                                                        aria-describedby="inputGroupPrepend"

                                                                        type={isRevealPwd2 ? "text" : "password"}
                                                                        name="confirmPwd"
                                                                        {...register('confirmPwd')}
                                                                        className={`form-control ${errors.confirmPwd ? 'is-invalid' : ''}`}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.confirmPwd?.message}
                                                                    </Form.Control.Feedback>
                                                                </InputGroup>
                                                                <img
                                                                    title={isRevealPwd2 ? "Hide password" : "Show password"}
                                                                    src={isRevealPwd2 ? hidePwdImg : showPwdImg}
                                                                    onClick={() => setIsRevealPwd2(prevState2 => !prevState2)}
                                                                    alt="hide-show-pass"
                                                                    style={{ height: '15px', border: 'none' }}
                                                                />
                                                            </Form.Group>                                                        
                                                        </Col>
                                                        <Col md="12">
                                                            <Button variant="primary" type="submit" className='m-auto mb-4 mt-3'>
                                                                Update Password
                                                            </Button>
                                                        </Col>                                                                                                                                                                      
                                                    </Row>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default Generalresetpassword;