import { useEffect, useState } from "react";
import {useParams,useNavigate} from "react-router-dom";
import LoveTemplate from './LoveTemplate'
import star from "../../Images/star.png";

function Publish(props) {

    const navigate = useNavigate();

    const [data,setData] = useState([]);
    const [template,setTemplate] = useState('');
    const [logoimage,setLogoImage] = useState('');
    const [logosize,setLogoSize] = useState('');
    const [Bgcolor,setBgcolor] = useState('');
    const [buttonColor,setButtonColor] = useState('');
    const [reviewColor,setReviewColor] = useState('');
    const [fontColor,setFontColor] = useState('');
    const [fontFamily,setFontFamily] = useState('');
    const [fontSize,setFontSize] = useState('');
    const [reviewFont,setReviewFont] = useState('');
    const [pageTitle,setPageTitle] = useState();
    const [subtitle,setSubtitle] = useState();
    const [ctaTitle,setCtaTitle] = useState();
    const [ctaURL,setCtaURL] = useState();
    const [displayProofPuddingBrand,setDisplayProofPuddingBrand] = useState('none');
    const [user,setUser] = useState();

    let { slug } = useParams();
    
    function GetWallOfLove(slug)
    {
        fetch(process.env.REACT_APP_API_URL + 'get-wall-of-love-testimonial/'+slug, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
          }).then(response => response.json())
            .then(data => {
                setData(data.data)
                setTemplate(data.wall.template);
                setLogoImage(data.wall.logo_image!=null?data.wall.logo_image:'');
                setLogoSize(data.wall.logo_size);
                setBgcolor(data.wall.primary_color);

                setPageTitle(data.wall.page_title);
                setSubtitle(data.wall.subtitle);
                setCtaTitle(data.wall.cta_title);
                setCtaURL(data.wall.cta_url);
                setButtonColor(data.wall.button_color);
                setReviewColor(data.wall.review_color);
                setFontColor(data.wall.font_color);
                setFontFamily(data.wall.font_family);
                setFontSize(data.wall.font_size);
                setReviewFont(data.wall.review_size);
                setUser(data.user);
                if(data.user && data.user.userplan.plan.type=='paid'){
                    setDisplayProofPuddingBrand('none');
                }else{
                    setDisplayProofPuddingBrand('block');
                }
             });
    }

    useEffect(()=>{
        GetWallOfLove(slug);
    },[]);

    return (
        <> 
        <div class="powered_by" style={{bottom:'auto',display:displayProofPuddingBrand}}>
            <h6 class="mb-0">Powered by Proof Pudding<img src={star} alt="star" class="ms-1"/></h6>
        </div>
        <div className='container-fluid'>
             {data.length > 0 ? 
                <LoveTemplate 
                    title={template} 
                    testimonials={data} 
                    logoimage={logoimage} 
                    logosize={logosize} 
                    Bgcolor={Bgcolor}
                    pageTitle={pageTitle}
                    subtitle={subtitle}
                    ctaTitle={ctaTitle} 
                    ctaURL={ctaURL} 
                    showDelete={false}
                    buttonColor={buttonColor}
                    reviewColor={reviewColor}
                    fontColor={fontColor}
                    fontFamily={fontFamily}
                    authorFont={fontSize}
                    reviewFont={reviewFont}
                />
             :'Loading...'}
        </div>
        </>
    );
}

export default Publish;