import { Container, Col, Button, Form } from 'react-bootstrap';
import avtar from "../Images/avtar.png";
import star from "../Images/star.png";
import uploadicon from "../Images/uploadicon.png";
import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

async function addReviewer(params) {
    return fetch(process.env.REACT_APP_API_URL + 'add-reviewer', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    })
        .then(data => data.json())
}

function Dashboard() {
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [shortName, setShortName] = useState('');
    const urlParams = new URLSearchParams(window.location.search);
    const business_name = urlParams.get('business_name');
    const id = urlParams.get('id');
    const [validated, setValidated] = useState(false);
    const [username, setUsername] = useState();
    const [email, setEmail] = useState();
    const [businessName, setBusinessName] = useState();
    const profileImage = useRef(null);
    const [showfile, setShowFile] = useState([]);
    const [file, setFile] = useState(null);
    const [displayProofPuddingBrand, setDisplayProofPuddingBrand] = useState('none');

    function getUserNameInitials(name_) {
        const slices = name_.split(' ');
        let name_initials = '';
        slices.forEach(item => {
            name_initials = name_initials + '' + item.charAt(0).toUpperCase();
        });
        setShortName(name_initials);
    }
    function handleFileChange(e) {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            setFile(reader.result);
        }
        setShowFile(URL.createObjectURL(e.target.files[0]));
    }
    async function getBusiness(params) {
        return fetch(process.env.REACT_APP_API_URL + 'get-business', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(data => data.json())
            .then(response => {
                setUser(response.data);
                if (response.data) {
                    if(response.data.userplan.plan.type=='paid'){
                        setDisplayProofPuddingBrand('none');
                    }else{
                        setDisplayProofPuddingBrand('block');
                    }
                    getUserNameInitials(response.data.name);
                }
            });
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            if (!business_name && !id){
                return toast.error('Something went wrong!');
            }
            const response = await addReviewer({
                id,
                username,
                email,
                businessName,
                file
            });
            if(response.status){
                navigate('/success?business_name='+business_name);
            }else{
                console.log('error');
                console.log(response.message);
            }
        }
    };
    useEffect(() => {
        getBusiness({ business_name });
    }, []);

    return (
        <>
            <ToastContainer/>
            <Container fluid className="p-0">
                <div className='add_review'>
                    <Col>
                        <div className='add_reviews_inner position-relative m-auto'>
                            <div className='head_wrap d-flex justify-content-between'>
                                {
                                    user && user.image
                                        ?
                                        <img className='logo_review' src={user.image} />
                                        :
                                        <div className='logo_review'>{shortName ? shortName : 'PP'}</div>
                                }
                                <h4 className='mt-0'>{business_name ? business_name : 'Business Name'}</h4>
                            </div>
                            <div className='common_content_wrapper pt-2'>
                                <h3 className='text-center mb-0'>Almost Finished!</h3>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="formBasicname">
                                        <Form.Label className='mb-0'>Your Name*</Form.Label>
                                        <Form.Control
                                            type="text"
                                            Value={username}
                                            required
                                            onChange={e => setUsername(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label className='mb-0'>E-mail*</Form.Label>
                                        <Form.Control
                                            type="email"
                                            Value={email}
                                            required
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicBusiness">
                                        <Form.Label className='mb-0'>Business Name (optional)</Form.Label>
                                        <Form.Control
                                            type="name"
                                            Value={businessName}
                                            onChange={e => setBusinessName(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-4" controlId="formBasicEmail">
                                        <Form.Label className='mb-0'>Your Avatar (optional)</Form.Label>
                                        <div className='d-flex align-items-center'>
                                            {
                                                showfile.length
                                                    ?
                                                    <img className='profile_name' src={showfile} />
                                                    :
                                                    <img src={avtar} alt="avtar" className='me-2' />
                                            }
                                            <div className='custom_upload d-flex align-items-center'>
                                                <Form.Control
                                                    type="file"
                                                    name="profileImage"
                                                    ref={profileImage}
                                                    onChange={handleFileChange}
                                                    accept="image/jpg, image/jpeg,image/png"
                                                />
                                                <img src={uploadicon} alt="uploadicon" className='me-2' />
                                                <Form.Label className='mb-0'>Pick an image</Form.Label>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <div className='common_btn_wrapper mb-3'>
                                        <Button type='submit'>Submit</Button>
                                    </div>
                                </Form>
                            </div>
                            <div className='powered_by' style={{display:displayProofPuddingBrand}}>
                                <h6 className='mb-0'>Powered by Proof Pudding<img src={star} alt="star" className='ms-1' /></h6>
                            </div>
                        </div>
                    </Col>
                </div>
            </Container>
        </>
    );
}
export default Dashboard;