import testimonials from "../Images/testimonials.svg";
import templates from "../Images/templates.svg";
import designs from "../Images/designs.svg";
import settings from "../Images/settings.svg";
import Modaltestimonial from "./Modaltestimonial";
import React from "react";
import { Link } from 'react-router-dom';

export default function StudioSidebar(props) {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <ul className='p-0 m-0'>
            <li>
                <Link to="" onClick={() => setModalShow(true)}>
                    <div className='image_wrap position-relative'>
                        <img src={testimonials} alt="testimonials" />
                    </div>
                    <label>Testimonials</label>
                </Link>
                <Modaltestimonial
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </li>
            <li>
                <Link to="/social-proof/template" className={props.page=='template'?'active':''}>
                    <div className='image_wrap position-relative'>
                        <img src={templates} alt="templates" />
                    </div>
                    <label>Templates</label>
                </Link>
            </li>
            <li className="d-none">
                <Link to="/social-proof/design" className={props.page=='design'?'active':''}>
                    <div className='image_wrap position-relative'>
                        <img src={designs} alt="designs" />
                    </div>
                    <label>Design</label>
                </Link>
            </li>
            <li className="d-none">
                <Link to="/social-proof/settings" className={props.page=='settings'?'active':''}>
                    <div className='image_wrap position-relative'>
                        <img src={settings} alt="settings" />
                    </div>
                    <label>Settings</label>
                </Link>
            </li>
        </ul>
    );
}