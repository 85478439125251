import { Container } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import WallLoveHeader from './WallLoveHeader';
import WallLoveSection from './WallLoveSection';
import { Link } from 'react-router-dom';
import WallTemplateOne from "../../Images/wall_template_1.png";
import WallTemplateTwo from "../../Images/lovewalltwo.png";
import WallTemplateThree from "../../Images/lovewallone.png";
import { Tab, Tabs, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WallLoveModal from "./WallLoveModal";
import upload from "../../Images/upload.svg";
import { SketchPicker } from 'react-color';
import logo from "../../Images/proof-wall-logo.png"
import html2canvas from 'html2canvas';

async function UpdateWallOfLove(data, access_token) {
    return fetch(process.env.REACT_APP_API_URL + 'update-wall-of-love', {
        method: 'POST',
        headers: {
            authorization: 'Bearer ' + access_token,
            Accept: 'application/json',
        },
        body: new URLSearchParams(data)
    })
        .then(data => data.json())
}

export default function SocialProof() {

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const widget = params.get('slug');
    const editWall = params.get('id');
    const accessToken = localStorage.getItem('accessToken');
    const [activeTemplate, setActiveTemplate] = useState('lovewallone');
    const [testimonials, setTestimonials] = useState([]);
    const [color, setColor] = useState('#000000');
    const [buttonColor, setButtonColor] = useState('#FBC343');
    const [reviewColor, setReviewColor] = useState('#f1f1f1');
    const [fontColor, setFontColor] = useState('#000000');
    const [fontFamily, setFontFamily] = useState("'Montserrat', sans-serif");
    const [authorFont, setAuthorFont] = useState("16px");
    const [reviewFont, setReviewFont] = useState("13px");
    const [Bgcolor, setBgColor] = useState('');
    const [activeTab, setActiveTab] = useState('templates');
    const [modalShow, setModalShow] = useState(false);
    const [showPicker, setShowPicker] = useState(false);
    const [showButtonPicker, setShowButtonPicker] = useState(false);
    const [showReviewPicker, setShowReviewPicker] = useState(false);
    const [showFontPicker, setShowFontPicker] = useState(false);
    const [sizevalue, setSizeValue] = useState(0);
    const [logoimage, setLogoImage] = useState('');
    const [file, setFile] = useState(null);
    const [pageTitle, setPageTitle] = useState('Custom Review Page');
    const [ctaTitle, setCtaTitle] = useState('Visit our website');
    const [ctaURL, setCtaURL] = useState('https://mike-viper.rvtechnologies.in');
    const [subtitle, setSubtitle] = useState('See what our customers are saying about us');
    const [MenuOneTitle, setMenuOneTitle] = useState('Website');
    const [MenuOneLink, setMenuOneLink] = useState('Website Link');
    const [MenuTwoTitle, setMenuTwoTitle] = useState('About Us');
    const [MenuTwoLink, setMenuTwoLink] = useState('About Us Link');
    const [MenuThreeTitle, setMenuThreeTitle] = useState('Contact');
    const [MenuThreeLink, setMenuThreeLink] = useState('Contact Link');
    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (tab === 'testimonials') {
            setModalShow(true);
        }
    };
    const handleClick = () => {
        setShowPicker(!showPicker);
    };
    const handleClose = () => {
        setShowPicker(false);
    };
    const handleChangecolor = (newColor) => {
        setBgColor(newColor.hex)
        setColor(newColor.hex);
    };

    const buttonColorPicker = () => {
        setShowButtonPicker(!showButtonPicker);
    };
    const handleButtonClose = () => {
        setShowButtonPicker(false);
    };
    const handleChangeButtonColor = (newColor_) => {
        setButtonColor(newColor_.hex);
    };

    const reviewColorPicker = () => {
        setShowReviewPicker(!showReviewPicker);
    };
    const handleReviewClose = () => {
        setShowReviewPicker(false);
    };
    const handleChangeReviewColor = (newColor__) => {
        setReviewColor(newColor__.hex);
    };

    const fontColorPicker = () => {
        setShowFontPicker(!showFontPicker);
    };
    const handleFontClose = () => {
        setShowFontPicker(false);
    };
    const handleChangeFontColor = (Color_) => {
        setFontColor(Color_.hex);
    };

    const handleFontChange = (event) => {
        setFontFamily(event.target.value);
    };
    const handleAuthorFontChange = (event) => {
        setAuthorFont(event.target.value);
    }
    const handleReviewFontChange = (event) => {
        setReviewFont(event.target.value);
    }

    function handleChangelogoImage(e) {
        setLogoImage(URL.createObjectURL(e.target.files[0]));
        const reader = new FileReader()
        if (e.target.files) {
            reader.readAsDataURL(e.target.files[0])
            reader.onload = () => {
                setFile(reader.result)
            }
        }
    }

    function getTestimonial() {
        fetch(process.env.REACT_APP_API_URL + 'get-testimonial', {
            method: 'Get',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            }
        })
            .then(response => response.json())
            .then(data => {
                setTestimonials(data.data);
                let love_testimonials_ids = localStorage.getItem('love_testimonials_ids');
                if(!editWall && !love_testimonials_ids){
                    let temp_arr = [];
                    data.data.map((val,ind) => {
                        temp_arr.push(val.id);
                    });
                    let new_testimonials_ids = temp_arr.join(", ");
                    localStorage.setItem('love_testimonials_ids',new_testimonials_ids);
                }
            });
    }
    const handleTemplate = (active) => {
        setBgColor('');
        setActiveTemplate(active);
        console.log('selected template');
        console.log(active);
        localStorage.setItem('love_Template', active);
    }
    const handleConvert = async () => {
        const node = document.getElementById('wall_of_love_theme');
        return html2canvas(node)
            .then(canvas => canvas.toDataURL('image/png'))
            .catch((e) => {
                console.log("Catched", e)
            })
    };

    async function SaveData() {
        let wall_id = localStorage.getItem('Wall_id');
        let templete = localStorage.getItem('love_Template');
        let testimonial_ids = localStorage.getItem('love_testimonials_ids');
        let wall_name = localStorage.getItem('walllove_Name');

        let logofile = file;
        let logosize = sizevalue;
        let primarycolor = Bgcolor;

        const thumbnail = await handleConvert();

        const updatedata = {
            'wall_id': wall_id,
            'wall_name': wall_name,
            'templete': templete,
            'thumbnail': thumbnail,
            'logofile': logofile,
            'logosize': logosize,
            'primarycolor': primarycolor,
            'testimonial_ids': testimonial_ids ? testimonial_ids : '',
            'page_title' : pageTitle,
            'subtitle' : subtitle,
            'cta_title' : ctaTitle,
            'cta_url' : ctaURL,
            'menu_one_title' : MenuOneTitle,
            'menu_one_link' : MenuOneLink,
            'menu_two_title' : MenuTwoTitle,
            'menu_two_link' : MenuTwoLink,
            'menu_three_title' : MenuThreeTitle,
            'menu_three_link' : MenuThreeLink,
            'button_color' : buttonColor,
            'review_color' : reviewColor,
            'font_color' : fontColor,
            'font_family' : fontFamily,
            'font_size' : authorFont,
            'review_font' : reviewFont
        };

        const response = UpdateWallOfLove(updatedata, accessToken);

        response.then((data) => {

            if (data.status) {
                toast.success(data.message);
                setPageTitle(data.Wall_id.page_title);
                setSubtitle(data.Wall_id.subtitle);
                setCtaTitle(data.Wall_id.cta_title);
                setCtaURL(data.Wall_id.cta_url);
                setMenuOneTitle(data.Wall_id.menu_one_title);
                setMenuOneLink(data.Wall_id.menu_one_link);
                setMenuTwoTitle(data.Wall_id.menu_two_title);
                setMenuTwoLink(data.Wall_id.menu_two_link);
                setMenuThreeTitle(data.Wall_id.menu_three_title);
                setMenuThreeLink(data.Wall_id.menu_three_link);
            } else {
                toast.error(data.message);
            }
        })
    }

    async function SaveInitThemeImage() {
        const thumbnail = await handleConvert();
        let wall_id = localStorage.getItem('Wall_id');

        fetch(process.env.REACT_APP_API_URL + 'save-wall-love-theme-image', {
            method: 'POST',
            headers: {
                authorization: 'Bearer ' + accessToken,
                Accept: 'application/json',
            },
            body: new URLSearchParams({ 'thumbnail': thumbnail, 'wall_id': wall_id })

        })
            .then(response => response.json())
    }

    const editProofWall = (id, accessToken) => {
        fetch(process.env.REACT_APP_API_URL + 'edit-wall-love', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify({ 'id': id })
        })
            .then(data => data.json())
            .then(data => {
                if (data.status) {
                    console.log('edit template');
                    console.log(data.data.template);
                    let name = btoa(data.data.wall_name);
                    localStorage.setItem('Wall_id', data.data.wall_id);
                    localStorage.setItem('love_Template', data.data.template);
                    localStorage.setItem('walllove_Name', name);
                    localStorage.setItem('love_testimonials_ids', data.data.testimonial_id);
                    localStorage.setItem('Wall_url', data.data.wall_url);

                    setActiveTemplate(data.data.template);

                    setLogoImage(data.data.logo_image != null ? data.data.logo_image : '');
                    setSizeValue(data.data.logo_size);
                    setBgColor(data.data.primary_color);
                    setColor(data.data.primary_color != null && data.data.primary_color != 'null' ? data.data.primary_color : '#000000');

                    setPageTitle(data.data.page_title);
                    setSubtitle(data.data.subtitle);
                    setCtaTitle(data.data.cta_title);
                    setCtaURL(data.data.cta_url);
                    setMenuOneTitle(data.data.menu_one_title);
                    setMenuOneLink(data.data.menu_one_link);
                    setMenuTwoTitle(data.data.menu_two_title);
                    setMenuTwoLink(data.data.menu_two_link);
                    setMenuThreeTitle(data.data.menu_three_title);
                    setMenuThreeLink(data.data.menu_three_link);
                    setButtonColor(data.data.button_color);
                    setReviewColor(data.data.review_color);
                    setFontColor(data.data.font_color);
                    setFontFamily(data.data.font_family);
                    setAuthorFont(data.data.font_size);
                    setReviewFont(data.data.review_size);
                } else {
                    console.log(data.message);
                }
            });
    }

    useEffect(() => {
        getTestimonial();
        let template = localStorage.getItem('love_Template');
        if (template) {
            setActiveTemplate(template);
        }
        localStorage.setItem('walllove_Name', widget ? widget : 'Proof Pudding Wall Love');
        if (editWall) {
            editProofWall(atob(editWall), accessToken);
        } else {
            SaveInitThemeImage();
        }
    }, []);

    return (
        <>
            <ToastContainer />
            <Container fluid className="p-0">
                <div className='edit_wrapper'>
                    <WallLoveHeader 
                        title={widget} 
                        savefunction={SaveData} 
                    />
                    <div className='edit_content_wrapper d-flex'>
                        <div className='edit_sidebar'>
                            <Tabs
                                id="uncontrolled-tab-example"
                                className="mb-3"
                                activeKey={activeTab}
                                onSelect={handleTabClick}
                            >
                                <Tab eventKey="testimonials" title="Testimonials">
                                    <WallLoveModal
                                        show={modalShow}
                                        onHide={() => {
                                            setModalShow(false);
                                            setActiveTab('templates');
                                        }}
                                        testimonials={testimonials}
                                    />
                                </Tab>
                                <Tab eventKey="templates" title="Templates">
                                    <div className='edit_testimonial_list'>
                                        <ul className='p-0 m-0'>
                                            <li>
                                                <Link onClick={(e) => {
                                                    e.preventDefault();
                                                    handleTemplate('lovewallone');
                                                }}>
                                                    <img src={WallTemplateOne} alt="WallTemplateOne" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link onClick={(e) => {
                                                    e.preventDefault();
                                                    handleTemplate('lovewalltwo');
                                                }}>
                                                    <img src={WallTemplateTwo} alt="WallTemplateTwo" />
                                                </Link>
                                            </li>
                                            {/* <li>
                                                <Link onClick={(e) => {
                                                    e.preventDefault();
                                                    handleTemplate('lovewallthree');
                                                }}>
                                                    <img src={WallTemplateThree} alt="WallTemplateThree" />
                                                </Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                </Tab>
                                <Tab eventKey="design" title="Design">
                                    <div className='edit_testimonial_list'>
                                        <div className='logo_wrap'>
                                            <Form>
                                                <Form.Group className="mb-3" controlId="formBasicLogoupload">
                                                    <Form.Label>Logo</Form.Label>
                                                    <div className='custom_upload'>
                                                        <img src={upload} alt="upload" />
                                                        <label>Upload</label>
                                                        <Form.Control type="file" onChange={handleChangelogoImage} />
                                                    </div>
                                                    <div className="row border justify-content-center p-3 mt-2 bg-white rounded">
                                                        <img src={logoimage != '' ? logoimage : logo} />
                                                    </div>
                                                </Form.Group>

                                                <Form.Group className="mb-3 position-relative" controlId="formBasicLogosize">
                                                    <Form.Label>Logo Size</Form.Label>
                                                    <Form.Range value={sizevalue} min='0' max='100' onChange={(e) => { setSizeValue(e.target.value) }} />
                                                    <div className='ranecount'>{sizevalue}</div>
                                                </Form.Group>

                                                <Form.Group className="mb-3 position-relative" controlId="formBasicLogosize">
                                                    <Form.Label>Primary Color</Form.Label>
                                                    <div className="border d-flex lh-lg bg-white rounded p-1" onClick={handleClick}>
                                                        <div className="rounded-circle border border-light mt-1"
                                                            style={{ backgroundColor: color, width: '20px', height: '20px' }}
                                                        />&nbsp;&nbsp;
                                                        <span> {color}</span>

                                                    </div>
                                                    {showPicker && (
                                                        <SketchPicker color={color} onChange={handleChangecolor} onClose={handleClose} />
                                                    )}
                                                </Form.Group>

                                                <Form.Group className="mb-3 position-relative" controlId="formBasicLogosize">
                                                    <Form.Label>Button Color</Form.Label>
                                                    <div className="border d-flex lh-lg bg-white rounded p-1" onClick={buttonColorPicker}>
                                                        <div className="rounded-circle border border-light mt-1"
                                                            style={{ backgroundColor: buttonColor, width: '20px', height: '20px' }}
                                                        />&nbsp;&nbsp;
                                                        <span> {buttonColor}</span>

                                                    </div>
                                                    {showButtonPicker && (
                                                        <SketchPicker color={buttonColor} onChange={handleChangeButtonColor} onClose={handleButtonClose} />
                                                    )}
                                                </Form.Group>

                                                <Form.Group className="mb-3 position-relative" controlId="formBasicLogosize">
                                                    <Form.Label>Review Color</Form.Label>
                                                    <div className="border d-flex lh-lg bg-white rounded p-1" onClick={reviewColorPicker}>
                                                        <div className="rounded-circle border border-light mt-1"
                                                            style={{ backgroundColor: reviewColor, width: '20px', height: '20px' }}
                                                        />&nbsp;&nbsp;
                                                        <span> {reviewColor}</span>

                                                    </div>
                                                    {showReviewPicker && (
                                                        <SketchPicker color={reviewColor} onChange={handleChangeReviewColor} onClose={handleReviewClose} />
                                                    )}
                                                </Form.Group>

                                                <Form.Group className="mb-3 position-relative" controlId="formBasicLogosize">
                                                    <Form.Label>Font Color</Form.Label>
                                                    <div className="border d-flex lh-lg bg-white rounded p-1" onClick={fontColorPicker}>
                                                        <div className="rounded-circle border border-light mt-1"
                                                            style={{ backgroundColor: fontColor, width: '20px', height: '20px' }}
                                                        />&nbsp;&nbsp;
                                                        <span> {fontColor}</span>

                                                    </div>
                                                    {showFontPicker && (
                                                        <SketchPicker color={fontColor} onChange={handleChangeFontColor} onClose={handleFontClose} />
                                                    )}
                                                </Form.Group>
                                                <Form.Group className="mb-3 mt-3" controlId="formBasicLogoposition">
                                                    <Form.Label>Font Family</Form.Label>
                                                    <Form.Select value={fontFamily} onChange={handleFontChange}>
                                                        <option value="'Montserrat', sans-serif">'Montserrat', sans-serif</option>
                                                        <option value="Arial">Arial</option>
                                                        <option value="Verdana">Verdana</option>
                                                        <option value="Courier New">Courier New</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group className="mb-3 mt-3" controlId="formBasicLogoposition">
                                                    <Form.Label>Author Font Size</Form.Label>
                                                    <Form.Select value={authorFont} onChange={handleAuthorFontChange}>
                                                        <option value="16px">Default</option>
                                                        <option value="17px">Medium</option>
                                                        <option value="18px">Large</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group className="mb-3 mt-3" controlId="formBasicLogoposition">
                                                    <Form.Label>Review Font Size</Form.Label>
                                                    <Form.Select value={reviewFont} onChange={handleReviewFontChange}>
                                                        <option value="13px">Default</option>
                                                        <option value="14px">Medium</option>
                                                        <option value="15px">Large</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Form>
                                        </div>
                                        <div className='background_style'>
                                        
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="settings" title="Settings">
                                    <div className='edit_testimonial_list'>
                                        <div className='swtich_wrapper'>
                                            <Form>
                                                <Form.Group className="mb-3 mt-3" controlId="formBasicLogoposition">
                                                    <Form.Label>Page Title</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={pageTitle?pageTitle:''}
                                                        onChange={(e) => setPageTitle(e.target.value)}
                                                        autoComplete='off'
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3 mt-3" controlId="formBasicLogoposition">
                                                    <Form.Label>Subtitle</Form.Label>
                                                    
                                                    <textarea 
                                                        rows={4} 
                                                        cols={15} 
                                                        value={subtitle?subtitle:''} 
                                                        onChange={(e) => setSubtitle(e.target.value)} 
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3 mt-3" controlId="formBasicLogoposition">
                                                    <Form.Label>Menu Item 1</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={MenuOneTitle?MenuOneTitle:''} 
                                                        placeholder="Please enter Menu name"
                                                        onChange={(e) => setMenuOneTitle(e.target.value)}
                                                        autoComplete='off'
                                                    />
                                                     <Form.Control
                                                        type="text"
                                                        value={MenuOneLink?MenuOneLink:''} 
                                                        placeholder="Please enter Menu Link"
                                                        onChange={(e) => setMenuOneLink(e.target.value)}
                                                        autoComplete='off'
                                                    />
                                                     
                                                </Form.Group>
                                                <Form.Group className="mb-3 mt-3" controlId="formBasicLogoposition">
                                                    <Form.Label>Menu Item 2</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={MenuTwoTitle?MenuTwoTitle:''} 
                                                        placeholder="Please enter Menu name"
                                                        onChange={(e) => setMenuTwoTitle(e.target.value)}
                                                        autoComplete='off'
                                                    />
                                                     <Form.Control
                                                        type="text"
                                                        value={MenuTwoLink?MenuTwoLink:''} 
                                                        placeholder="Please enter Menu Link"
                                                        onChange={(e) => setMenuTwoLink(e.target.value)}
                                                        autoComplete='off'
                                                    />
                                                     
                                                </Form.Group>
                                                <Form.Group className="mb-3 mt-3" controlId="formBasicLogoposition">
                                                    <Form.Label>Menu Item 3</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={MenuThreeTitle?MenuThreeTitle:''} 
                                                        placeholder="Please enter Menu name"
                                                        onChange={(e) => setMenuThreeTitle(e.target.value)}
                                                        autoComplete='off'
                                                    />
                                                     <Form.Control
                                                        type="text"
                                                        value={MenuThreeLink?MenuThreeLink:''} 
                                                        placeholder="Please enter Menu Link"
                                                        onChange={(e) => setMenuThreeLink(e.target.value)}
                                                        autoComplete='off'
                                                    />
                                                     
                                                </Form.Group>
 

                                                <Form.Group className="mb-3 mt-3" controlId="formBasicLogoposition">
                                                    <Form.Label>CTA Title</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={ctaTitle?ctaTitle:''}
                                                        onChange={(e) => setCtaTitle(e.target.value)}
                                                        autoComplete='off'
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3 mt-3" controlId="formBasicLogoposition">
                                                    <Form.Label>CTA URL</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={ctaURL?ctaURL:''}
                                                        onChange={(e) => setCtaURL(e.target.value)}
                                                        autoComplete='off'
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                        <WallLoveSection
                            testimonials={testimonials}
                            activeTemplate={activeTemplate}
                            logoimage={logoimage}
                            logosize={sizevalue}
                            Bgcolor={Bgcolor}
                            pageTitle={pageTitle}
                            ctaTitle={ctaTitle}
                            subtitle={subtitle}
                            ctaURL={ctaURL}
                            MenuOneTitle={MenuOneTitle}
                            MenuOneLink={MenuOneLink}
                            MenuTwoTitle={MenuTwoTitle}
                            MenuTwoLink={MenuTwoLink}
                            MenuThreeTitle={MenuThreeTitle}
                            MenuThreeLink={MenuThreeLink}
                            buttonColor={buttonColor}
                            reviewColor={reviewColor}
                            fontColor={fontColor}
                            fontFamily={fontFamily}
                            authorFont={authorFont}
                            reviewFont={reviewFont}
                        />
                    </div>
                </div>
            </Container>
        </>
    );
}

