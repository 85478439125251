import share from "../../Images/share.svg";
import arrowback from "../../Images/arrowback.png";
import { Link } from 'react-router-dom';
import React, { useState, useRef } from "react";
import { toast } from 'react-toastify';
import { Tab, Tabs } from 'react-bootstrap';
import widgetsemoji from "../../Images/widgetsemoji.png";
import arrowexport from "../../Images/arrowexport.svg";
import menu from "../../Images/menu.svg";
import close from "../../Images/close.svg";
import copydata from "../../Images/copydata.svg";
import html2canvas from 'html2canvas';

async function saveWidget(params, access_token) {
    return fetch(process.env.REACT_APP_API_URL + 'save-widget', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        },
        body: JSON.stringify(params)
    }).then(data => data.json())
}

const handleConvert = async () => {
    const node = document.getElementById('testimonial_view');
    return html2canvas(node)
        .then(canvas => canvas.toDataURL('image/png'));
};

function toggleClass() {
    if (document.body.classList.contains('show')) {
        document.body.classList.remove('show');
    } else {
        document.body.classList.add('show');
    }
};

export default function StudioHeader(props) {
    const accessToken = localStorage.getItem('accessToken');
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [editWidget, setEditWidget] = useState(params.get('id'));
    const [widget, setWidget] = useState(null);
    const [openShareOptions, setOpenShareOptions] = useState(false);
    const textRef = useRef(null);
    const [activeTab, setActiveTab] = useState('javascript');
    const url = widget ? widget.widget_url : '';
    const code = '<iframe src='+url+' height="500" width="100%"></iframe>';

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const saveChanges = async () => {
        const template = localStorage.getItem('Widget_Template');
        const testinonials = localStorage.getItem('Widget_Testimonials');
        const widget_name = localStorage.getItem('Widget_Name');

        if (template && testinonials && widget_name) {
            const thumbnail = await handleConvert();
            const response = await saveWidget({
                'template': template,
                'testimonials': testinonials,
                'widget_name': widget_name,
                'thumbnail': thumbnail,
                'id': editWidget,
                'show_date': props.date,
                'show_rating': props.rating,
                'show_avatar': props.avatar,
                'background' : props.background,
                'color' : props.color,
                'font_family' : props.fontFamily,
                'border_radius' : props.borderRadius,
                'spacing' : props.margin
            }, accessToken)
            if (response.status) {
                toast.success(response.message);
                setWidget(response.widget)
            }
        }
    }
    const generateLink = async () => {
        const template = localStorage.getItem('Widget_Template');
        const testinonials = localStorage.getItem('Widget_Testimonials');
        const widget_name = localStorage.getItem('Widget_Name');

        if (template && testinonials && widget_name) {
            const thumbnail = await handleConvert();
            const response = await saveWidget({
                'template': template,
                'testimonials': testinonials,
                'widget_name': widget_name,
                'thumbnail': thumbnail,
                'id': editWidget,
                'show_date': props.date,
                'show_rating': props.rating,
                'show_avatar': props.avatar,
                'background' : props.background,
                'color' : props.color,
                'font_family' : props.fontFamily,
                'border_radius' : props.borderRadius,
                'spacing' : props.margin
            }, accessToken)
            if (response.status) {
                setWidget(response.widget);
                setOpenShareOptions(!openShareOptions);
            }
        }
    }
    const copyCodeORLink = () => {
        if (activeTab == 'javascript') {
            navigator.clipboard.writeText(code).then(() => {
                toast.success("Copied to clipboard");
            });
        } else {
            navigator.clipboard.writeText(url).then(() => {
                toast.success("Copied to clipboard");
            });
        }
    }
    const exportAsPNG = async () => {
        const dataUrl = await handleConvert();
        const temp_link = document.createElement('a');
        temp_link.download = 'widget.png';
        temp_link.href = dataUrl;
        temp_link.click();
    }

    return (
        <>
            <div className='edit_header d-flex justify-content-between align-items-center'>
                <div className='left d-flex'>
                    <div className='back_btn'>
                        <Link to="/social-proof"><img src={arrowback} alt="arrowback" /></Link>
                    </div>
                    <div className='title'>{props.title ? props.title : 'ProofPudding'}</div>
                    <div className="menu_header" onClick={toggleClass}>
                        <img src={menu} alt="menu" />
                        <img src={close} alt="close" />
                    </div>
                </div>
                <div className='right d-flex'>
                    <button className='save_changes common_btn me-2' onClick={saveChanges}>Save changes</button>
                    <button className='share' onClick={generateLink}><img src={share} alt="share" className='me-2' />Share</button>
                </div>
            </div>
            <div className={openShareOptions ? 'share_widget_wrapper' : 'share_widget_wrapper d-none'} style={{ zIndex: '10' }}>
                <div className='header'>
                    <h3><img src={widgetsemoji} alt="widgetsemoji" className='me-2' />Share your widget</h3>
                    <p>Follow the instructions to embed this on your website</p>
                </div>
                <div className='embed-website'>
                    <h4>Embed on your website</h4>
                    <div className='tabs_wrapper'>
                        <div className='copydata' onClick={copyCodeORLink}><img src={copydata} alt="copydata" /></div>
                        <Tabs
                            id="socialProoftabs"
                            activeKey={activeTab}
                            onSelect={handleTabClick}
                            className="mb-2"
                        >
                            <Tab eventKey="javascript" title="Javascript">
                                <p>{code}</p>
                            </Tab>
                            <Tab eventKey="url" title="URL">
                                <p>{url}</p>
                            </Tab>
                        </Tabs>
                    </div>
                    <p>Paste this code snippet where you want to display the embed on your site</p>
                    <button className='export_btn' onClick={exportAsPNG}><img src={arrowexport} alt="arrowexport" className='me-2' />Export as PNG</button>
                    <button className='done_btn' onClick={() => setOpenShareOptions(!openShareOptions)}>Done</button>
                </div>
            </div>
        </>
    );
}