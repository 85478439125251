import { Col, Row, Container, Card } from 'react-bootstrap';
import { React, useState, useEffect } from 'react'
import { Link } from "react-router-dom";

function PrivacyPolicy() {

    const [pageData, setPageData] = useState(null);

    const getData = () => {
        fetch(process.env.REACT_APP_API_URL + 'privacy-policy', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        }).then(response => response.json())
            .then(data => {
                console.log('privacy ');
                console.log(data);
                if (data.status) {
                    setPageData(data.data);
                }
            });
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <div className="policy_wrapper">
                <div className="policy_inner_wrapper">
                        <Card>
                            {
                            pageData
                            ?
                            <>
                                <div className='plans_wrapper'>
                                    <div className='header'>
                                        <h3>{pageData.title}</h3>
                                        <Link to="/settings"><div className="back_btn">Back</div></Link>
                                    </div>
                                </div>
                                <div style={{ padding: '20px 50px' }} dangerouslySetInnerHTML={{ __html: pageData.content }}>
                                </div>
                            </>
                            :
                            <></>
                            }
                        </Card>
                </div>
            </div>
        </>
    );
}
export default PrivacyPolicy;