import { Container, Col, Button } from 'react-bootstrap';
import star from "../Images/star.png";
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

async function addReview(params) {
    return fetch(process.env.REACT_APP_API_URL + 'add-review', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    })
        .then(data => data.json())
}

function Dashboard() {
    const [shortName, setShortName] = useState('');
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const business_name = urlParams.get('business_name');
    const [user, setUser] = useState();
    const [file, setFile] = useState();
    const [message, setMessage] = useState(null);
    const [serviceQuestions, setServiceQuestions] = useState([]);
    const [displayProofPuddingBrand, setDisplayProofPuddingBrand] = useState('none');

    function getUserNameInitials(name_) {
        const slices = name_.split(' ');
        let name_initials = '';
        slices.forEach(item => {
            name_initials = name_initials + '' + item.charAt(0).toUpperCase();
        });
        setShortName(name_initials);
    }

    async function getBusiness(params) {
        return fetch(process.env.REACT_APP_API_URL + 'get-business', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(data => data.json())
            .then(response => {
                setUser(response.data);
                if (response.data) {
                    getUserNameInitials(response.data.name);
                    setServiceQuestions(response.data.service_questions);
                    
                    if(response.data.userplan.plan.type=='paid'){
                        setDisplayProofPuddingBrand('none');
                    }else{
                        setDisplayProofPuddingBrand('block');
                    }
                }
            });
    }
    useEffect(() => {
        getBusiness({ business_name });
    }, []);

    const uploadFile = async () => {
        if (file) {
            const response = await addReview({
                'message': message,
                'business_name': business_name,
                'selectedFile': file
            });
            if (response.status) {
                navigate('/write-a-review-final?business_name=' + business_name + '&id=' + btoa(response.data.id));
            }
        }
    }
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.onload = function () {
                setFile(reader.result);
            };
            reader.readAsDataURL(file);
        });
    }
    return (
        <>
            <Container fluid className="p-0">
                <div className='add_review'>
                    <Col>
                        <div className='add_reviews_inner position-relative m-auto'>
                            <div className='head_wrap d-flex justify-content-between'>
                                {
                                    user && user.image
                                        ?
                                        <img className='logo_review' src={user.image} />
                                        :
                                        <div className='logo_review'>{shortName ? shortName : 'PP'}</div>
                                }
                                <h4 className='mt-0'>{business_name ? business_name : 'Business Name'}</h4>
                            </div>
                            <div className='common_content_wrapper pt-2'>
                                <h3 className='text-center'>Upload Video Review</h3>
                                <p style={{whiteSpace: "pre-wrap"}}>{serviceQuestions}</p>
                               { /* <ul>
                                    {serviceQuestions && serviceQuestions.map((ques, key) => {
                                        return <li key={key}>{ques.text}</li>
                                    })}

                                </ul>  */ }
                                <div className='common_btn_wrapper mt-4'>
                                    <div className='choose_file position-relative'>
                                        <input
                                            type="file"
                                            accept="video/*"
                                            onChange={handleFileUpload}
                                        />
                                        <label>Choose File</label>
                                    </div>
                                    <Button className='mb-4' onClick={uploadFile}>Submit</Button>
                                </div>
                            </div>
                            <div className='powered_by' style={{display:displayProofPuddingBrand}}>
                                <h6 className='mb-0'>Powered by Proof Pudding<img src={star} alt="star" className='ms-1' /></h6>
                            </div>
                        </div>
                    </Col>
                </div>
            </Container>
        </>
    );
}
export default Dashboard;