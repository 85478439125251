import create from "../Images/create.svg";
import imgs from "../Images/imgs.svg";
import widgets from "../Images/widgets.svg";
import lovewall from "../Images/lovewall.svg";
import save from "../Images/save.svg";
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function SocialProofHeader(props) {
    return (
        <Col md="12">
            <div className='tabs_links'>
                <ul className='d-flex'>
                    <li>
                        <Link to="/social-proof" className={props.active === 'create' ? 'active' : ''}><img src={create} alt="create" className='me-2' />Create</Link>
                    </li>
                    <li>
                        <Link to="/social-proof/images" className={props.active === 'images' ? 'active' : ''}><img src={imgs} alt="imgs" className='me-2' />Social Images</Link>
                    </li>
                    <li>
                        <Link to="/social-proof/widgets" className={props.active === 'widgets' ? 'active' : ''}><img src={widgets} alt="widgets" className='me-2' />Widgets</Link>
                    </li>
                    <li>
                        <Link to="/social-proof/proof-wall" className={props.active === 'proof-wall' ? 'active' : ''}><img src={lovewall} alt="lovewall" className='me-2' />Custom Review Page</Link>
                    </li>
                    <li>
                        <Link to="/social-proof/Saved" className={props.active === 'saved' ? 'active' : ''}><img src={save} alt="save" className='me-2' />Saved</Link>
                    </li>
                </ul>
            </div>
        </Col>
    );
}