import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';

export default function AddProject(props) {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken');

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      props.saveProject();
    }
  };

  return (
    <>
      <Modal {...props} size="lg" onKeyDown={handleKeyDown} aria-labelledby="contained-modal-title-vcenter" centered className='add_tile'>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create a new project
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Create a new project to collect and store testimonials</h5>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="project_name"
                placeholder="Proof Pudding"
                onChange={(e) => props.setProjectName(e.target.value)}
                required
                autoComplete="off"
              />
            </Form.Group>
          </Form>
          <Button onClick={props.saveProject}>Submit</Button>
        </Modal.Body>
      </Modal>
    </>
  );
}