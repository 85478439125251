import rightup from "../Images/rightup.png";
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';

export default function UserPlan(props) {

    const accessToken = localStorage.getItem('accessToken');

    return (
        <Col lg="6">
            <div className='plan_info'>
                <div className='d-flex align-items-center justify-content-between plan_info_inner'>
                    {
                        props.plan ?
                        <>
                            <div className='left'>
                                <div className='plan_name'>{props.plan.plan_name} <label>{props.plan.plan_testimonials_number ? props.plan.plan_testimonials_number + ' businesses/brands' : props.plan.plan_business_projects_number + ' businesses/brands'}</label></div>
                                { /* <label><div dangerouslySetInnerHTML={{ __html: props.plan.plan_description }} /> </label> */ }
                            </div>
                            <div className='right'>
                                <div className='price'>${props.plan.plan_price?props.plan.plan_price:0}<label></label></div>
                            </div>
                        </>
                        :
                        ''
                    }
                </div>
                <div className='upgrade_plan'>
                    <Link to="/settings/upgrade-plan" className='upgrade_plan_btn'>Upgrade plan<img src={rightup} alt="menu" className='ms-2' /></Link>
                </div>
            </div>
        </Col>
    );
}