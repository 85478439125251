import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/style.css'
import './css/videoplayer.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Signup from "./components/Signup";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import RegisterEmailVerify from "./components/RegisterEmailVerify";
import Dashboard from "./components/Dashboard";
import ProofWallCombined from "./components/ProofWallCombined";
import ProofWallSeparate from "./components/ProofWallSeparate";
import GetMoreReviews from "./components/Getmorereviews";
import AddReview from "./components/Addreviews";
import Settings from "./components/Settings";
import Integrations from "./components/Integrations";
import Installthewall from "./components/Installthewall";
import Platformslist from "./components/Platformslist";
import SocialProof from "./components/SocialProof";
import VideoRecorder from "./components/VideoRecorder";
import SuccessPage from "./components/SuccessPage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Terms from "./components/Terms";
import Plans from './components/Plans';
import Generalresetpassword from './components/Generalresetpassword';
import Images from "./components/Images";
import Widgetsmain from "./components/Widgetsmain";
import Walloflove from "./components/Walloflove";
import Saved from "./components/Saved";
import Edittwo from "./components/Edittwo";
import Design from "./components/Design";
import Editsettings from "./components/Editsettings";
import Widgets from "./components/Widgets/Main";
import WidgetDesign from "./components/Widgets/WidgetDesign";
import WidgetSettings from "./components/Widgets/WidgetSettings";
import WidgetsLove from './components/love_wall/Main'
import Publish from './components/love_wall/Publish';
import WidgetWall from './components/Widgets/WidgetWall';
import WidgetWallProofWallCombined from './components/Widgets/WidgetWallProofWallCombined';
import WidgetWallProofWallSeprated from './components/Widgets/WidgetWallProofWallSeprated';
import WriteAReview from './components/WriteAReview';
import WriteAReviewFinal from './components/WriteAReviewFinal';
import RecordVideo from './components/Recordvideo';
import Uploadvideo from './components/Uploadvideo';
import ProjectSettings from './components/ProjectSettings';
import ProjectDomain from './components/ProjectDomain';
import ProjectDanger from './components/ProjectDanger';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/register" element={<Signup />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route path="/register-email-verify" element={<RegisterEmailVerify />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/proof-wall/combined" element={<ProofWallCombined />}></Route>
        <Route path="/proof-wall/separated" element={<ProofWallSeparate />}></Route>
        <Route path="/get-more-reviews" element={<GetMoreReviews />}></Route>
        <Route path="/add-review" element={<AddReview />}></Route>
        <Route path="/settings" element={<Settings />}></Route>
        <Route path="/integrations" element={<Integrations />}></Route>
        <Route path="/install-the-wall" element={<Installthewall />}></Route>
        <Route path="/choose-platforms" element={<Platformslist />}></Route>
        <Route path="/social-proof" element={<SocialProof />}></Route>
        <Route path="/record-video" element={<VideoRecorder />}></Route>
        <Route path="/success" element={<SuccessPage />}></Route>
        <Route path='/settings/reset-password' element={<Generalresetpassword />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/terms" element={<Terms />}></Route>
        <Route path='/settings/upgrade-plan' element={<Plans />}></Route>
        <Route path="/social-proof/images" element={<Images />}></Route>
        <Route path="/social-proof/widgets" element={<Widgetsmain />}></Route>
        <Route path="/social-proof/proof-wall" element={<Walloflove />}></Route>
        <Route path="/social-proof/saved" element={<Saved />}></Route>
        <Route path="/social-proof/images/template" element={<Edittwo />}></Route>
        <Route path="/social-proof/design" element={<Design />}></Route>
        <Route path="/social-proof/settings" element={<Editsettings />}></Route>
        <Route path="/social-proof/widgets/template" element={<Widgets />}></Route>
        <Route path="/social-proof/widgets/design" element={<WidgetDesign />}></Route>
        <Route path="/social-proof/widgets/settings" element={<WidgetSettings />}></Route>
        <Route path="/social-proof/proof-wall/template" element={<WidgetsLove />}></Route>
        <Route path='/wall/:project/:slug' element={<Publish />}></Route>
        <Route path='/widget/:slug' element={<WidgetWall />}></Route>
        <Route path='/widgetProofWallCombined/:slug' element={<WidgetWallProofWallCombined />}></Route>
        <Route path='/widgetProofWallSeprated/:slug' element={<WidgetWallProofWallSeprated />}></Route>
        <Route path='/write-a-review' element={<WriteAReview />}></Route>
        <Route path='/write-a-review-final' element={<WriteAReviewFinal />}></Route>
        <Route path='/record-a-video' element={<RecordVideo />}></Route>
        <Route path='/upload-video' element={<Uploadvideo />}></Route>
        <Route path='/project-settings' element={<ProjectSettings />}></Route>
        <Route path='/project-settings/domain' element={<ProjectDomain />}></Route>
        <Route path='/project-settings/danger' element={<ProjectDanger />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
