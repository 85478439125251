import React, { Component, useState } from 'react';
import CanvasJSReact from '../assets/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Chart(props){
	
	const options = {
		
		animationEnabled: true,
		axisY: {
			includeZero: false,
		},
		data: [{
			name: 'Views',
			type: "splineArea",
			color: "#2b0c04",
			yValueFormatString: "#,##0.##",
			showInLegend: true,
			dataPoints:props.data
		}]
	}

	return (
       
          <div>
			<div className='d-flex justify-content-between align-items-center pb-3'>
				<h4>Total View Count</h4>
				<div className='filter'>
					<ul className='p-0 d-flex'>
						<li onClick={props.monthFunction} className={props.isactive === 'month' ? 'active' : ''}>Month</li>
						<li onClick={props.dayFunction} className={props.isactive === 'day' ? 'active' : ''}>Day</li>
						<li onClick={props.yearFunction} className={props.isactive === 'year' ? 'active' : ''}>Year</li>
					</ul>
				</div>
			</div>
			<CanvasJSChart options = {options} />
		</div>
		
	);
}

export default Chart;
